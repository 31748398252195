export enum ETypePayments {
  PAID_MARKED = 'PAID_MARKED',
  WALLET = 'WALLET',
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  PIX = 'PIX',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  TICKET = 'TICKET',
}



export default ETypePayments;
