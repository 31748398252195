import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  MODAL_LOCATION_APP_ACTION,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonUserCheckin from '../../components/Buttons/ButtonUserCheckin';

import {
  Container,
  Screen,
} from '../../components/Composh/web';

import DetailsCart from '../../components/Details/DetailsCart';

import {
  IconMap,
  IconTypeNightClub,
  IconNoitada,
  IconTypeParty,
} from '../../components/Icons';

import LocationsScreen from '../../modals/Locations';

import NameRoutes from '../../navigation/names';

import {
  BottomNavigationStyle,
  ActionBottomStyle,
} from '../../styles/bottom-navigation.layout';

import TabEventsScreen from './Tabs/TabEvents';
import TabHighlightsScreen from './Tabs/TabHighlights';
import TabMapScreen from './Tabs/TabMap';
import TabProfilesScreen from './Tabs/TabProfiles';



const GridEventsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const openLocationApp = useSelector((state: RootState) => state.localities.modalLocationApp);
  const sacola = useSelector((state: RootState) => state.sacola);
  const user = useSelector((state: RootState) => state.user.actualUser);
  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);


  const [value, setValue] = useState('/');



  const getCurrentTab = () => {
    if (!value) {
      return (
        <TabHighlightsScreen />
      );
    }
    switch (value.toUpperCase()) {
      case '/':
        return (
          <TabHighlightsScreen />
        );

      case 'Events'.toUpperCase():
        return (
          <TabEventsScreen />
        );

      case 'Companies'.toUpperCase():
        return (
          <TabProfilesScreen />
        );

      case 'Map'.toUpperCase():
        return (
          <TabMapScreen />
        );

      default:
        return (
          <TabHighlightsScreen />
        );
    }
  };


  function returnLabelCurrentTab() {
    if (!value) {
      return `${translate(TranslateConfig.HIGHLIGHTS)} | ${translate(TranslateConfig.NAME_APP)}`;
    }
    switch (value.toUpperCase()) {
      case '/':
        return `${translate(TranslateConfig.HIGHLIGHTS)} | ${translate(TranslateConfig.NAME_APP)}`;

      case 'Events'.toUpperCase():
        return `${translate(TranslateConfig.EVENTS)} | ${translate(TranslateConfig.NAME_APP)}`;

      case 'Companies'.toUpperCase():
        return `${translate(TranslateConfig.PARTNERS)} | ${translate(TranslateConfig.NAME_APP)}`;

      case 'Map'.toUpperCase():
        return `${translate(TranslateConfig.MAP)} | ${translate(TranslateConfig.NAME_APP)}`;

      default:
        return `${translate(TranslateConfig.HIGHLIGHTS)} | ${translate(TranslateConfig.NAME_APP)}`;
    }
  };



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.HOME });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {returnLabelCurrentTab()}
        </title>
      </Helmet>



      <Container>

        {getCurrentTab()}



        {sacola?.items[0] && user?.id && checkinUser && (
          <DetailsCart
            totalItems={sacola?.items?.length}
            price={sacola?.items?.reduce((itemA: any, itemB: any) => {
              return itemA + (itemB?.itemGroup?.price * itemB?.quantity);
            }, 0)}
            onPress={() => {
              history.push(NameRoutes.OrderBagScreen);
            }}
          />
        )}



        <BottomNavigationStyle
          value={value}
          onChange={(_, newValue) => {
            setValue(String(newValue));
          }}>

          <ActionBottomStyle
            value={'/'}
            label={translate(TranslateConfig.HIGHLIGHTS)}
            icon={
              <IconNoitada />
            }
          />


          <ActionBottomStyle
            value={'Events'}
            label={translate(TranslateConfig.EVENTS)}
            icon={
              <IconTypeParty />
            }
          />


          <ButtonUserCheckin
            size={60}
          />


          <ActionBottomStyle
            value={'Companies'}
            label={translate(TranslateConfig.PARTNERS)}
            icon={
              <IconTypeNightClub />
            }
          />


          <ActionBottomStyle
            value={'Map'}
            label={translate(TranslateConfig.MAP)}
            icon={
              <IconMap />
            }
          />

        </BottomNavigationStyle>

      </Container>



      <LocationsScreen
        visible={openLocationApp}
        type={'APP'}
        action={'DISPATCH'}
        onClose={() => {
          dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: false });
        }}
      />

    </Screen>

  );
};



export default GridEventsScreen;
