import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  color?: string;
}



export const CardHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
`;



export const CardHistoryImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: ${Sizes.cardRadius - 2}px;
  object-fit: cover;
`;



export const HistoryInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin-left: 12px;
`;


export const CardRequestInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardRequestQuantityBadge = styled.p`
  min-width: 23px;
  margin-right: 5px;
  padding-top: 2px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 2px;
  background-color: ${Colors.primaryDark};
  color: ${Colors.white};
  font-size: 17px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  border-radius: 50px;
`;

export const RequestItemName = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
`;


export const HistoryStatusView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`;

export const HistorySheetStatusText = styled.p`
  color: ${(props: IProps) => props.color || Colors.black};
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
`;

export const HistorySheetHourText = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
  line-height: 20px;
`;



export const HistorySheetPriceText = styled.p`
  margin-left: 10px;
  color: ${Colors.white};
  font-size: 16px;
`;
