import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  LinearGradient,
} from '../../../Composh/web';



export interface IProps {
  size?: number;
}



export const CardDashEventStyle = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${(props: IProps) => props.size}px;
  min-width: ${(props: IProps) => props.size}px;
  height: 180px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const ButtonBackgroundEvent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  position: relative;
`;

export const ImageBackgroundEvent = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;



export const TextGradientContainer = styled(LinearGradient)`
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 5;
`;

export const TextTypeEvent = styled.p`
  color: ${Colors.accent};
  font-size: 14px;
  font-weight: bold;
`;

export const TextEventName = styled.p`
  margin-top: 1px;
  margin-bottom: 1px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: right;
`;

export const TextDate = styled.p`
  color: ${Colors.textApp};
  font-size: 12px;
`;
