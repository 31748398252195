import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraPr: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Apucarana',
    value: 'APUCARANA',
  },
  {
    id: 2,
    label: 'Cascavel',
    value: 'CASCAVEL',
  },
  {
    id: 3,
    label: 'Curitiba',
    value: 'CURITIBA',
  },
  {
    id: 4,
    label: 'Foz do Iguaçu',
    value: 'FOZ_DO_IGUACU',
  },
  {
    id: 5,
    label: 'Londrina',
    value: 'LONDRINA',
  },
  {
    id: 6,
    label: 'Maringá',
    value: 'MARINGA',
  },
  {
    id: 7,
    label: 'Paranaguá',
    value: 'PARANAGUA',
  },
  {
    id: 8,
    label: 'Ponta Grossa',
    value: 'PONTA_GROSSA',
  },
  {
    id: 9,
    label: 'São José dos Pinhais',
    value: 'SAO_JOSE_DOS_PINHAIS',
  },
  {
    id: 10,
    label: 'Toledo',
    value: 'TOLEDO',
  },
];



export default CitiesFullBraPr;
