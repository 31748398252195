import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '@noitada/redux-config';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECompanyStatus,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  getTimeDiff,
} from '@noitada/shared/utils/date.utils';

import {
  IconArrowLightRight,
  IconOrderCard,
  IconTable,
} from '../../../../components/Icons';

import {
  OrderSheetInfoButton,
  CardSheetContainer,
  HeaderInfoCheckinView,
  CheckInfoView,
  CheckinUserRowHeader,
  CheckinUserIcon,
  CheckinUserTitle,
  CheckinUserText,
  OrderLimitTimeView,
  LimitTimeTitle,
  LimitTimeText,
  HeaderInfoPreparView,
  TotalItemsPreparView,
  OrderPreparTitle,
  OrderLartTimeText,
  TotalItemsPreparText,
  CompleteHistoryButton,
  HistoryText,
} from './styled';



export interface IProps {
  numberTable?: string;
  numberOrder?: string;
  statusCompany?: string;
  orders?: any[];
  okPress?: any;
}



const CardOrderSheetInfo: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);

  const preparLength = checkinUser?.orders?.filter((e) => e?.status === 'PREPARING').length || 0;



  function returnTitleStatus() {
    switch (props.statusCompany) {
      case ECompanyStatus.CLOSED:
      case ECompanyStatus.ENABLED:
      case ECompanyStatus.DISABLED:
        return 'Fechado';

      case ECompanyStatus.CONSTRUCTION:
        return 'Em construção';

      case ECompanyStatus.OPEN:
        return 'Aberto';

      case ECompanyStatus.OPEN_PAUSED:
        return 'Suspenso';

      default:
        return 'Pausadas';
    }
  }


  function returnBackgroundStatus() {
    switch (props.statusCompany) {
      case ECompanyStatus.CLOSED:
      case ECompanyStatus.ENABLED:
      case ECompanyStatus.DISABLED:
        return Colors.danger;

      case ECompanyStatus.CONSTRUCTION:
        return Colors.danger;

      case ECompanyStatus.OPEN:
        return Colors.accept;

      case ECompanyStatus.OPEN_PAUSED:
        return Colors.attention;

      default:
        return Colors.gray;
    }
  }



  return (

    <OrderSheetInfoButton>

      <CardSheetContainer>

        <HeaderInfoCheckinView>

          <CheckInfoView>

            <CheckinUserRowHeader>
              <CheckinUserIcon>
                <IconTable
                  color={Colors.black}
                  size={18}
                />
              </CheckinUserIcon>

              <CheckinUserTitle>
                {translate(TranslateConfig.TABLE)}
              </CheckinUserTitle>
            </CheckinUserRowHeader>


            <CheckinUserText>
              {props.numberTable || '--'}
            </CheckinUserText>
          </CheckInfoView>



          <CheckInfoView>

            <CheckinUserRowHeader>
              <CheckinUserIcon>
                <IconOrderCard
                  color={Colors.black}
                  size={18}
                />
              </CheckinUserIcon>

              <CheckinUserTitle>
                {translate(TranslateConfig.ORDERSHEET)}
              </CheckinUserTitle>
            </CheckinUserRowHeader>


            <CheckinUserText>
              {props.numberOrder || '--'}
            </CheckinUserText>
          </CheckInfoView>



          <OrderLimitTimeView>
            <LimitTimeTitle>
              {translate(TranslateConfig.SALES)}
            </LimitTimeTitle>


            <LimitTimeText
              color={returnBackgroundStatus()}>
              {returnTitleStatus()}
            </LimitTimeText>

          </OrderLimitTimeView>

        </HeaderInfoCheckinView>



        <HeaderInfoPreparView>
          <TotalItemsPreparView>
            {checkinUser?.orders[0] && (
              <>
                <OrderPreparTitle>
                  {translate(TranslateConfig.LAST_ORDER)}
                </OrderPreparTitle>


                <OrderLartTimeText>
                  {checkinUser?.orders?.slice(-1)[0]?.orderedAt
                    ? `${translate(TranslateConfig.THERE_IS)} ${getTimeDiff(new Date(checkinUser?.orders?.slice(-1)[0]?.orderedAt))}`
                    : '-'
                  }
                </OrderLartTimeText>
              </>
            )}


            {!checkinUser?.orders[0] && (
              <OrderPreparTitle>
                {translate(TranslateConfig.EMPTY_ORDERS)}
              </OrderPreparTitle>
            )}

          </TotalItemsPreparView>



          {preparLength > 0 && (
            <TotalItemsPreparText>
              {translate(TranslateConfig.ORDER_PREPARING)}: {preparLength}
            </TotalItemsPreparText>
          )}

        </HeaderInfoPreparView>
      </CardSheetContainer>



      <CompleteHistoryButton
        // activeOpacity={Sizes.CardActiveOpacity}
        onClick={props.okPress}>

        <HistoryText>
          {translate(TranslateConfig.ACTION_HISTORY)}
        </HistoryText>

        <IconArrowLightRight
          color={Colors.white}
          size={28}
        />

      </CompleteHistoryButton>

    </OrderSheetInfoButton>

  );
};



export default CardOrderSheetInfo;
