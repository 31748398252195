import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useLocation,
} from 'react-router-dom';

import {
  useSpring,
  animated,
} from '@react-spring/web';

import {
  APP_DRAWER_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  CARNIVAL_ACTIONSHEET_CIRCUITS,
} from '@noitada/redux-config/reducers/carnival-festivals.store';

import {
  APP_ALL_LOCALITIES,
  MODAL_LOCATION_APP_ACTION,
  USER_LANGUAGE,
  USER_LANGUAGE_MODAL,
  USER_LOCATION,
} from '@noitada/redux-config/reducers/localities.store';

import {
  USER_LOGGED,
  USER_LOGOUT_STATE,
  USER_SET,
} from '@noitada/redux-config/reducers/user.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  WorldPlaceResponse,
} from '@noitada/shared/service/address.service';

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@noitada/shared/utils/local-storage.utils';

import {
  Screen,
} from '../../components/Composh/web';

import {
  getSystemCurrentLanguage,
  setCurrentLanguage,
} from '../../config/language.config';

import {
  AbsoluteFillView,
  ContainerGlue,
  BackgroundColorView,
  FlexContent,
  ImageBackgroundStyle,
  LogoStyle,
  LogoImageStyle,
} from './styled';


const AnimatedBackgroundColorView = animated(BackgroundColorView);
const AnimatedFlexContent = animated(FlexContent);
const AnimatedImageBackgroundStyle = animated(ImageBackgroundStyle);
const AnimatedImageLogo = animated(LogoImageStyle);



export interface IProps {
  children?: any;
}



const AnimatedSplash: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const pathname = location.pathname;


  const [animationDone, setAnimationDone] = useState<boolean>(false);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const logoWidth = 120;
  const logoHeight = 120;
  const backgroundColor = Colors.primaryDark;
  const logoImage = Images.logoIconAppWhite;
  const imageBackgroundSource = Images.backgroundSplashBubble;



  const loadingProgress = useSpring({
    from: { value: 0 },
    // to: { value:100 }, // FIXME
    to: { value: isLoaded ? 100 : 0 },
  });


  const opacityClearToVisible = useSpring({
    opacity: loadingProgress.value.to({
      range: [0, 15, 30],
      output: [0, 0, 1],
      extrapolate: 'clamp',
    }),
  });


  const imageScale = useSpring({
    transform: loadingProgress.value.to({
      range: [0, 10, 100],
      output: [1, 1, 65],
    }).to((scaleValue) => `scale(${scaleValue})`),
  });


  const logoScale = useSpring({
    transform: loadingProgress.value.to({
      range: [0, 10, 100],
      output: [1, 0.8, 10],
    }).to((scaleValue) => `scale(${scaleValue})`),
  });


  const logoOpacity = useSpring({
    opacity: loadingProgress.value.to({
      range: [0, 20, 100],
      output: [1, 0, 0],
      extrapolate: 'clamp',
    }),
  });


  const appScale = useSpring({
    transform: loadingProgress.value.to({
      range: [0, 7, 100],
      output: [1.1, 1.05, 1],
    }).to((scaleValue) => `scale(${scaleValue})`),
  });



  function clearValues(): void {
    try {
      dispatch({ type: USER_LOGGED, payload: false });
      dispatch({ type: USER_LOGOUT_STATE, payload: false });
      dispatch({ type: USER_SET, payload: {} });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function appProjectParamsValues(dataParams: any) {
    // if (!dataParams?.canAccess) {
    //   clearValues();
    // }

    // dispatch({ type: PROJECT_CONFIGS_MODULES_SET, payload: dataParams });

    // // Verificar se nenhum dos parâmetros está presente
    // setShowSecret((prevShowSecret) => {
    //   const shouldShowSecret = !dataParams?.showRent && !dataParams?.showLive && !dataParams?.showFanClub;
    //   if (prevShowSecret !== shouldShowSecret) {
    //     return shouldShowSecret;
    //   }
    //   return prevShowSecret;
    // });
  }


  async function loadLocalities() {
    const response = WorldPlaceResponse();
    const responseData = response?.data;

    dispatch({ type: APP_ALL_LOCALITIES, payload: responseData });
  }


  async function loadGlobalParams() {
    // try {
    //   const response = await GetGlobalAppParams();
    //   const responseData = response?.data;

    //   if (!responseData) {
    //     appProjectParamsValues(null);
    //     return;
    //   }

    //   appProjectParamsValues(responseData?.configs?.modules);
    // }
    // catch (error: any) {
    //   console.error(error);
    //   appProjectParamsValues(null);

    //   dispatch({ type: MODELES_HIGHLIGHTS, payload: arrayModelesHighlights });
    // }
  };


  function renderChildren() {
    if (isLoaded) {
      return props.children;
    }

    return null;
  }


  function initializeLanguage() {
    const systemLang = getSystemCurrentLanguage();
    setCurrentLanguage(systemLang);
    i18n.changeLanguage(systemLang);
    dispatch({ type: USER_LANGUAGE, payload: systemLang });
  }


  function setLocationFunc(location: any) {
    setLocalStorageItem(USER_LOCATION, location);
    dispatch({ type: USER_LOCATION, payload: location });
  }


  function initializeLocation() {
    const cityParams = queryParams?.get('city');
    const stateParams = queryParams?.get('state');
    const countryParams = queryParams?.get('country');

    if (cityParams && stateParams && countryParams) {
      const paramsLocationPayload = {
        city: cityParams,
        state: stateParams,
        country: countryParams,
      };

      setLocationFunc(paramsLocationPayload);
      return;
    }


    const getLocationSave = getLocalStorageItem(USER_LOCATION);
    const citySave = getLocationSave?.city;
    const stateSave = getLocationSave?.state;
    const countrySave = getLocationSave?.country;

    if (citySave && stateSave && countrySave) {
      const storageLocationPayload = {
        city: citySave,
        state: stateSave,
        country: countrySave,
      };

      setLocationFunc(storageLocationPayload);
      return;
    }


    const locationPayload = {
      city: 'SALVADOR',
      state: 'BA',
      country: 'BRA',
    };

    setLocationFunc(locationPayload);
  }


  function resetModalStates() {
    dispatch({ type: APP_DRAWER_ACTION, payload: false });

    dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: false });
    dispatch({ type: USER_LANGUAGE_MODAL, payload: false });
    dispatch({ type: CARNIVAL_ACTIONSHEET_CIRCUITS, payload: false });
  }



  useEffect(() => {
    // console.log(getCurrentLanguage());

    if (!isLoaded) {
      loadLocalities();
      loadGlobalParams();

      initializeLanguage();
      initializeLocation();
      resetModalStates();
    }


    setTimeout(async () => {
      setIsLoaded(true);
    }, 2000);


    if (isLoaded) {
      setAnimationDone(true);
    }
  }, [isLoaded]);



  return (

    <Screen
      style={pathname === '/privacy' ? { overflow: 'scroll' } : {}}
      backgroundColor={Colors.transparent}>


      {!animationDone && (
        <AbsoluteFillView />
      )}



      <ContainerGlue>

        {!animationDone && (
          <AnimatedBackgroundColorView
            backgroundColor={backgroundColor}
            style={{
              opacity: logoOpacity.opacity,
            }}
          />
        )}



        <AnimatedFlexContent
          style={{
            transform: isLoaded ? null : appScale.transform,
            opacity: opacityClearToVisible.opacity,
          }}>
          {renderChildren()}
        </AnimatedFlexContent>



        {false && !animationDone && (
          <AnimatedImageBackgroundStyle
            alt={'imageBackground'}
            src={imageBackgroundSource}
            tintColor={Colors.primary}
            style={{
              transform: imageScale.transform,
              opacity: logoOpacity.opacity,
            }}
          />
        )}



        {!animationDone && (
          <LogoStyle>
            <AnimatedImageLogo
              alt={'logo'}
              src={logoImage}
              width={logoWidth}
              height={logoHeight}
              style={{
                transform: logoScale.transform,
                opacity: logoOpacity.opacity,
              }}
            />
          </LogoStyle>
        )}

      </ContainerGlue>

    </Screen>

  );
};



export default AnimatedSplash;
