import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

const EventSize = 180;
const EventFooterSize = 45;
const EventMarginHorizontal = 4;



export const LightEventContainer = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${EventSize}px;
  min-width: ${EventSize}px;
  height: ${EventSize + EventFooterSize}px;
  min-height: ${EventSize + EventFooterSize}px;
  margin-left: ${EventMarginHorizontal}px;
  margin-right: ${EventMarginHorizontal}px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;


export const DashLightEventImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const DashLightEventFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${EventFooterSize}px;
  min-height: ${EventFooterSize}px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${Colors.cardBackground};
`;

export const LightEventNameText = styled.p`
  margin-top: 1px;
  margin-bottom: 1px;
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
