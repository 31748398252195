import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraSp: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Campinas',
    value: 'CAMPINAS',
  },
  {
    id: 2,
    label: 'Guarulhos',
    value: 'GUARULHOS',
  },
  {
    id: 3,
    label: 'Mauá',
    value: 'MAUA',
  },
  {
    id: 4,
    label: 'Osasco',
    value: 'OSASCO',
  },
  {
    id: 5,
    label: 'Ribeirão Preto',
    value: 'RIBEIRAO_PRETO',
  },
  {
    id: 6,
    label: 'Santo André',
    value: 'SANTO_ANDRE',
  },
  {
    id: 7,
    label: 'Sorocaba',
    value: 'SOROCABA',
  },
  {
    id: 8,
    label: 'São Bernardo do Campo',
    value: 'SAO_BERNARDO_DO_CAMPO',
  },
  {
    id: 9,
    label: 'São José dos Campos',
    value: 'SAO_JOSE_DOS_CAMPOS',
  },
  {
    id: 10,
    label: 'São Paulo',
    value: 'SAO_PAULO',
  },
];



export default CitiesFullBraSp;
