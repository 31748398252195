import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  animated,
  useSpring,
} from '@react-spring/web';

import {
  IInputSelect,
} from '@noitada/axios-config/interfaces/App/input-select.interface';

import {
  RootState,
} from '@noitada/redux-config';

import {
  MODAL_LOCATION_APP_ACTION,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  returnCityArray,
  returnStateArray,
} from '@noitada/shared/utils/address.utils';

import {
  IconMapLocation,
} from '../../Icons';

import {
  ButtonCityUserStyle,
  ButtonCityLinear,
  ButtonCityParent,
  MenuIconCity,
  MenuTextCity,
} from './styled';


const AnimatedButtonCityLinear = animated(ButtonCityLinear);



const ButtonCityCarnival: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);

  const getStateArray = returnStateArray(localities?.country);
  const findState = getStateArray?.find((itemTravel: IInputSelect) => itemTravel.value === localities?.state);

  const getCityArray = returnCityArray(localities?.country, findState?.value);
  const findCity = getCityArray?.find((itemTravel: IInputSelect) => itemTravel.value === localities?.city);


  const gradientColors = [
    Colors.carnivalBlue,
    Colors.carnivalGreen,
    Colors.carnivalPurple,
    Colors.carnivalRed,
    Colors.carnivalYellow,
  ];

  const styleProps = useSpring({
    loop: true,
    from: { background: gradientColors[0] },
    to: async (next) => {
      while (1) {
        await next({ background: gradientColors[4] });
        await new Promise((resolve) => setTimeout(resolve, 2500));
        await next({ background: gradientColors[3] });
        await new Promise((resolve) => setTimeout(resolve, 2500));
        await next({ background: gradientColors[1] });
        await new Promise((resolve) => setTimeout(resolve, 2500));
        await next({ background: gradientColors[2] });
        await new Promise((resolve) => setTimeout(resolve, 2500));
        await next({ background: gradientColors[0] });
        await new Promise((resolve) => setTimeout(resolve, 2500));
      }
    },
  });



  return (

    <ButtonCityUserStyle
      onClick={() => {
        dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: true });
      }}>

      <AnimatedButtonCityLinear
        style={{
          background: styleProps.background,
        }}>

        <ButtonCityParent>
          <MenuIconCity>
            <IconMapLocation
              size={19}
              color={Colors.white}
            />
          </MenuIconCity>


          {/* // FIXME: Se o nome da cidade usar tail (...) fazer o nome se mexer para mostrar todo */}
          <MenuTextCity>
            {findCity?.label || translate(TranslateConfig.CITY)}
          </MenuTextCity>
        </ButtonCityParent>

      </AnimatedButtonCityLinear>

    </ButtonCityUserStyle>

  );
};



export default ButtonCityCarnival;
