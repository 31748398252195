import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pt-br';

import {
  validateString,
} from './string.utils';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale('pt-br');



export function FormatIsoDateWithPatternDayjs(isoDate: string | Date | null | undefined, pattern: string) {
  if (!isoDate) {
    return '';
  }

  const date = dayjs(isoDate).locale('pt');
  const formattedDate = date.format(pattern);

  return formattedDate;
}



export function DayJStoIso(dataString: string, format: string) {
  if (!dataString) {
    return '';
  }

  return dayjs(dataString, format, true).hour(12).toISOString();
}



export function isValidDate(dateString: string) {
  return dayjs(dateString).isValid();
}



// Função para adicionar horas a uma data e retornar um objeto Date
export function convertToDateHours(dateString: string, hours?: number) {
  const date = dayjs(dateString);

  if (hours) {
    return date.add(hours, 'hour').toDate();
  }

  return date.toDate();
}



export function daysUntilDate(dataString: string) {
  if (!dataString) {
    return '';
  }

  // Parse da data alvo usando o Day.js
  const targetDateTime = dayjs(dataString);

  // Parse da data de hoje usando o Day.js
  const todayDateTime = dayjs().startOf('day');

  // Calcula a diferença entre as datas em dias
  const daysDifference = targetDateTime.diff(todayDateTime, 'day');

  return daysDifference;
};



export function isBirthdayToday(birthDateString: string | null | undefined) {
  // Verifica se a data de nascimento é nula ou indefinida
  if (!validateString(birthDateString)) {
    return '';
  }

  // Parse da data sem considerar o timezone
  const birthDate = dayjs(birthDateString);

  // Obter a data de hoje
  const today = dayjs();

  // Verificar se hoje é o aniversário
  return today.date() === birthDate.date() && today.month() === birthDate.month();
}



export function RenderHourMinutes(dateStr: string) {
  if (!validateString(dateStr)) {
    return '--:--';
  }
  const date = new Date(dateStr);
  return date.toTimeString().substring(0, 5);
}



export function getTimeDiff(date: string | Date) {
  if (!date) {
    return '';
  };

  const now = dayjs(); // Data atual
  const end = dayjs(date); // Outra data

  const yearsDiff = now.diff(end, 'year');
  if (yearsDiff > 0) {
    return `${yearsDiff} ${yearsDiff === 1 ? 'ano' : 'anos'}`;
  }

  const daysDiff = now.diff(end, 'day');
  if (daysDiff > 0) {
    return `${daysDiff} ${daysDiff === 1 ? 'dia' : 'dias'}`;
  }

  const hoursDiff = now.diff(end, 'hour');
  if (hoursDiff > 0) {
    return `${hoursDiff} ${hoursDiff === 1 ? 'hora' : 'horas'}`;
  }

  const minutesDiff = now.diff(end, 'minute');
  return `${minutesDiff} ${minutesDiff === 1 ? 'minuto' : 'minutos'}`;
}



export function formatIsToFractionDayjs(isoDate: any) {
  if (!isoDate) {
    return '';
  }

  const now = dayjs();
  const date = dayjs(isoDate);

  const diffInMilliseconds = now.diff(date);
  const durationObj = dayjs.duration(diffInMilliseconds);

  const days = Math.floor(durationObj.asDays());
  const hours = durationObj.hours();
  const minutes = durationObj.minutes();

  // Condicionalmente monta a string
  let formattedDate = '';

  if (days > 0) {
    formattedDate += `${days}d `;
  }

  if (hours > 0) {
    formattedDate += `${hours}h `;
  }

  formattedDate += `${minutes}m`;

  return formattedDate.trim();
}



export function formatMinuteToFractionDayjs(minutes: number) {
  if (!minutes) {
    return '';
  }

  const now = dayjs();

  // Converta minutos em milissegundos e crie uma data com essa diferença
  const date = now.subtract(minutes, 'minute').toISOString();

  // Use a função formatIsToFractionDayjs para formatar a data
  const formattedTime = formatIsToFractionDayjs(date);

  return formattedTime;
};



export function FormatExtenseDate(dataString: string) {
  if (!dataString) {
    return '';
  }

  // Converte a string da data para um objeto dayjs e define o locale para português
  const data = dayjs(dataString).locale('pt');

  // Formata a data em 'DD, MMMM YYYY'
  // Ex: "21 de outubro de 2024"
  const dataFormatada = data.format('DD [de] MMMM [de] YYYY');

  return dataFormatada;
}



export function FullCompleteDate(dateString: string) {
  if (!dateString) {
    return null;
  }

  try {
    // Cria um objeto Date a partir da string de data
    const date = new Date(dateString);

    // Verifica se a data é válida
    if (isNaN(date.getTime())) {
      console.log('FullCompleteDate - Invalid Date');
      return null;
    }

    // Arrays com os nomes dos dias da semana e meses
    const daysOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    // Obtém o nome do dia da semana, o dia do mês, o nome do mês e o ano
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate().toString().padStart(2, '0'); // Formata o dia do mês com dois dígitos
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Formata a data no formato desejado
    return {
      dayOfWeek,
      dayOfMonth,
      month,
      year,
    };
  }
  catch (error) {
    console.error(error);
    return null;
  }
}
