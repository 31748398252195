import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  languageModal: false,
  language: '',

  modalLocationApp: false,
  allLocalities: [],
  selected: {
    city: '',
    state: '',
    country: '',
  },
};



// Action Types

export const USER_LANGUAGE_MODAL = '@company/USER_LANGUAGE_MODAL';
export const USER_LANGUAGE = '@user/LANGUAGE';

export const MODAL_LOCATION_APP_ACTION = '@company/MODAL_LOCATION_APP_ACTION';
export const APP_ALL_LOCALITIES = '@user/APP_ALL_LOCALITIES';
export const USER_LOCATION = '@user/LOCATION';



// Action Creators

export const languageModalAction = createAction<object>(USER_LANGUAGE_MODAL);
export const languageAction = createAction<object>(USER_LANGUAGE);

export const modalLocationAppAction = createAction<object>(MODAL_LOCATION_APP_ACTION);
export const appAllLocalitiesAction = createAction<object>(APP_ALL_LOCALITIES);
export const locationAction = createAction<object>(USER_LOCATION);



// Handlers

const languageModalReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  languageModal: action.payload,
});


const languageReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  language: action.payload,
});


const modalLocationAppReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  modalLocationApp: action.payload,
});


const appAllLocalitiesReducer = (state = INITIAL_STATE, action) => ({
  ...state,
  allLocalities: action.payload,
});


const locationReducer = (state = INITIAL_STATE, action: any) => ({
  ...state,
  selected: action.payload,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [languageModalAction.type]: languageModalReducer,
  [languageAction.type]: languageReducer,

  [modalLocationAppAction.type]: modalLocationAppReducer,
  [appAllLocalitiesAction.type]: appAllLocalitiesReducer,
  [locationAction.type]: locationReducer,
});
