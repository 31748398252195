import React from 'react';

import {
  Images,
} from '@noitada/shared/constants';

import {
  LightEventContainer,
  DashLightEventImage,
  DashLightEventFooter,
  LightEventNameText,
} from './styled';



export interface IProps {
  image?: string;
  name?: string;

  onPress?: any;
}



const CardDashEventLight: React.FC<IProps> = (props: IProps) => {
  const eventImage = (props.image === '' || props.image === null || props.image === undefined)
    ? Images.placeholderEvent
    : props.image;



  return (

    <LightEventContainer
      onClick={props.onPress}>

      <DashLightEventImage
        alt={'Event'}
        src={eventImage}
      />


      <DashLightEventFooter>

        <LightEventNameText>
          {props.name || '-'}
        </LightEventNameText>

      </DashLightEventFooter>

    </LightEventContainer>

  );
};



export default CardDashEventLight;
