import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraRj: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Angra dos Reis',
    value: 'ANGRA_DOS_REIS',
  },
  {
    id: 2,
    label: 'Belford Roxo',
    value: 'BELFORD_ROXO',
  },
  {
    id: 3,
    label: 'Cabo Frio',
    value: 'CABO_FRIO',
  },
  {
    id: 4,
    label: 'Duque de Caxias',
    value: 'DUQUE_DE_CAXIAS',
  },
  {
    id: 5,
    label: 'Nova Iguaçu',
    value: 'NOVA_IGUACU',
  },
  {
    id: 6,
    label: 'Niterói',
    value: 'NITEROI',
  },
  {
    id: 7,
    label: 'Petrópolis',
    value: 'PETROPOLIS',
  },
  {
    id: 8,
    label: 'Rio de Janeiro',
    value: 'RIO_DE_JANEIRO',
  },
  {
    id: 9,
    label: 'São Gonçalo',
    value: 'SAO_GONCALO',
  },
  {
    id: 10,
    label: 'Volta Redonda',
    value: 'VOLTA_REDONDA',
  },
];



export default CitiesFullBraRj;
