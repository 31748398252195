import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const StatesFullBra: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Acre',
    value: 'AC',
  },
  {
    id: 2,
    label: 'Alagoas',
    value: 'AL',
  },
  {
    id: 3,
    label: 'Amapá',
    value: 'AP',
  },
  {
    id: 4,
    label: 'Amazonas',
    value: 'AM',
  },
  {
    id: 5,
    label: 'Bahia',
    value: 'BA',
  },
  {
    id: 6,
    label: 'Ceará',
    value: 'CE',
  },
  {
    id: 7,
    label: 'Distrito Federal',
    value: 'DF',
  },
  {
    id: 8,
    label: 'Espírito Santo',
    value: 'ES',
  },
  {
    id: 9,
    label: 'Goiás',
    value: 'GO',
  },
  {
    id: 10,
    label: 'Maranhão',
    value: 'MA',
  },
  {
    id: 11,
    label: 'Mato Grosso',
    value: 'MT',
  },
  {
    id: 12,
    label: 'Mato Grosso do Sul',
    value: 'MS',
  },
  {
    id: 13,
    label: 'Minas Gerais',
    value: 'MG',
  },
  {
    id: 14,
    label: 'Pará',
    value: 'PA',
  },
  {
    id: 15,
    label: 'Paraíba',
    value: 'PB',
  },
  {
    id: 16,
    label: 'Paraná',
    value: 'PR',
  },
  {
    id: 17,
    label: 'Pernambuco',
    value: 'PE',
  },
  {
    id: 18,
    label: 'Piauí',
    value: 'PI',
  },
  {
    id: 19,
    label: 'Rio de Janeiro',
    value: 'RJ',
  },
  {
    id: 20,
    label: 'Rio Grande do Norte',
    value: 'RN',
  },
  {
    id: 21,
    label: 'Rio Grande do Sul',
    value: 'RS',
  },
  {
    id: 22,
    label: 'Rondônia',
    value: 'RO',
  },
  {
    id: 23,
    label: 'Roraima',
    value: 'RR',
  },
  {
    id: 24,
    label: 'Santa Catarina',
    value: 'SC',
  },
  {
    id: 25,
    label: 'São Paulo',
    value: 'SP',
  },
  {
    id: 26,
    label: 'Sergipe',
    value: 'SE',
  },
  {
    id: 27,
    label: 'Tocantins',
    value: 'TO',
  },
];



export default StatesFullBra;
