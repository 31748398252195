import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Container,
  Content,
} from '../../../../components/Composh/web';



export const SheetContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardBackground};
`;



export const ListContainer = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
`;


// export const ListTitle = styled.p`
//   width: 100%;
//   margin-top: 4px;
//   margin-bottom: 8px;
//   padding-left: ${Sizes.paddingContainer}px;
//   padding-right: ${Sizes.paddingContainer}px;
//   font-size: 16px;
//   color: ${Colors.primary};
// `;


export const ListButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;


export const ListIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: 12px;
`;


export const ListLabel = styled.p`
  font-size: 15px;
  color: ${Colors.white};
`;
