import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../config/mask.config';

import {
  BottomCartStyle,
  ContainNumberItems,
  TextCart,
  PriceCart,
} from './styled';



export interface IProps {
  totalItems?: string;
  price?: string;
  onPress?: any;
}



const DetailsCart: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function returnPriceValue() {
    if (props.price) {
      return FormatMoney(props?.price);
    }

    return '-';
  }



  return (

    <BottomCartStyle
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <ContainNumberItems>
        {props.totalItems || 0}
      </ContainNumberItems>


      <TextCart>
        {translate(TranslateConfig.ACTION_BAG_SEE)}
      </TextCart>

      <PriceCart>
        {returnPriceValue()}
      </PriceCart>

    </BottomCartStyle>

  );
};



export default DetailsCart;
