import {
  ILanguageCountry,
  IEndpointResponse,
} from '@noitada/axios-config/interfaces';

import {
  languagesOption,
} from '../arrays/App/lang-country.array';

import {
  returnCityArray,
  returnStateArray,
} from '../utils/address.utils';



// Lista de todos os países suportados
const supportedCountries = languagesOption.map((lang: ILanguageCountry) => lang.code);

export function WorldPlaceResponse(): IEndpointResponse {
  const customData = supportedCountries.map((country) => {
    const states = returnStateArray(country);

    const stateData = states.map((state) => {
      const cities = returnCityArray(country, state.value);
      return {
        stateName: state.value,
        cities: cities.map((city) => city.value),
      };
    });

    return {
      countryName: country,
      states: stateData,
    };
  });

  return {
    message: 'Sucesso',
    data: customData,
    status: 'SUCCESS',
  };
}
