import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  installmentValuesFormat,
  payBrandsInfoFormat,
  renderFlagTypeText,
} from '@noitada/shared/utils/payments.utils';

import {
  PaymentChooseContainer,
  PaymentInfoChooseView,
  PaymentChooseTitle,
  PaymentChooseCardNumber,
  PaymentChooseSubtitle,
  PaymentCardView,
  CreditImageFlag,
  PaymentParcelsCardView,
} from './styled';



export interface IProps {
  payType?: string;
  cardBrand?: string;
  parcels?: number;
  last4?: string | null | undefined;
}



const PaymentMethods: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <PaymentChooseContainer>

      <PaymentInfoChooseView>

        <PaymentChooseTitle>
          {translate(TranslateConfig.PAYMENT_FORM)}
        </PaymentChooseTitle>


        <PaymentChooseCardNumber>
          {renderFlagTypeText(props.payType || '')?.name || '-'}
        </PaymentChooseCardNumber>


        <PaymentChooseSubtitle>
          {installmentValuesFormat(props.parcels)}
        </PaymentChooseSubtitle>

      </PaymentInfoChooseView>



      <PaymentCardView>
        <CreditImageFlag
          alt={'ImageCard'}
          src={payBrandsInfoFormat(props.cardBrand || '')?.flag}
        />

        <PaymentParcelsCardView>
          {payBrandsInfoFormat(props.cardBrand || '')?.name || '-'}
        </PaymentParcelsCardView>
      </PaymentCardView>

    </PaymentChooseContainer>

  );
};



export default PaymentMethods;
