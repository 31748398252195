import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const HelpActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

export const HelpVoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

export const HelpVoteText = styled.p`
  margin-bottom: 5px;
  color: gray;
  font-size: 16px;
`;


export const VoteButtonsContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

export const HelpVoteButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-left: 7px;
  margin-right: 7px;
  border-radius: 50px;
  border-color: ${Colors.gray};
  border-style: solid;
  border-width: 2px;
`;

export const HelpContactButton = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
`;
