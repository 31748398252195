import React from 'react';

import {
  useSpring,
  animated,
} from '@react-spring/web';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconMapPin,
} from '../../../Icons';

import {
  LocationItemContainer,
  LocationItemInfoView,
  LocationItemTitle,
  LocationItemIcon,
} from './styled';

const AnimatedLocationItemIcon = animated(LocationItemIcon);



export interface IProps {
  title: any;
  actualCity?: boolean;
  borderWidth?: any;
  onPress?: any;
}



const CardLocationCity: React.FC<IProps> = (props: IProps) => {
  const iconAnimation = useSpring({
    loop: true,
    from: { opacity: 0.2 },
    to: async (next) => {
      while (1) {
        await next({ opacity: 1 });
        await new Promise((resolve) => setTimeout(resolve, 500));
        await next({ opacity: 0.2 });
        await new Promise((resolve) => setTimeout(resolve, 5));
      }
    },
  });



  return (

    <LocationItemContainer
      borderBottomWidth={props.borderWidth}
      onClick={props.onPress}>

      <LocationItemInfoView>
        <LocationItemTitle
          selected={props.actualCity}>
          {props.title}
        </LocationItemTitle>
      </LocationItemInfoView>


      {props.actualCity && (
        <AnimatedLocationItemIcon
          style={{
            opacity: iconAnimation.opacity,
          }}>
          <IconMapPin
            color={Colors.accent}
            size={25}
          />
        </AnimatedLocationItemIcon>
      )}

    </LocationItemContainer>

  );
};



export default CardLocationCity;
