import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EPlatformPayments,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatExtenseDate,
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  stringCapitalize,
} from '@noitada/shared/utils/string.utils';

import {
  FormatMoney,
} from '../../../config/mask.config';

import PaymentMethods from '../PaymentMethods';

import {
  CardPayInfoContainer,
  CardPayItemContent,
  CardPayItemIDText,
  CardPayItemText,
  CardPayUserText,
  CardMethodPayText,
  CardTypeTaxView,
  CardTypeTaxText,
} from './styled';



export interface IProps {
  id?: string;

  realized?: string;
  date?: string;
  payFullName?: string;

  modality?: EPlatformPayments | string;

  delivery?: number | null;
  taxsValue?: number;
  discount?: number;
  tip?: number;

  subTotal?: number;
  total?: number;

  payType?: string;
  parcels?: number;
  last4?: string;
  cardBrand?: string;
}



const CardPayInfo: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  // Função para formatar a string da data realizada
  function getRealizedDateTimeString() {
    const realized = props?.realized;

    if (!realized) {
      return 'Data não disponível';
    }

    const time = FormatIsoDateWithPatternDayjs(realized, 'HH:mm');

    return `Realizado às ${time}`;
  }


  // Função para formatar a string da data do pagamento
  function getPaymentDateTimeString() {
    const date = props?.date;

    if (!date) {
      return 'Data não disponível';
    }

    const time = FormatIsoDateWithPatternDayjs(date, 'HH:mm');

    return `Processado às ${time}`;
  }


  // Função para formatar a data completa (dia da semana e data)
  function getFullDateString(date: string | undefined) {
    if (!date) {
      return 'Data não disponível';
    }

    const dayOfWeek = FormatIsoDateWithPatternDayjs(date, 'dddd');
    const extendedDate = FormatExtenseDate(date);

    return stringCapitalize(`${dayOfWeek}, ${extendedDate}`);
  }


  function renderModalityPay() {
    if (props.modality === EPlatformPayments.WEB) {
      return translate(TranslateConfig.ACTION_PAY_APP);
    }

    if (props.modality === EPlatformPayments.MOBILE || props.modality === EPlatformPayments.POS) {
      return translate(TranslateConfig.ACTION_PAY_PRESENTIAL);
    }

    return '';
  }



  return (

    <CardPayInfoContainer>

      <CardPayItemContent>
        <CardPayItemIDText
          color={Colors.white}>
          ID: #{props.id}
        </CardPayItemIDText>

        <CardPayItemText>
          {getRealizedDateTimeString()}
        </CardPayItemText>

        <CardPayItemText>
          {getFullDateString(props?.realized)}
        </CardPayItemText>
      </CardPayItemContent>



      <CardPayItemContent>
        <CardPayItemText>
          {getPaymentDateTimeString()}
        </CardPayItemText>

        <CardPayItemText>
          {getFullDateString(props?.date)}
        </CardPayItemText>
      </CardPayItemContent>



      {Boolean(props.payFullName) && (
        <CardPayItemContent>
          <CardPayUserText>
            Pago por
          </CardPayUserText>

          <CardPayItemText>
            {props.payFullName || '-'}
          </CardPayItemText>
        </CardPayItemContent>
      )}



      {renderModalityPay() && (
        <CardMethodPayText>
          {renderModalityPay()}
        </CardMethodPayText>
      )}



      <CardPayItemContent>

        <CardTypeTaxView>
          <CardTypeTaxText>
            {translate(TranslateConfig.SUBTOTAL)}
          </CardTypeTaxText>

          <CardTypeTaxText>
            {FormatMoney(props?.subTotal)}
          </CardTypeTaxText>
        </CardTypeTaxView>



        {Boolean(props.delivery) && (
          <CardTypeTaxView>
            <CardTypeTaxText>
              {translate(TranslateConfig.TAX_DELIVERY)}
            </CardTypeTaxText>

            <CardTypeTaxText>
              {FormatMoney(props.delivery)}
            </CardTypeTaxText>
          </CardTypeTaxView>
        )}



        {Boolean(props.taxsValue) && (
          <CardTypeTaxView>
            <CardTypeTaxText>
              {translate(TranslateConfig.TAXES)}
            </CardTypeTaxText>

            <CardTypeTaxText>
              {FormatMoney(props?.taxsValue)}
            </CardTypeTaxText>
          </CardTypeTaxView>
        )}



        <CardTypeTaxView>
          <CardTypeTaxText
            color={Colors.danger}>
            {translate(TranslateConfig.DISCOUNT)}
          </CardTypeTaxText>

          <CardTypeTaxText
            color={Colors.danger}>
            {FormatMoney(props.discount)}
          </CardTypeTaxText>
        </CardTypeTaxView>



        <CardTypeTaxView>
          <CardTypeTaxText
            color={Colors.action}>
            {translate('Acréscimo')}
          </CardTypeTaxText>

          <CardTypeTaxText
            color={Colors.action}>
            {FormatMoney(props.tip)}
          </CardTypeTaxText>
        </CardTypeTaxView>



        <CardTypeTaxView>
          <CardTypeTaxText
            bold
            color={Colors.primary}>
            {translate(TranslateConfig.TOTAL_PAY)}
          </CardTypeTaxText>

          <CardTypeTaxText
            bold
            color={Colors.primary}>
            {FormatMoney(props?.total)}
          </CardTypeTaxText>
        </CardTypeTaxView>

      </CardPayItemContent>



      <CardPayItemContent>

        <PaymentMethods
          payType={props.payType}
          parcels={props.parcels}
          last4={props.last4 || '****'}
          cardBrand={props.cardBrand}
        />

      </CardPayItemContent>

    </CardPayInfoContainer>

  );
};



export default CardPayInfo;
