import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  paymentsTypesMap,
} from '@noitada/shared/utils/payments.utils';

import {
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../../styles/styled.layout';

import {
  DetailsPayToggle,
  PaymentsContainer,
  PaymentsText,
  PaymentsContent,
  PaymentsImage,
} from './styled';



export interface IProps {
  paymentType: any;
}



const SectionPaymentsOn: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [forms, setForms] = useState<Array<any>>([]);
  const [debitCards, setDebitCards] = useState<Array<any>>([]);
  const [creditCards, setCreditCards] = useState<Array<any>>([]);
  const [ticketsCards, setTicketsCards] = useState<Array<any>>([]);



  function renderToggle(
    index: any,
    image: any,
    displayValue: string,
  ) {
    return (

      <DetailsPayToggle
        key={index}
        noPress
        selected
        iconContent={
          <PaymentsImage
            // resizeMode={'contain'}
            src={image}
          />
        }
        height={Sizes.TOOGLE_HEIGHT}
        value={''}
        displayValue={displayValue}
        borderWidth={1.5}
        borderColor={Colors.toolbarLine}
        borderTintColor={Colors.primary}
        backgroundTintColor={Colors.cardBackground}
        borderRadius={Sizes.cardRadius}
        textTintColor={Colors.primary}
      />

    );
  }


  function setPaymentForms() {
    const forms: any = [];
    const debs: any = [];
    const creds: any = [];
    const ticks: any = [];

    if (props.paymentType) {
      props.paymentType.forEach((pay: any) => {
        switch (pay.description) {
          case 'cash': forms.push('cash'); break;
          case 'cheque': forms.push('cheque'); break;
          case 'pix': forms.push('pix'); break;
          case 'debit_elo': debs.push('debit_elo'); break;
          case 'debit_mastercard': debs.push('debit_mastercard'); break;
          case 'debit_visa': debs.push('debit_visa'); break;
          case 'credit_american': creds.push('credit_american'); break;
          case 'credit_diners': creds.push('credit_diners'); break;
          case 'credit_hipercard': creds.push('credit_hipercard'); break;
          case 'credit_elo': creds.push('credit_elo'); break;
          case 'credit_mastercard': creds.push('credit_mastercard'); break;
          case 'credit_visa': creds.push('credit_visa'); break;
          case 'ticket_alelo': ticks.push('ticket_alelo'); break;
          case 'ticket_ben': ticks.push('ticket_ben'); break;
          case 'ticket_sodexo': ticks.push('ticket_sodexo'); break;
          case 'ticket_ticket': ticks.push('ticket_ticket'); break;
          case 'ticket_vr': ticks.push('ticket_vr'); break;
        }
      });
    }
    setForms(forms);
    setDebitCards(debs);
    setCreditCards(creds);
    setTicketsCards(ticks);
  }


  useEffect(() => {
    setPaymentForms();
  }, []);



  return (forms?.length > 0 || debitCards?.length > 0 || creditCards?.length > 0 || ticketsCards?.length > 0)
    ? (
      <DetailsSectionView>
        <SubtitleDetails>
          {translate(TranslateConfig.PARTNER_PAYMENTS_ON)}
        </SubtitleDetails>


        {forms?.length > 0 && (
          <PaymentsContainer>
            <PaymentsText>
              {translate(TranslateConfig.FORMS)}:
            </PaymentsText>


            <PaymentsContent>
              {forms.map((card: any, index: any) => (
                renderToggle(
                  index,
                  Images[paymentsTypesMap[card].image],
                  paymentsTypesMap[card].name,
                )
              ))}
            </PaymentsContent>
          </PaymentsContainer>
        )}


        {debitCards?.length > 0 && (
          <PaymentsContainer>
            <PaymentsText>
              {translate(TranslateConfig.DEBIT_CARD)}:
            </PaymentsText>


            <PaymentsContent>
              {debitCards.map((card: any, index: any) => (
                renderToggle(
                  index,
                  Images[paymentsTypesMap[card].image],
                  paymentsTypesMap[card].name,
                )
              ))}
            </PaymentsContent>
          </PaymentsContainer>
        )}


        {creditCards?.length > 0 && (
          <PaymentsContainer>
            <PaymentsText>
              {translate(TranslateConfig.CREDIT_CARD)}:
            </PaymentsText>


            <PaymentsContent>
              {creditCards.map((card: any, index: any) => (
                renderToggle(
                  index,
                  Images[paymentsTypesMap[card].image],
                  paymentsTypesMap[card].name,
                )
              ))}
            </PaymentsContent>
          </PaymentsContainer>
        )}


        {ticketsCards?.length > 0 && (
          <PaymentsContainer>
            <PaymentsText>
              {translate(TranslateConfig.TICKETS_CARD)}:
            </PaymentsText>


            <PaymentsContent>
              {ticketsCards.map((card: any, index: any) => (
                renderToggle(
                  index,
                  Images[paymentsTypesMap[card].image],
                  paymentsTypesMap[card].name,
                )
              ))}
            </PaymentsContent>
          </PaymentsContainer>
        )}

      </DetailsSectionView>
    )
    : null;
};



export default SectionPaymentsOn;
