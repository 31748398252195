import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraAm: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Coari',
    value: 'COARI',
  },
  {
    id: 2,
    label: 'Itacoatiara',
    value: 'ITACOATIARA',
  },
  {
    id: 3,
    label: 'Manacapuru',
    value: 'MANACAPURU',
  },
  {
    id: 4,
    label: 'Manaus',
    value: 'MANAUS',
  },
  {
    id: 5,
    label: 'Parintins',
    value: 'PARINTINS',
  },
  {
    id: 6,
    label: 'Tabatinga',
    value: 'TABATINGA',
  },
  {
    id: 7,
    label: 'Tefé',
    value: 'TEFE',
  },
];



export default CitiesFullBraAm;
