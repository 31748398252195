import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraMt: Array<IInputPlaceSelect> = [
  {
    id: 10,
    label: 'Alta Floresta',
    value: 'ALTA_FLORESTA',
  },
  {
    id: 8,
    label: 'Barra do Garças',
    value: 'BARRA_DO_GARCAS',
  },
  {
    id: 6,
    label: 'Cáceres',
    value: 'CACERES',
  },
  {
    id: 1,
    label: 'Cuiabá',
    value: 'CUIABA',
  },
  {
    id: 3,
    label: 'Rondonópolis',
    value: 'RONDONOPOLIS',
  },
  {
    id: 7,
    label: 'Sorriso',
    value: 'SORRISO',
  },
  {
    id: 4,
    label: 'Sinop',
    value: 'SINOP',
  },
  {
    id: 9,
    label: 'Primavera do Leste',
    value: 'PRIMAVERA_DO_LESTE',
  },
  {
    id: 5,
    label: 'Tangará da Serra',
    value: 'TANGARA_DA_SERRA',
  },
  {
    id: 2,
    label: 'Várzea Grande',
    value: 'VARZEA_GRANDE',
  },
];



export default CitiesFullBraMt;
