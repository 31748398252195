import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  countriesOption,
} from '@noitada/shared/arrays/App/countries.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  stringRawDataText,
} from '@noitada/shared/utils/string.utils';

import {
  IconMapLocation,
  IconMapPin,
} from '../../../Icons';

import {
  MyLocationButton,
  LocationIconView,
  LocationInfoTextView,
  LocationTitle,
  LocationSubtitle,
  LocationCountryView,
  LocationCountryImage,
} from './styled';



export interface IProps {
  noMargin?: boolean;

  city?: string;
  state?: string;
  country?: string;

  onPress?: any;
}



const LocationUser: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  // FIXME
  const myLocation = true;

  const filterLangCountry = countriesOption.find((country) => stringRawDataText(country.code) === stringRawDataText(props.country));



  return (

    <MyLocationButton
      noMargin={props.noMargin}
      onClick={props.onPress}>

      <LocationIconView>
        {myLocation
          ? (
            <IconMapPin
              color={Colors.white}
              size={26}
            />
          )
          : (
            <IconMapLocation
              color={Colors.white}
              size={26}
            />
          )
        }
      </LocationIconView>


      <LocationInfoTextView>
        <LocationTitle>
          {myLocation
            ? props.city
            : translate(TranslateConfig.ACTION_LOCATION_MY)
          }
        </LocationTitle>


        <LocationSubtitle>
          {myLocation
            ? props.state
            : translate(TranslateConfig.SUBTITLES_SEE_ITEMS_MY_LOCATION)
          }
        </LocationSubtitle>
      </LocationInfoTextView>


      <LocationCountryView>
        <LocationCountryImage
          alt={props.country}
          src={filterLangCountry?.image}
        />
      </LocationCountryView>

    </MyLocationButton>

  );
};



export default LocationUser;
