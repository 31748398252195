import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraRn: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Mossoró',
    value: 'MOSSORO',
  },
  {
    id: 2,
    label: 'Natal',
    value: 'NATAL',
  },
  {
    id: 3,
    label: 'Parnamirim',
    value: 'PARNAMIRIM',
  },
  {
    id: 4,
    label: 'São Gonçalo do Amarante',
    value: 'SAO_GONCALO_DO_AMARANTE',
  },
  {
    id: 5,
    label: 'Caicó',
    value: 'CAICO',
  },
  {
    id: 6,
    label: 'Currais Novos',
    value: 'CURRAIS_NOVOS',
  },
  {
    id: 7,
    label: 'Macaíba',
    value: 'MACAIBA',
  },
  {
    id: 8,
    label: 'Ceará-Mirim',
    value: 'CEARA-MIRIM',
  },
  {
    id: 9,
    label: 'Açu',
    value: 'ACU',
  },
  {
    id: 10,
    label: 'Areia Branca',
    value: 'AREIA_BRANCA',
  },
];



export default CitiesFullBraRn;
