import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

// import {
//   GetLocalitiesApp,
// } from '@noitada/axios-config/endpoints/localities.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  countriesOption,
} from '@noitada/shared/arrays/App/countries.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  stringRawDataText,
  stringUpperCase,
} from '@noitada/shared/utils/string.utils';

import {
  Body,
  Container,
  Overlay,
  SubTitle,
  Title,
} from '../../components/Composh/web';

import {
  ButtonBack,
  ButtonBackSkip,
} from '../../components/Controls';

import FooterChoose from '../../components/Footers/FooterChoose';
import HeaderScreen from '../../components/Headers/HeaderScreen';

import {
  ButtonText,
} from '../../styles/styled.layout';

import LocationCitiesScreen from './Cities';
import LocationCountriesScreen from './Countries';

import {
  CategoryChooseContainer,
  LocationCountryButton,
  LocationCountryImage,
} from './styled';



export interface IProps {
  visible?: boolean;
  type: 'APP' | 'CARNIVAL';
  action: 'RETURN' | 'DISPATCH';
  currentCity?: string;
  onClose?: any;
  onPress?: any;
}



const LocationsScreen: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();


  const allStoreLocalities = useSelector((state: RootState) => state.localities.allLocalities);
  const localities = useSelector((state: RootState) => state.localities.selected);

  const appActualCityName = props.currentCity || localities.city;
  const [appCountryUser, setAppCountryUser] = useState(localities.country);

  const filterLangCountry = countriesOption.find((country) => stringRawDataText(country.code) === stringRawDataText(appCountryUser));


  const widthLine = 330;
  const heightLine = 610;

  const [showCountries, setShowCountries] = useState<boolean>(false);

  const [data, setData] = useState<Array<any>>([]);

  const [countriesApp, setCountriesApp] = useState<Array<any>>([]);
  const [locationsApp, setLocationsApp] = useState<Array<any>>([]);



  function onCloseModal() {
    if (props.onClose) {
      props.onClose();
    }
  };


  function returnCountryListUser(response: any) {
    const countryArray: Array<any> = [];

    if (response?.length > 0) {
      response.forEach((itemCountry: any) => {
        countryArray.push({
          title: itemCountry?.countryName,
        });
      });

      setCountriesApp(countryArray);

      const countryShowList = response?.find((country: any) => {
        const itemA = stringRawDataText(stringUpperCase(country?.countryName));
        const itemB = stringRawDataText(stringUpperCase(appCountryUser));
        return itemA === itemB;
      });

      const arrayStates = countryShowList?.states?.map((stateItem: any) => {
        if (stateItem?.stateName && stateItem?.cities) {
          return {
            title: stateItem?.stateName,
            data: stateItem?.cities,
          };
        }
      })?.filter((itemFilter: any) => !!itemFilter);

      if (!arrayStates || !arrayStates[0]) {
        setLocationsApp([]);
        return;
      }

      setLocationsApp(arrayStates);
    }
    else {
      setCountriesApp([]);
      setLocationsApp([]);
    }
  }


  const getApiLocalities = () => {
    setData(allStoreLocalities);
    returnCountryListUser(allStoreLocalities);
  };


  function returnButtonLeft() {
    if (showCountries) {
      return (

        <ButtonBackSkip
          color={Colors.white}
          size={20}
          onPress={() => {
            setShowCountries(false);
          }}
        />

      );
    }
    else {
      return (

        <ButtonBack
          close
          color={Colors.white}
          onPress={() => {
            onCloseModal();
          }}
        />

      );
    }
  };


  function returnTitle() {
    if (showCountries) {
      return (

        <Title>
          {translate(TranslateConfig.COUNTRIES)}
        </Title>

      );
    }
    else {
      return (

        <Body>
          <Title>
            {translate(TranslateConfig.CITIES)}
          </Title>

          <SubTitle>
            {translate(countriesOption.find((country) => country?.code === filterLangCountry?.code)?.label) || '-'}
          </SubTitle>
        </Body>

      );
    }
  };


  function onCityPress(value: any) {
    if (props.action === 'RETURN') {
      if (props.onPress) {
        props.onPress(value);
      }
    }
    else {
      onCloseModal();
    }

    // const newUrl = `${location.pathname}?gender=${genderChoose}&city=${value?.city}&state=${value?.state}&country=${value?.country}`;
    // history(newUrl, { replace: true });
  }



  useEffect(() => {
    if (appCountryUser && data[0]) {
      returnCountryListUser(data);
    }
  }, [appCountryUser]);


  useEffect(() => {
    getApiLocalities();
  }, []);



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <CategoryChooseContainer
        width={widthLine}
        height={heightLine}>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={returnButtonLeft()}
          centerContent={returnTitle()}
          rightIcon={!showCountries && (
            <LocationCountryButton
              onClick={() => {
                setShowCountries(true);
              }}>
              <LocationCountryImage
                alt={appCountryUser}
                src={filterLangCountry?.image}
              />
            </LocationCountryButton>
          )}>

          {/* 
        TODO: Fazer o SearchBar
        <SubTitle>
          SearchBar
        </SubTitle>
        */}

        </HeaderScreen>



        <Container>

          {!showCountries && (
            <LocationCitiesScreen
              data={locationsApp}
              action={props.action}
              currentCity={appActualCityName}
              countrySelected={appCountryUser}
              onCityPress={(value: any) => {
                onCityPress(value);
              }}
              onShowCountries={() => {
                setShowCountries(true);
              }}
            />
          )}


          {showCountries && (
            <LocationCountriesScreen
              data={countriesApp}
              countrySelected={appCountryUser}
              onCountryPress={(country: string | number) => {
                if (country) {
                  setAppCountryUser(String(country));
                  setShowCountries(false);
                }
              }}
            />
          )}

        </Container>



        <FooterChoose
          hideCancelButton

          okColor={ArrayColors.arrayCancel}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {'Fechar'}
            </ButtonText>
          }
          okPress={() => {
            onCloseModal();
          }}
        />

      </CategoryChooseContainer>

    </Overlay>

  );
};



export default LocationsScreen;
