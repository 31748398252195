export enum EAppBrandPayments {
  WALLET = 'WALLET',
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  PIX = 'PIX',

  AMEX = 'AMEX',
  AURA = 'AURA',
  CABAL = 'CABAL',
  DINERS = 'DINERS',
  DISCOVER = 'DISCOVER',
  ELO = 'ELO',
  HIPERCARD = 'HIPERCARD',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  UNIONPAY = 'UNIONPAY',
  VISA = 'VISA',

  ALELO = 'ALELO',
  BEN = 'BEN',
  SODEXO = 'SODEXO',
  TICKET = 'TICKET',
  VR = 'VR',
}



export default EAppBrandPayments;
