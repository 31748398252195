import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraSe: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Aracaju',
    value: 'ARACAJU',
  },
  {
    id: 2,
    label: 'Estância',
    value: 'ESTANCIA',
  },
  {
    id: 3,
    label: 'Itabaiana',
    value: 'ITABAIANA',
  },
  {
    id: 4,
    label: 'Itabaianinha',
    value: 'ITABAIANINHA',
  },
  {
    id: 5,
    label: 'Lagarto',
    value: 'LAGARTO',
  },
  {
    id: 6,
    label: 'Nossa Senhora da Glória',
    value: 'NOSSA_SENHORA_DA_GLORIA',
  },
  {
    id: 7,
    label: 'Nossa Senhora do Socorro',
    value: 'NOSSA_SENHORA_DO_SOCORRO',
  },
  {
    id: 8,
    label: 'Simão Dias',
    value: 'SIMAO_DIAS',
  },
  {
    id: 9,
    label: 'São Cristóvão',
    value: 'SAO_CRISTOVAO',
  },
  {
    id: 10,
    label: 'Tobias Barreto',
    value: 'TOBIAS_BARRETO',
  },
];



export default CitiesFullBraSe;
