import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ActionSheet,
} from '../../../../components/Composh/web';

import HeaderModal from '../../../../components/Headers/HeaderModal';

import {
  IconCheckAll,
  IconOrderMenu,
} from '../../../../components/Icons';

import NameRoutes from '../../../../navigation/names';

import {
  SheetContainer,
  ListContainer,
  ListButton,
  ListIcon,
  ListLabel,
} from './styled';



export interface IProps {
  visible?: boolean;
  qrRead?: string;
  onCheckIn?: any;
  onClose?: any;
}



const ChooseSheetCheckin: React.FC<IProps> = (props: IProps) => {
  const navigation = useHistory();
  // const { t: translate } = useTranslation();

  const [idPublic, setIdPublic] = useState('');



  function goBack() {
    if (props.onClose) {
      props.onClose();
    }
  };


  const passwordScreens = [
    {
      key: 'seeMenuSheet',
      title: 'Ver cardápio',
      icon: (
        <IconOrderMenu
          size={27}
          color={Colors.white}
        />
      ),
      onPress: () => {
        if (idPublic) {
          navigation.push({
            pathname: NameRoutes.MenuSheetScreen.replace(
              ':company_username',
              idPublic,
            ),
          });
        }
        goBack();
      },
    },
    {
      key: 'makeCheckin',
      title: 'Fazer checkin',
      icon: (
        <IconCheckAll
          size={27}
          color={Colors.white}
        />
      ),
      onPress: () => {
        if (idPublic) {
          props.onCheckIn();
        }
        goBack();
      },
    },
  ];


  function extractID(url: string) {
    const regexUrl = /([a-zA-Z]+)\//;
    const resultShortId = regexUrl.exec(url);

    if (resultShortId) {
      setIdPublic(resultShortId[1]);
    }
    else {
      setIdPublic('');
    }
  }



  useEffect(() => {
    console.log('qrReaded', props.qrRead);
    extractID(props.qrRead || '');
  }, [props.qrRead]);



  return (

    <ActionSheet
      visible={props.visible}
      closeOnDragDown
      closeOnPressMask
      onClose={props.onClose}
      height={180}
      overlayColor={Colors.menuOverlay}>

      <SheetContainer>

        <HeaderModal
          title={`ID Público: ${idPublic || '-'}`}
          onPress={() => {
            goBack();
          }}
        />


        <ListContainer>
          {passwordScreens.map((route, i) => (
            <ListButton
              key={i}
              onClick={route.onPress}>

              <ListIcon>
                {route.icon}
              </ListIcon>


              <ListLabel>
                {route.title}
              </ListLabel>

            </ListButton>
          ))}
        </ListContainer>

      </SheetContainer>

    </ActionSheet>

  );
};



export default ChooseSheetCheckin;
