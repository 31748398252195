import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '@noitada/redux-config';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  RenderHourMinutes,
  getTimeDiff,
} from '@noitada/shared/utils/date.utils';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import CardOrderSheetHistory from '../../../../components/Cards/CardOrders/CardOrderSheetHistory';

import {
  ActionSheet,
  Body,
  SubTitle,
  Title,
  Toolbar,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';

import {
  FormatMoney,
  FormatSymbolMoney,
} from '../../../../config/mask.config';

import {
  SheetContainer,
  SheetStatusViewBadge,
  SheetStatusTextBadge,
  SheetHistoryContainer,
  SheetHistoryCheckinView,
  SheetCheckInView,
  SheetPriceInfoView,
  CheckPriceView,
  SheetTitle,
  SheetSubtitle,
  SheetHourText,
  SheetPriceView,
  SheetTypePriceText,
  TimelineStyled,
} from './styled';



export interface IProps {
  visible?: boolean;
  total: number;
  onOpen?: any;
  onClose?: any;
  orders?: any;
}



const OrderSheetHistoryScreen: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();


  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);


  function closeOrderModal() {
    props.onClose();
  }


  function renderCheckIn(title: string, subTitle: string, hour: string) {
    return (

      <SheetCheckInView>

        <SheetHourText>
          {hour}
        </SheetHourText>


        <SheetTitle>
          {title}
        </SheetTitle>


        <SheetSubtitle>
          {subTitle}
        </SheetSubtitle>

      </SheetCheckInView>

    );
  };


  function renderDetail(item: any, index: number /*, sectionID, rowID */) {
    return (

      <CardOrderSheetHistory
        key={index}
        class={item?.type}
        image={item?.picture}
        quantity={item?.quantity}
        title={item?.saleName}
        status={validateString(item?.status)}
        time={validateString(RenderHourMinutes(item?.orderedAt))}
        price={item?.price}
      />

    );
  }



  return (

    <ActionSheet
      visible={props.visible}
      closeOnDragDown
      closeOnPressMask
      onClose={props.onClose}
      height={610}
      overlayColor={Colors.menuOverlay}>

      <SheetContainer>

        <Toolbar
          backgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
              onPress={() => {
                closeOrderModal();
              }}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.white}>
                {translate(TranslateConfig.HISTORIC)}
              </Title>

              <SubTitle
                color={Colors.gray}>
                {checkinUser?.company?.name}
              </SubTitle>
            </Body>
          }
          rightIcon={
            <SheetStatusViewBadge
              style={{
                backgroundColor: validateString(checkinUser?.checkout) ? Colors.danger : Colors.primary,
              }}>
              <SheetStatusTextBadge>
                {validateString(checkinUser?.checkout)
                  ? translate(TranslateConfig.ORDERSHEET_CLOSED)
                  : translate(TranslateConfig.ORDERSHEET_OPENED)
                }
              </SheetStatusTextBadge>
            </SheetStatusViewBadge>
          }
        />



        <SheetHistoryContainer>

          <SheetHistoryCheckinView>
            {renderCheckIn(
              translate(TranslateConfig.CHECKIN).toUpperCase(),
              `${translate(TranslateConfig.THERE_IS)} ${getTimeDiff(new Date(checkinUser?.checkin))}`,
              RenderHourMinutes(checkinUser?.checkin),
            )}


            {renderCheckIn(
              translate(TranslateConfig.CHECKOUT).toUpperCase(),
              checkinUser?.checkout
                ? `${translate(TranslateConfig.THERE_IS)} ${getTimeDiff(new Date(checkinUser?.checkout))}`
                : translate(TranslateConfig.TO_DO),
              RenderHourMinutes(checkinUser?.checkout),
            )}
          </SheetHistoryCheckinView>



          <SheetPriceInfoView>

            <CheckPriceView>
              <SheetTitle>
                {translate(TranslateConfig.TOTAL_CONSUMPTION).toUpperCase()}
              </SheetTitle>

              {props?.orders[0] && (
                <SheetSubtitle>
                  {`${translate(TranslateConfig.LAST_ORDER_THERE_IS)}: ${getTimeDiff(new Date(props?.orders?.slice(-1)[0]?.orderedAt)) || 0}`}
                </SheetSubtitle>
              )}
            </CheckPriceView>


            <SheetPriceView>
              <SheetTypePriceText>
                {FormatSymbolMoney()}
              </SheetTypePriceText>

              <SheetHourText>
                {FormatMoney(props?.total, false)}
              </SheetHourText>
            </SheetPriceView>

          </SheetPriceInfoView>

        </SheetHistoryContainer>



        {props?.orders?.length === 0 && (
          <EmptyContent
            image={Images.ordersheetWait}
            title={translate(TranslateConfig.EMPTY_ORDERSHEET_HISTORY)}
          />
        )}



        {props?.orders?.length > 0 && (
          <TimelineStyled>

            {props?.orders?.map((rowData: any, index: number) => (
              renderDetail(rowData, index)
            ))}

          </TimelineStyled>
        )}

      </SheetContainer>

    </ActionSheet>

  );
};



export default OrderSheetHistoryScreen;
