import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraSc: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Balneário Camboriú',
    value: 'BALNEARIO_CAMBORIU',
  },
  {
    id: 2,
    label: 'Blumenau',
    value: 'BLUMENAU',
  },
  {
    id: 3,
    label: 'Chapecó',
    value: 'CHAPECO',
  },
  {
    id: 4,
    label: 'Criciúma',
    value: 'CRICIUMA',
  },
  {
    id: 5,
    label: 'Florianópolis',
    value: 'FLORIANOPOLIS',
  },
  {
    id: 6,
    label: 'Itajaí',
    value: 'ITAJAI',
  },
  {
    id: 7,
    label: 'Joinville',
    value: 'JOINVILLE',
  },
  {
    id: 8,
    label: 'Lages',
    value: 'LAGES',
  },
  {
    id: 9,
    label: 'Palhoça',
    value: 'PALHOCA',
  },
  {
    id: 10,
    label: 'São José',
    value: 'SAO_JOSE',
  },
];



export default CitiesFullBraSc;
