import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const StatesFullIta: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Veneto',
    value: 'VENETO',
  },
];



export default StatesFullIta;
