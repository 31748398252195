export enum EWebBrandPayments {
  AMEX = 'AMEX',
  AURA = 'AURA',
  DINERS = 'DINERS',
  DISCOVER = 'DISCOVER',
  ELO = 'ELO',
  HIPERCARD = 'HIPERCARD',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',

  ALELO = 'ALELO',
  SODEXO = 'SODEXO',
  TICKET = 'TICKET',
  VR = 'VR',
}



export default EWebBrandPayments;
