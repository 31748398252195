import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';


const CitiesFullBraAc: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Acrelândia',
    value: 'ACRELANDIA',
    capital: false,
  },
  {
    id: 2,
    label: 'Assis Brasil',
    value: 'ASSIS_BRASIL',
    capital: false,
  },
  {
    id: 3,
    label: 'Brasiléia',
    value: 'BRASILEIA',
    capital: false,
  },
  {
    id: 4,
    label: 'Bujari',
    value: 'BUJARI',
    capital: false,
  },
  {
    id: 5,
    label: 'Capixaba',
    value: 'CAPIXABA',
    capital: false,
  },
  {
    id: 6,
    label: 'Cruzeiro do Sul',
    value: 'CRUZEIRO_DO_SUL',
    capital: false,
  },
  {
    id: 7,
    label: 'Epitaciolândia',
    value: 'EPITACIOLANDIA',
    capital: false,
  },
  {
    id: 8,
    label: 'Feijó',
    value: 'FEIJO',
    capital: false,
  },
  {
    id: 9,
    label: 'Jordão',
    value: 'JORDAO',
    capital: false,
  },
  {
    id: 10,
    label: 'Mâncio Lima',
    value: 'MANCIO_LIMA',
    capital: false,
  },
  {
    id: 11,
    label: 'Manoel Urbano',
    value: 'MANOEL_URBANO',
    capital: false,
  },
  {
    id: 12,
    label: 'Marechal Thaumaturgo',
    value: 'MARECHAL_THAUMATURGO',
    capital: false,
  },
  {
    id: 13,
    label: 'Plácido de Castro',
    value: 'PLACIDO_DE_CASTRO',
    capital: false,
  },
  {
    id: 14,
    label: 'Porto Acre',
    value: 'PORTO_ACRE',
    capital: false,
  },
  {
    id: 15,
    label: 'Porto Walter',
    value: 'PORTO_WALTER',
    capital: false,
  },
  {
    id: 16,
    label: 'Rio Branco',
    value: 'RIO_BRANCO',
    capital: true,
  },
  {
    id: 17,
    label: 'Rodrigues Alves',
    value: 'RODRIGUES_ALVES',
    capital: false,
  },
  {
    id: 18,
    label: 'Santa Rosa do Purus',
    value: 'SANTA_ROSA_DO_PURUS',
    capital: false,
  },
  {
    id: 19,
    label: 'Sena Madureira',
    value: 'SENA_MADUREIRA',
    capital: false,
  },
  {
    id: 20,
    label: 'Senador Guiomard',
    value: 'SENADOR_GUIOMARD',
    capital: false,
  },
  {
    id: 21,
    label: 'Tarauacá',
    value: 'TARAUACA',
    capital: false,
  },
  {
    id: 22,
    label: 'Xapuri',
    value: 'XAPURI',
    capital: false,
  },
];



export default CitiesFullBraAc;
