import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraRr: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Amajari',
    value: 'AMAJARI',
  },
  {
    id: 2,
    label: 'Bonfim',
    value: 'BONFIM',
  },
  {
    id: 3,
    label: 'Boa Vista',
    value: 'BOA_VISTA',
  },
  {
    id: 4,
    label: 'Caracaraí',
    value: 'CARACARAI',
  },
  {
    id: 5,
    label: 'Mucajaí',
    value: 'MUCAJAI',
  },
  {
    id: 6,
    label: 'Normandia',
    value: 'NORMANDIA',
  },
  {
    id: 7,
    label: 'Pacaraima',
    value: 'PACARAIMA',
  },
  {
    id: 8,
    label: 'Rorainópolis',
    value: 'RORAINOPOLIS',
  },
  {
    id: 9,
    label: 'São João da Baliza',
    value: 'SAO_JOAO_DA_BALIZA',
  },
  {
    id: 10,
    label: 'Uiramutã',
    value: 'UIRAMUTA',
  },
];



export default CitiesFullBraRr;
