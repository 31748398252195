import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  DetailsButtonFooter,
  DetailsButtonList,
  DetailsButtonBuy,
  ActionButtonView,
  DetailsIcon,
  DetailsButtonText,
} from './styled';



export interface IProps {
  buttonDisabled?: boolean;
  buttonPress?: any;
  buttonIcon?: any;
  buttonBackgroundColor?: string;
  buttonLabel?: string;

  actionDisabled?: boolean;
  actionPress?: any;
  actionIcon?: any;
  actionLabel?: string;
}



const FooterAction: React.FC<IProps> = (props: IProps) => {
  return (

    <DetailsButtonFooter
      backgrondColor={!props.buttonDisabled
        ? props.buttonBackgroundColor || Colors.actionBackground
        : Colors.grayDark
      }>

      <DetailsButtonList
        // activeOpacity={Sizes.ButtonActiveOpacity}
        // disabled={props.buttonDisabled}
        style={{
          paddingLeft: 30,
          paddingRight: 30,
        }}
        onClick={() => {
          if (!props.buttonDisabled) {
            props.buttonPress();
          }
        }}>

        <ActionButtonView
        // opacity: (availableEvent && haveList()) ? 1 : opactyDisabled,
        >

          <DetailsIcon>
            {props.buttonIcon}
          </DetailsIcon>

          <DetailsButtonText>
            {props.buttonLabel}
          </DetailsButtonText>
        </ActionButtonView>

      </DetailsButtonList>



      <DetailsButtonBuy
        // activeOpacity={Sizes.ButtonActiveOpacity}
        // disabled={props.actionDisabled}
        backgrondColor={!props.actionDisabled
          ? Colors.buttonOk
          : Colors.buttonDisabledDark
        }
        style={{
          paddingLeft: 30,
          paddingRight: 30,
        }}
        onClick={() => {
          if (!props.actionDisabled) {
            props.actionPress();
          }
        }}>

        <ActionButtonView
        // opacity: (availableEvent && haveTickets()) ? 1 : opactyDisabled
        >

          <DetailsIcon>
            {props.actionIcon}
          </DetailsIcon>


          <DetailsButtonText>
            {props.actionLabel}
          </DetailsButtonText>

        </ActionButtonView>
      </DetailsButtonBuy>

    </DetailsButtonFooter>

  );
};



export default FooterAction;
