import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EOrderSheetClass,
  EOrderSheetItems,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import {
  CardHistoryContainer,
  CardHistoryImage,
  HistoryInfoView,
  CardRequestInfoHeader,
  CardRequestQuantityBadge,
  RequestItemName,
  HistoryStatusView,
  HistorySheetStatusText,
  HistorySheetHourText,
  HistorySheetPriceText,
} from './styled';



export interface IProps {
  class?: string;
  image?: string;
  quantity?: number;
  title?: string;
  time?: string | null;
  status?: string | null;
  price?: string;
}



const CardOrderSheetHistory: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function renderStatusText() {
    if (!props.status) {
      return '-';
    }

    const status = props.status?.toUpperCase();

    if (status === EOrderSheetItems.CANCELLED) {
      return translate(TranslateConfig.CANCELED);
    }

    if (status === EOrderSheetItems.CANCELLED_BY_PARTNER) {
      return 'Extornado';
    }

    if (status === EOrderSheetItems.DECLINE) {
      return translate(TranslateConfig.ORDER_REFUSED);
    }

    if (status === EOrderSheetItems.FINISH) {
      return translate(TranslateConfig.FINISHED);
    }

    if (status === EOrderSheetItems.CREATED) {
      return translate(TranslateConfig.ORDER_PLACED);
    }

    if (status === EOrderSheetItems.PREPARING) {
      return translate(TranslateConfig.ORDER_PREPARING);
    }

    if (status === EOrderSheetItems.COMPLETE) {
      return translate(TranslateConfig.ORDER_TO_DELIVER);
    }

    return '-';
  }


  function renderStatusColor() {
    if (!props.status) {
      return Colors.gray;
    }

    const status = props.status?.toUpperCase();

    if (status === EOrderSheetItems.CANCELLED) {
      return Colors.danger;
    }

    if (status === EOrderSheetItems.CANCELLED_BY_PARTNER) {
      return Colors.danger;
    }

    if (status === EOrderSheetItems.DECLINE) {
      return Colors.danger;
    }

    if (status === EOrderSheetItems.FINISH) {
      return Colors.accept;
    }

    if (status === EOrderSheetItems.CREATED) {
      return Colors.primary;
    }

    if (status === EOrderSheetItems.PREPARING) {
      return Colors.attention;
    }

    if (status === EOrderSheetItems.COMPLETE) {
      return Colors.sketch;
    }

    return Colors.gray;
  }


  function renderPriceText() {
    const status = props?.status;
    const priceReceived = props.price || 0;

    if (
      status === EOrderSheetItems.CANCELLED ||
      status === EOrderSheetItems.CANCELLED_BY_PARTNER ||
      status === EOrderSheetItems.DECLINE
    ) {
      return `- ${FormatMoney(priceReceived)}`;
    }


    if (
      status === EOrderSheetItems.PREPARING ||
      status === EOrderSheetItems.COMPLETE ||
      status === EOrderSheetItems.FINISH
    ) {
      return `+ ${FormatMoney(priceReceived)}`;
    }

    return FormatMoney(priceReceived);
  }


  const getPriceColor = () => {
    const status = props?.status;

    if (
      status === EOrderSheetItems.CANCELLED ||
      status === EOrderSheetItems.CANCELLED_BY_PARTNER ||
      status === EOrderSheetItems.DECLINE
    ) {
      return Colors.danger;
    }


    if (
      status === EOrderSheetItems.PREPARING ||
      status === EOrderSheetItems.COMPLETE ||
      status === EOrderSheetItems.FINISH
    ) {
      return Colors.accept;
    }

    return Colors.white;
  };


  function renderPictureItem() {
    if (props.class === EOrderSheetClass.SERVICE) {
      return Images.ordersheetService;
    }

    return validateString(props.image)
      ? props.image
      : Images.placeholderAvatarCompany;
  }



  return (

    <CardHistoryContainer>

      <CardHistoryImage
        src={renderPictureItem()}
      />


      <HistoryInfoView>

        <CardRequestInfoHeader>
          {props.class !== EOrderSheetClass.SERVICE && (
            <CardRequestQuantityBadge>
              {props?.quantity || '-'}
            </CardRequestQuantityBadge>
          )}

          <RequestItemName>
            {props.title || '-'}
          </RequestItemName>
        </CardRequestInfoHeader>


        <HistoryStatusView>
          <HistorySheetStatusText
            color={renderStatusColor()}>
            {renderStatusText()}
          </HistorySheetStatusText>


          <HistorySheetHourText>
            {props.time || '-'}
          </HistorySheetHourText>
        </HistoryStatusView>

      </HistoryInfoView>



      {props.price && (
        <HistorySheetPriceText
          style={{ color: getPriceColor() }}>
          {renderPriceText()}
        </HistorySheetPriceText>
      )}

    </CardHistoryContainer>

  );
};



export default CardOrderSheetHistory;
