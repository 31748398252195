export enum EOrderSheetItems {
  SOLD = 'SOLD',
  CREATED = 'CREATED',
  PREPARING = 'PREPARING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  CANCELLED_BY_PARTNER = 'CANCELLED_BY_PARTNER',
  FINISH = 'FINISH',
  DECLINE = 'DECLINE',
}



export default EOrderSheetItems;
