import React,
{
  useState,
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserCurrentCheckin,
  MakeCheckout,
} from '@noitada/axios-config/endpoints/order-sheet-checkin.endpoints';

import {
  CancelCallWaiters,
  CreateWaiters,
} from '@noitada/axios-config/endpoints/waiter.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_RESET_CHECKIN,
  USER_SET_CHECKIN,
  USER_SET_WIFI_MODAL,
} from '@noitada/redux-config/reducers/comanda.store';

import {
  USER_CLEAR_SACOLA,
} from '@noitada/redux-config/reducers/order-bag.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import EOrderSheet from '@noitada/shared/enums/Orders/order-sheet.enum';
import EWaiters from '@noitada/shared/enums/Waiters/waiters.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  RenderHourMinutes,
} from '@noitada/shared/utils/date.utils';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import AlertMessage from '../../../../components/Alerts';
import CardOrderSheetInfo from '../../../../components/Cards/CardOrders/CardOrderSheetInfo';

import {
  Container,
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconExclamation,
  IconOrderBag,
  IconOrderMenu,
  IconWifi,
} from '../../../../components/Icons';

import LoadingProgress from '../../../../components/Loadings/LoadingProgress';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import {
  openHelpWhatsApp,
} from '../../../../config/linking.config';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import ModalFinished from '../../../../modals/ModalFinished';

import NameRoutes from '../../../../navigation/names';

import {
  ButtonText,
} from '../../../../styles/styled.layout';

import CloseOrderActionSheet from '../Modals/PayOrderModal';
import WifiModal from '../Modals/WifiModal';

import OrderSheetHistoryScreen from '../OrderSheetHistory';

import {
  OrderSheetHeaderButton,
  OrderSubToolbar,
  OrderHelloTitle,
  OrderBagButton,
  OrderBagTitle,
  OrderBadgeView,
  OrderBadgeText,
  PlaceSheetView,
  PlaceImageSheetView,
  SheetPlaceViewImage,
  SheetPlaceImage,
  SheetPlaceNameText,
  PlaceItemSheetView,
  SheetPlaceTitle,
  TableNumberTitle,
  PlacePriceSheetView,
  TablePriceTitle,
  ContentOrderSheet,
  OrderSheetActionView,
  OrderSheetActionButton,
  OrderSheetActionIcon,
  OrderSheetActionText,
  SheetHelpFooterView,
  SheetHelpView,
  WaiterSheetButton,
  WaiterSheetView,
  WaiterAttentionIcon,
  WaiterSheetImage,
  WaiterSheetContent,
  WaiterSheetText,
  WaiterSheetCancelText,
  HelpSheetButton,
  HelpSheetTitle,
  HelpSheetText,
} from './styled';



const OrderSheetScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);
  const company = useSelector((state: RootState) => state.company.selected);
  const sacola = useSelector((state: RootState) => state.sacola);
  const showWifi = useSelector((state: RootState) => state.comanda.wifiModal);


  const time = 5;

  const nameUser = user?.publicName?.split(' ')[0];
  const wifiName = checkinUser?.company?.company_about?.wifiName;
  const wifiPassword = checkinUser?.company?.company_about?.wifiPassword;

  const [loading, setLoading] = useState<boolean>(false);

  const [statusOrderSheet, setStatusOrderSheet] = useState<string>(checkinUser?.status);

  const [statusWaiters, setStatusWaiters] = useState<EWaiters | null>(null);

  const [loadingWaiters, setLoadingWaiters] = useState<boolean>(false);

  const [showDoneModal, setShowDoneModal] = useState<boolean>(false);
  const [showPartnerFinishModal, setShowPartnerFinishModal] = useState<boolean>(false);
  const [showWifiModal, setShowWifiModal] = useState<boolean>(false);
  const [makeCheckout, setMakeCheckout] = useState<boolean>(false);
  const [closeOrderModal, setCloseOrderModal] = useState<boolean>(false);
  const [historyModal, setHistoryModal] = useState<boolean>(false);
  const [tempo, setTempo] = useState({ tempo: time });



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function GetOrder() {
    try {
      const response = await GetUserCurrentCheckin(checkinUser?.id);

      if (response && response?.status) {
        dispatch({ type: USER_SET_CHECKIN, payload: response });
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
  }



  function renderTextCallWaiter(status: EWaiters | null): string {
    if (!status) {
      return translate(TranslateConfig.WAITER_CALL).toUpperCase();
    }
    switch (status) {
      case EWaiters.CREATED:
        return translate(TranslateConfig.WAITER_CALLING).toUpperCase();

      case EWaiters.ACCEPTED:
        return translate(TranslateConfig.WAITER_GOING).toUpperCase();

      case EWaiters.CANCELED:
      case EWaiters.FINISH:
      default:
        return translate(TranslateConfig.WAITER_CALL).toUpperCase();
    }
  }


  function renderColorCallWaiter(status: EWaiters | null): string {
    if (!status) {
      return Colors.accent;
    }
    switch (status) {
      case EWaiters.CREATED:
        return Colors.accept;

      case EWaiters.ACCEPTED:
        return Colors.danger;

      case EWaiters.CANCELED:
      case EWaiters.FINISH:
      default:
        return Colors.accent;
    }
  }


  function renderTextOrderButton(): string {
    if (statusOrderSheet === EOrderSheet.APPROVED) {
      return translate(TranslateConfig.ACTION_CLOSE_ORDERSHEET);
    }

    if (checkinUser?.totalConsumed === 0) {
      return translate(TranslateConfig.ACTION_CLOSE_ORDERSHEET);
    }

    if (statusOrderSheet === EOrderSheet.CHECK_WAIT) {
      return translate(TranslateConfig.HELP_ORDERSHEET_CLOSING);
    }

    if (statusOrderSheet === EOrderSheet.PAYMENT_WAIT) {
      return translate(TranslateConfig.ACTION_PAY_ORDERSHEET);
    }

    return translate(TranslateConfig.LOADING);
  }


  async function cancelWaiterFunction() {
    try {
      setLoadingWaiters(true);

      await CancelCallWaiters(checkinUser?.id);
      await GetOrder();
    }
    catch (error: any) {
      console.error(error);
    }
    finally {
      setLoadingWaiters(false);
    }
  };


  function orderPress() {
    if (statusOrderSheet === EOrderSheet.APPROVED) {
      setMakeCheckout(true);
      return;
    }

    if (statusOrderSheet === EOrderSheet.PAYMENT_WAIT) {
      // Show Payments Menu
      setCloseOrderModal(true);
      return;
    }

    if (checkinUser?.totalConsumed === 0) {
      // FIXME
      // return 'Fechar Checkout';
    }
  }


  async function callWaiterFunction() {
    if (checkinUser?.waiters) {
      setLoadingWaiters(true);

      try {
        const statusWaiter = checkinUser?.waiters[0];

        if (!statusWaiter) {
          await CreateWaiters(checkinUser?.id);
          const checkinData = await GetUserCurrentCheckin(checkinUser?.id);

          if (checkinData && checkinUser?.status) {
            dispatch({ type: USER_SET_CHECKIN, payload: checkinData });
          }
        }
      }
      catch (error: any) {
        console.error(error);
      }
      finally {
        setLoadingWaiters(false);
      }
    }
  };


  async function okCheckOutPay() {
    if (statusOrderSheet === EOrderSheet.APPROVED) {
      setStatusOrderSheet('CHECKOUT');

      try {
        setLoading(true);

        const data = await MakeCheckout();

        if (data?.status === EOrderSheet.COMPLETED) {
          setShowDoneModal(true);
          return;
        }
        dispatch({ type: USER_SET_CHECKIN, payload: data });
      }
      catch (error: any) {
        console.error(error);
      }
      finally {
        setLoading(false);
      }
    }
  }


  function onModalDonePress() {
    setShowDoneModal(false);
    dispatch({ type: USER_CLEAR_SACOLA });
    dispatch({ type: USER_RESET_CHECKIN });
    history.replace(NameRoutes.GridEventsScreen);
  }


  function seeChangeStatusUser() {
    setStatusOrderSheet(checkinUser?.status);

    if (checkinUser?.waiters) {
      const statusWaiter = checkinUser?.waiters?.length > 0 ? checkinUser?.waiters[0] : null;

      if (statusWaiter) {
        setStatusWaiters(statusWaiter?.status);
      }
      else {
        setStatusWaiters(null);
      }
    }
    else {
      setStatusWaiters(null);
    }

    if (checkinUser?.status === EOrderSheet.COMPLETED) {
      setShowPartnerFinishModal(true);
    }
  }


  function onPartnerClosePress() {
    dispatch({ type: USER_RESET_CHECKIN });
    history.replace(NameRoutes.GridEventsScreen);
  }



  useEffect(() => {
    GetOrder();
    const interval = setInterval(() => {
      setTempo((prevState) => {
        return {
          tempo: prevState.tempo - 1,
        };
      });
    }, 850);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (tempo.tempo <= -5) {
      setTempo({ tempo: time });
    }
    if (tempo.tempo === 0) {
      GetOrder();
    }
  }, [tempo]);


  useEffect(() => {
    if (!(checkinUser && company)) {
      history.replace(NameRoutes.GridEventsScreen);
    }
    setStatusOrderSheet(checkinUser?.status);
    seeChangeStatusUser();
  }, [checkinUser]);


  useEffect(() => {
    if (showWifi) {
      setTimeout(() => {
        setShowWifiModal(true);
      }, 1000);
    }
  }, [showWifi]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <OrderSheetHeaderButton
          // start={{ x: 0, y: 0 }}
          // end={{ x: 1, y: 1 }}
          locations={[0.35, 0.99]}
          colors={[Colors.primary, Colors.primaryDark]}>

          <HeaderScreen
            transparent
            leftIcon={
              <ButtonBack
                close
                color={Colors.white}
              />
            }
            centerContent={
              <Title
                color={Colors.white}>
                {translate(TranslateConfig.ORDERSHEET)}
              </Title>
            }>


            <OrderSubToolbar>

              <OrderHelloTitle>
                {`${translate(TranslateConfig.HELLO)}, ${nameUser || ''}`}
              </OrderHelloTitle>


              <OrderBagButton
                // disabled={checkout}
                // activeOpacity={Sizes.CardActiveOpacity}
                onClick={() => {
                  if (statusOrderSheet === EOrderSheet.APPROVED) {
                    history.push(NameRoutes.OrderBagScreen);
                  }
                }}>

                <IconOrderBag
                  color={statusOrderSheet !== EOrderSheet.APPROVED ? Colors.gray : Colors.white}
                  size={30}
                />


                <OrderBagTitle
                  style={{
                    color: statusOrderSheet !== EOrderSheet.APPROVED ? Colors.gray : Colors.white,
                  }}>
                  {translate(TranslateConfig.ORDERBAG)}
                </OrderBagTitle>

                {sacola?.items?.length > 0 && (
                  <OrderBadgeView>
                    <OrderBadgeText>
                      {sacola?.items?.length}
                    </OrderBadgeText>
                  </OrderBadgeView>
                )}

              </OrderBagButton>

            </OrderSubToolbar>

          </HeaderScreen>



          <PlaceSheetView>

            <PlaceImageSheetView>

              <SheetPlaceViewImage
                onClick={() => {
                  history.push({
                    pathname: NameRoutes.DetailsCompanyScreen.replace(
                      ':company_username',
                      company?.company_username,
                    ),
                    state: {
                      showOptions: false,
                    },
                  });
                }}>
                <SheetPlaceImage
                  alt={'Logo'}
                  src={validateString(checkinUser?.company?.picture)
                    ? checkinUser?.company?.picture
                    : Images?.placeholderAvatarCompany
                  }
                />
              </SheetPlaceViewImage>


              <SheetPlaceNameText>
                {checkinUser?.company?.name || '-'}
              </SheetPlaceNameText>

            </PlaceImageSheetView>



            <PlaceItemSheetView>
              <SheetPlaceTitle>
                {translate(TranslateConfig.CHECKIN)}
              </SheetPlaceTitle>

              <TableNumberTitle>
                {RenderHourMinutes(checkinUser?.checkin) || '--'}
              </TableNumberTitle>
            </PlaceItemSheetView>



            <PlacePriceSheetView>
              <TablePriceTitle>
                {FormatMoney(checkinUser?.totalConsumed)}
              </TablePriceTitle>


              <SheetPlaceTitle>
                {translate(TranslateConfig.TOTAL_CONSUMPTION)}
              </SheetPlaceTitle>
            </PlacePriceSheetView>

          </PlaceSheetView>

        </OrderSheetHeaderButton>



        <Content>

          {loading && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}



          {!loading && statusOrderSheet === EOrderSheet.APPROVED && (
            <ContentOrderSheet>

              <OrderSheetActionView>

                <OrderSheetActionButton
                  disabled={!wifiName || !wifiPassword}
                  onClick={() => {
                    if (wifiName && wifiPassword) {
                      setShowWifiModal(true);
                    }
                  }}>

                  <OrderSheetActionIcon>
                    <IconWifi
                      color={Colors.white}
                      size={22}
                    />
                  </OrderSheetActionIcon>

                  <OrderSheetActionText>
                    {translate(TranslateConfig.ACTION_SEE_WIFI)}
                  </OrderSheetActionText>

                </OrderSheetActionButton>



                <OrderSheetActionButton
                  onClick={() => {
                    history.push(
                      NameRoutes.MenuSheetScreen.replace(
                        ':company_username',
                        company.company_username,
                      ),
                      // { company },
                    );
                  }}>

                  <OrderSheetActionIcon>
                    <IconOrderMenu
                      color={Colors.white}
                      size={22}
                    />
                  </OrderSheetActionIcon>

                  <OrderSheetActionText>
                    {translate(TranslateConfig.ACTION_SEE_MENUSHEET)}
                  </OrderSheetActionText>

                </OrderSheetActionButton>

              </OrderSheetActionView>



              <CardOrderSheetInfo
                orders={checkinUser?.orders}
                numberTable={checkinUser?.table}
                numberOrder={checkinUser?.number_ordersheet}
                statusCompany={checkinUser?.company?.status}
                okPress={() => {
                  setHistoryModal(true);
                }}
              />



              <SheetHelpFooterView>

                <SheetHelpView>

                  {/* FIXME: Liberar se a comanda for de pagar depois */}
                  {(
                    <WaiterSheetButton
                      // activeOpacity={Sizes.CardActiveOpacity}
                      onClick={() => {
                        if (!loadingWaiters) {
                          if (statusWaiters === EWaiters.CREATED) {
                            cancelWaiterFunction();
                          }
                          else {
                            callWaiterFunction();
                          }
                        }
                      }}>
                      <WaiterSheetView>
                        <WaiterSheetImage
                          // resizeMode={'cover'}
                          src={Images.placeholderAvatarWaiter}
                        />

                        {statusWaiters === EWaiters.ACCEPTED && !loadingWaiters && (
                          <WaiterAttentionIcon>
                            <IconExclamation
                              size={18}
                              color={Colors.white}
                            />
                          </WaiterAttentionIcon>
                        )}
                      </WaiterSheetView>


                      <WaiterSheetContent>
                        {!loadingWaiters && (
                          <>
                            <WaiterSheetText
                              color={renderColorCallWaiter(statusWaiters)}>
                              {renderTextCallWaiter(statusWaiters)}
                            </WaiterSheetText>


                            {statusWaiters === EWaiters.CREATED && (
                              <WaiterSheetCancelText>
                                {translate(TranslateConfig.ACTION_CLICK_CANCEL)}
                              </WaiterSheetCancelText>
                            )}
                          </>
                        )}

                        {loadingWaiters && (
                          <WaiterSheetText>
                            <LoadingProgress
                              color={Colors.accent}
                              size={30}
                            />
                          </WaiterSheetText>
                        )}
                      </WaiterSheetContent>

                    </WaiterSheetButton>
                  )}



                  <HelpSheetButton
                    // activeOpacity={Sizes.CardActiveOpacity}
                    onClick={() => {
                      openHelpWhatsApp();
                    }}>
                    <HelpSheetTitle>
                      {translate(TranslateConfig.HELP_SOME)}
                    </HelpSheetTitle>

                    <HelpSheetText>
                      {translate(TranslateConfig.HELP_TALK_TO_US)}
                    </HelpSheetText>
                  </HelpSheetButton>

                </SheetHelpView>
              </SheetHelpFooterView>

            </ContentOrderSheet>
          )}



          {!loading && statusOrderSheet === EOrderSheet.CHECK_WAIT && (
            <EmptyContent
              image={Images.orderSheetFinishing}
              title={translate(TranslateConfig.ORDERSHEET_CLOSING_ORDERSHEET)}
              titleSize={24}
              description={translate(TranslateConfig.INTERPOLATE_ORDERSHEET_CLOSING).replace(
                '{company_name}',
                checkinUser?.company?.name,
              )}
              descriptionSize={15.5}

              buttonText={translate(TranslateConfig.ACTION_HISTORY)}
              onPress={() => {
                setHistoryModal(true);
              }}
            />
          )}



          {!loading && statusOrderSheet === EOrderSheet.PAYMENT_WAIT && (
            <EmptyContent
              image={Images.orderSheetDone}
              title={translate(TranslateConfig.ORDERSHEET_CLOSED)}
              titleSize={24}
              description={translate(TranslateConfig.INTERPOLATE_ORDERSHEET_CLOSE).replace(
                '{company_name}',
                checkinUser?.company?.name,
              )}
              descriptionSize={15.5}

              buttonText={translate(TranslateConfig.ACTION_HISTORY)}
              onPress={() => {
                setHistoryModal(true);
              }}
            />
          )}

        </Content>



        <FooterChoose
          hideCancelButton

          okDisabled={loading || statusOrderSheet === EOrderSheet.CHECK_WAIT || !statusOrderSheet}
          okColor={loading || statusOrderSheet === EOrderSheet.CHECK_WAIT
            ? ArrayColors.arrayDisabled
            : ArrayColors.arrayOk
          }
          okLabel={
            <ButtonText
              color={Colors.white}>
              {renderTextOrderButton()}
            </ButtonText>
          }
          okPress={() => {
            orderPress();
          }}
        />

      </Container>



      <ModalFinished
        visible={showDoneModal}
        title={translate(TranslateConfig.HELP_ORDERSHEET_THANKS)}
        buttonText={translate(TranslateConfig.ACTION_CLOSE)}
        onPress={() => {
          onModalDonePress();
        }}
      />



      <AlertMessage
        visible={showPartnerFinishModal}
        title={translate(TranslateConfig.HELP_ORDERSHEET_PARTNER_CLOSE)}
        okText={translate(TranslateConfig.ACTION_CLOSE)}
        okPress={() => {
          onPartnerClosePress();
        }}
      />



      <AlertMessage
        visible={makeCheckout}
        title={translate(TranslateConfig.QUESTION_ORDERSHEET_CLOSE)}
        cancelText={translate(TranslateConfig.ACTION_NO)}
        cancelPress={() => {
          setMakeCheckout(false);
        }}
        okText={translate(TranslateConfig.ACTION_YES)}
        okPress={() => {
          setMakeCheckout(false);
          okCheckOutPay();
        }}
      />



      <WifiModal
        visible={showWifiModal}
        name={nameUser}
        picture={checkinUser?.company?.picture}
        companyName={checkinUser?.company?.name}
        wifiName={wifiName}
        wifiPassword={wifiPassword}
        onClose={() => {
          setShowWifiModal(false);
          dispatch({ type: USER_SET_WIFI_MODAL, payload: false });
        }}
      />



      <CloseOrderActionSheet
        visible={closeOrderModal}
        company={checkinUser?.company}
        onOpen={() => {
          setCloseOrderModal(true);
        }}
        onClose={() => {
          setCloseOrderModal(false);
        }}
      />



      <OrderSheetHistoryScreen
        orders={checkinUser?.orders}
        total={checkinUser?.totalConsumed}
        visible={historyModal}
        onClose={() => {
          setHistoryModal(false);
        }}
      />

    </Screen>

  );
};



export default OrderSheetScreen;
