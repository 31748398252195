import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Title,
} from '../../Composh/web';



export interface IProps {
  bold?: boolean;
  color?: string;
}



export const CardPayInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


export const CardPayItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CardPayItemIDText = styled(Title)`
  margin-bottom: 5px;
`;



export const CardPayItemText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
`;

export const CardPayUserText = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
`;



export const CardMethodPayText = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${Colors.white};
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
`;


export const CardTypeTaxView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardTypeTaxText = styled.p`
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 16px;
  font-weight: ${(props: IProps) => props.bold ? 'bold' : '400'};
`;
