import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserCurrentCheckin,
} from '@noitada/axios-config/endpoints/order-sheet-checkin.endpoints';

import {
  AddItem,
} from '@noitada/axios-config/endpoints/order-sheet.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CHECKIN,
} from '@noitada/redux-config/reducers/comanda.store';

import {
  USER_CLEAR_SACOLA,
  USER_REMOVE_SACOLA_ITEM,
  USER_UPDATE_SACOLA_ITEM,
} from '@noitada/redux-config/reducers/order-bag.store';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import AlertMessage from '../../../../components/Alerts';
import ButtonSeeMore from '../../../../components/Buttons/ButtonSeeMore';
import CardOrderBagBuy from '../../../../components/Cards/CardOrders/CardOrderBagBuy';

import {
  Screen,
  Title,
  Container,
  Content,
  ToolbarGroup,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import DetailsHeaderBlur from '../../../../components/Details/DetailsHeaderBlur';
import FooterAction from '../../../../components/Footers/FooterAction';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconDelete,
  IconOrderSheet,
} from '../../../../components/Icons';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import ModalOk from '../../../../modals/ModalOk';

import NameRoutes from '../../../../navigation/names';

import {
  CleanBagButton,
  HeaderBagView,
  BagPlaceMinOrderView,
  BagPlaceMinOrderText,
  BagTotalTitle,
  BagTotalText,
} from './styled';



const OrderBagScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const company = useSelector((state: RootState) => state.company.selected);
  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);
  const sacola = useSelector((state: RootState) => state.sacola);
  const totalPrice = useSelector((state: RootState) => state.sacola?.total);


  const [showDeleteItemBag, setShowDeleteItemBag] = useState<{ index: number, name: string } | null>(null);
  const [showClearModal, setShowClearModal] = useState<boolean>(false);
  const [modalOk, setModalOk] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function doOrderSheet() {
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const items: any[] = [];
    for (let i = 0; i < sacola.items.length; i++) {
      const item = sacola.items[i];

      const payloadItem = {
        itemSaleId: item?.itemSale?.id,
        itemGroupId: item?.itemGroup.id,
        observations: '',
        comments: item?.itemSale?.comments || '',
        quantity: item?.quantity,
      };

      items.push(payloadItem);
    }

    try {
      await AddItem({ items });

      const checkinData = await GetUserCurrentCheckin();

      dispatch({ type: USER_SET_CHECKIN, payload: checkinData });
      dispatch({ type: USER_CLEAR_SACOLA });

      setModalOk(true);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function indexToRemoveFromBag() {
    dispatch({ type: USER_REMOVE_SACOLA_ITEM, payload: { idx: showDeleteItemBag?.index } });
    setShowDeleteItemBag(null);
  }


  async function updateItem(item: any, index: number, value: any) {
    const copied = { ...item };

    if (value === -1) {
      if (copied.quantity === 1) {
        setShowDeleteItemBag({
          index: index,
          name: item?.itemSale?.name,
        });
        return;
      }
      else {
        copied.quantity--;
      }
    }
    else if (value === 1) {
      copied.quantity++;
    }

    dispatch({ type: USER_UPDATE_SACOLA_ITEM, payload: { idx: index, item: copied } });
  }


  async function clearBag() {
    dispatch({ type: USER_CLEAR_SACOLA });
    setShowClearModal(false);
  }


  const isActionDisabled = () => {
    const totalBagValue = sacola?.items?.reduce((itemA: any, itemB: any) => {
      return itemA + (itemB?.itemGroup?.price * itemB?.quantity);
    }, 0) || 0;

    const minOrderValue = parseFloat(company?.company_params?.minOrderSheet || '0');

    // O botão será desabilitado se não houver itens na sacola ou se o valor total for menor que o mínimo,
    // a menos que o valor mínimo seja zero (sem restrições).
    return sacola?.items?.length <= 0 || (minOrderValue > 0 && totalBagValue < minOrderValue);
  };


  function renderItem(item: any, index: number) {
    return (

      <CardOrderBagBuy
        title={item?.itemSale?.name}
        titleGroup={item?.itemGroup?.name}
        image={item?.itemSale?.picture}
        comments={item?.itemSale?.comments}
        quantity={item?.quantity}
        price={FormatMoney(item?.itemGroup.price * item?.quantity)}
        updateQuantity={(value: any) => {
          updateItem(item, index, value);
        }}
      />

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
            />
          }
          centerContent={
            <Title>
              {translate(TranslateConfig.ORDERBAG)}
            </Title>
          }
          rightIcon={
            <ToolbarGroup>

              <CleanBagButton>
                <BagTotalTitle>
                  {translate(TranslateConfig.TOTAL_BAG)}
                </BagTotalTitle>


                <BagTotalText>
                  {FormatMoney(totalPrice)}
                </BagTotalText>
              </CleanBagButton>

            </ToolbarGroup>
          }
        />



        <Content>

          <DetailsHeaderBlur
            image={company?.picture}
            banner={company?.picture}
            name={company?.name}
            username={company?.company_username}
          />



          <HeaderBagView>

            {sacola?.items?.map((item: any, index: number) => (
              renderItem(item, index)
            ))}



            <BagPlaceMinOrderView>

              {!validateString(company?.company_params?.minOrderSheet) && (
                <BagPlaceMinOrderText>
                  {translate(TranslateConfig.NO_VALUE_MINIMAL)}
                </BagPlaceMinOrderText>
              )}

              {validateString(company?.company_params?.minOrderSheet) && (
                <BagPlaceMinOrderText>
                  {translate(TranslateConfig.MIN_ORDERSHEET)}: {FormatMoney(company?.company_params?.minOrderSheet)}
                </BagPlaceMinOrderText>
              )}

            </BagPlaceMinOrderView>

          </HeaderBagView>



          <ButtonSeeMore
            title={translate(TranslateConfig.ACTION_ADD_MORE_ITEMS)}
            onPress={() => {
              history.push({
                pathname: NameRoutes.MenuSheetScreen.replace(
                  ':company_username',
                  checkinUser?.company?.company_username,
                ),
              });
            }}
          />

        </Content>



        <FooterAction
          buttonIcon={
            <IconDelete
              color={Colors.white}
              size={20}
            />
          }
          buttonLabel={translate(TranslateConfig.ACTION_BAG_CLEAN)}
          buttonPress={() => {
            setShowClearModal(true);
          }}

          actionDisabled={isActionDisabled()}
          actionIcon={
            <IconOrderSheet
              color={Colors.white}
              size={Sizes.iconAndroidSize}
            />
          }
          actionLabel={translate(TranslateConfig.ACTION_FINISH_ORDER)}
          actionPress={() => {
            doOrderSheet();
          }}
        />

      </Container>



      <AlertMessage
        visible={Boolean(showDeleteItemBag)}
        title={translate(TranslateConfig.QUESTION_BAG_ITEM_REMOVE).replace('{itemSaleName}', showDeleteItemBag?.name || '-')}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          setShowDeleteItemBag(null);
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          indexToRemoveFromBag();
        }}
      />



      <AlertMessage
        visible={showClearModal}
        title={translate(TranslateConfig.QUESTION_BAG_CLEAR)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          setShowClearModal(false);
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          clearBag();
        }}
      />



      <ModalOk
        visible={modalOk}
        backgroundColor={Colors.cardBackground}
        image={Images.orderDone}
        title={translate(TranslateConfig.SUCCESS_ORDER_DONE)}
        description={translate(TranslateConfig.HELP_SUCCESS_ORDER_DONE)}
        buttonText={translate(TranslateConfig.ACTION_CLOSE)}
        onPress={() => {
          history.goBack();
        }}
      />

    </Screen>

  );
};



export default OrderBagScreen;
