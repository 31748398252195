export enum ECompanyStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  CONSTRUCTION = 'CONSTRUCTION',
  OPEN = 'OPEN',
  OPEN_PAUSED = 'OPEN_PAUSED',
  CLOSED = 'CLOSED',
}



export default ECompanyStatus;
