import React from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  IInputSelect,
} from '@noitada/axios-config/interfaces/App/input-select.interface';

import {
  USER_LOCATION,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  returnCityArray,
  returnStateArray,
} from '@noitada/shared/utils/address.utils';

import {
  setLocalStorageItem,
} from '@noitada/shared/utils/local-storage.utils';

import CardLocationCity from '../../../components/Cards/CardLocation/LocationCity';

import {
  Container,
  ListSection,
} from '../../../components/Composh/web';

import EmptyContent from '../../../components/Empty';

import {
  LocationListHeader,
} from './styled';



export interface IProps {
  data?: Array<any>;
  action: 'RETURN' | 'DISPATCH';
  currentCity?: string;
  onCityPress?: any;
  countrySelected?: string;
  onShowCountries?: any;
}



const LocationCitiesScreen: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();

  const arrayLocations = props.data;
  const actualCityName = props.currentCity;

  const countryUser = props.countrySelected || '';



  // // TODO: Evento a partir da localização
  // function renderLocationButton() {
  //   return (

  //     <LocationUser
  //       onPress={() => {
  //         goBack();
  //       }}
  //     />

  //   );
  // }


  const renderSection = (section: { title: string; }) => {
    const getStateArray = returnStateArray(countryUser);
    const findState = getStateArray?.find((item) => item?.value === section?.title);
    const stateUser = findState?.label || '';

    return (

      <LocationListHeader
        bold
        text={stateUser}
        backgroundColor={Colors.primary}
        textSize={18}
        textColor={Colors.white}
      />

    );
  };


  const renderCard = (titleReceived: any, index: any) => {
    const stateFind = arrayLocations?.find((state: any) => state?.data?.find((city: any) => city === titleReceived))?.title;

    const getCityArray = returnCityArray(countryUser, stateFind);
    const findCity = getCityArray?.find((item: IInputSelect) => item?.value === titleReceived);
    const cityUser = findCity?.label;

    const dataLocation = {
      city: findCity?.value,
      state: stateFind,
      country: countryUser,
    };


    return (

      <CardLocationCity
        key={index}
        title={cityUser || '-'}
        actualCity={actualCityName === findCity?.value}
        borderWidth={index ? 1 : 0}
        onPress={() => {
          setLocalStorageItem(USER_LOCATION, dataLocation);

          if (props.action === 'DISPATCH') {
            dispatch({ type: USER_LOCATION, payload: dataLocation });
          }

          props.onCityPress(dataLocation);
        }}
      />

    );
  };


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.locationGlobal}
        // TODO
        // title={translate(TranslateConfig.EMPTY_PLACES)}
        title={'Não há itens ainda! Escolha um país para ver outros locais'}
        buttonText={'Escolher país'}
        onPress={() => {
          props.onShowCountries();
        }}
      />

    );
  }



  return (

    <Container
      backgroundColor={Colors.appBackground}>

      {(!arrayLocations || arrayLocations?.length === 0) && (
        listEmptyComponent()
      )}


      {arrayLocations && arrayLocations?.length > 0 && (
        <ListSection
          sections={arrayLocations}
          // ListHeaderComponent={renderLocationButton} // TODO: Fazer o 'A partir da minha Localização'
          keyExtractor={(_: any, index: any) => String(index)}
          renderItem={renderCard}
          renderSectionHeader={renderSection}
        />
      )}

    </Container>

  );
};



export default LocationCitiesScreen;
