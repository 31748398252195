import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const DashProfileContainer = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 250px;
  min-width: 250px;
  height: 133px;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;



export const HeightCoverImageStyle = css`
  height: 37px;
`;

export const DashProfileImageView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  ${HeightCoverImageStyle};
`;

export const HeaderImageStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${HeightCoverImageStyle};
`;

export const DashProfileHeaderImage = styled.img`
  ${HeaderImageStyle};
  width: 100%;
  background-color: ${Colors.primaryDark};
  object-fit: cover;
  z-index: 0;
  filter: blur(3px);
`;

export const ImageOverlayUser = styled.div`
  display: flex;
  flex-direction: column;
  ${HeaderImageStyle};
  background-color: rgba(0, 0, 0, 0.55);
`;


export const DashProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 11px;
  padding-right: 10px;
  padding-bottom: 8px;
  background-color: ${Colors.cardBackground};
  z-index: 5;
`;

export const DashProfileInfoView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 4px;
`;


export const DashLeftInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -36px;
  padding-bottom: 3px;
`;

export const DashProfileImage = styled.img`
  height: 62px;
  width: 62px;
  background-color: black;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const DashProfileStarView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 5px;
`;

export const DashProfileRatingText = styled.p`
  margin-left: 8px;
  color: ${Colors.white};
  font-size: 13px;
`;



export const DashProfileTextsView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
`;

export const DashProfileNameText = styled.p`
  flex: 1;
  height: 45px;
  font-size: 16px;
  color: white;
`;



export const DashProfileRatingView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DashProfileLocationIcon = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;
`;

export const DashProfileLocationView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 3px;
`;

export const DashProfileLocationText = styled.p`
  display: flex;
  flex: 1;
  margin-left: 4px;
  color: ${Colors.white};
  font-size: 13px;
`;


export const DashProfileOpenedText = styled.p`
  margin-left: 8px;
  color: ${Colors.accent};
  font-size: 13px;
`;

export const DashProfileFlexStarView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;
