import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraPe: Array<IInputPlaceSelect> = [
  {
    id: 1,
    label: 'Caruaru',
    value: 'CARUARU',
  },
  {
    id: 2,
    label: 'Garanhuns',
    value: 'GARANHUNS',
  },
  {
    id: 3,
    label: 'Jaboatão dos Guararapes',
    value: 'JABOATAO_DOS_GUARARAPES',
  },
  {
    id: 4,
    label: 'Olinda',
    value: 'OLINDA',
  },
  {
    id: 5,
    label: 'Paulista',
    value: 'PAULISTA',
  },
  {
    id: 6,
    label: 'Petrolina',
    value: 'PETROLINA',
  },
  {
    id: 7,
    label: 'Recife',
    value: 'RECIFE',
  },
  {
    id: 8,
    label: 'Santa Cruz do Capibaribe',
    value: 'SANTA_CRUZ_DO_CAPIBARIBE',
  },
  {
    id: 9,
    label: 'Serra Talhada',
    value: 'SERRA_TALHADA',
  },
  {
    id: 10,
    label: 'Vitória de Santo Antão',
    value: 'VITORIA_DE_SANTO_ANTAO',
  },
];



export default CitiesFullBraPe;
