import {
  IPaymentObject,
} from '@noitada/axios-config/interfaces';

import {
  Colors,
  Images,
} from '../constants';

import {
  EBanksKeyPayments,
  EAppBrandPayments,
  EPosBrandPayments,
  EAppStatusPayments,
  EPosStatusPayments,
  EWebStatusPayments,
  ETypePayments,
  EWebBrandPayments,
  EPlatformPayments,
  EManipulatePayments,
  ETransferStatusPayment,
} from '../enums';

import {
  normalizeRawText,
  stringRawDataText,
  stringUpperCase,
} from './string.utils';



type PaymentKey = keyof typeof Images;

interface IKeysRecord {
  name: string;
  image: PaymentKey;
}



export const paymentsTypesMap: Record<string, IKeysRecord> = {
  cash: {
    name: 'Dinheiro',
    image: 'flagTypeCash',
  },
  cheque: {
    name: 'Cheque',
    image: 'flagTypeCheque',
  },
  pix: {
    name: 'Pix',
    image: 'flagTypePix',
  },
  debit_elo: {
    name: 'Elo',
    image: 'flagCardElo',
  },
  debit_mastercard: {
    name: 'Mastercard',
    image: 'flagCardMastercard',
  },
  debit_visa: {
    name: 'Visa',
    image: 'flagCardVisa',
  },
  credit_american: {
    name: 'American Express',
    image: 'flagCardAmerican',
  },
  credit_diners: {
    name: 'Diners',
    image: 'flagCardDiners',
  },
  credit_hipercard: {
    name: 'Hipercard',
    image: 'flagCardHipercard',
  },
  credit_elo: {
    name: 'Elo',
    image: 'flagCardElo',
  },
  credit_mastercard: {
    name: 'Mastercard',
    image: 'flagCardMastercard',
  },
  credit_visa: {
    name: 'Visa',
    image: 'flagCardVisa',
  },
  ticket_alelo: {
    name: 'Alelo',
    image: 'flagTicketAlelo',
  },
  ticket_ben: {
    name: 'Ben',
    image: 'flagTicketBen',
  },
  ticket_sodexo: {
    name: 'Sodexo',
    image: 'flagTicketSodexo',
  },
  ticket_ticket: {
    name: 'Ticket',
    image: 'flagTicketTicket',
  },
  ticket_vr: {
    name: 'Vr',
    image: 'flagTicketVr',
  },
  amex: {
    name: 'Amex',
    image: 'flagCardAmerican',
  },
  dinersclub: {
    name: 'Dinersclub',
    image: 'flagCardAmerican',
  },
  elo: {
    name: 'Elo',
    image: 'flagCardElo',
  },
  hipercard: {
    name: 'Hipercard',
    image: 'flagCardHipercard',
  },
  mastercard: {
    name: 'Mastercard',
    image: 'flagCardMastercard',
  },
  visa: {
    name: 'Visa',
    image: 'flagCardVisa',
  },
};



export function renderStatusPaymentDetails(value: string): IPaymentObject {
  const valueReceived = stringUpperCase(value) || '';

  switch (valueReceived) {
    case EAppStatusPayments.UNKNOWN:
    case EPosStatusPayments.UNKNOWN:
    case EWebStatusPayments.UNKNOWN:
      return {
        name: 'Desconhecido',
        enum: EAppStatusPayments.UNKNOWN,
        color: Colors.gray,
      };

    case EAppStatusPayments.AUTHORIZED:
    case EWebStatusPayments.AUTHORIZED:
      return {
        name: 'Autorizado',
        enum: EAppStatusPayments.AUTHORIZED,
        color: Colors.primary,
      };

    case EAppStatusPayments.APPROVED:
    case EPosStatusPayments.APPROVED:
    case EWebStatusPayments.PAID:
      return {
        name: 'Aprovado',
        enum: EAppStatusPayments.APPROVED,
        color: Colors.accept,
      };

    case EAppStatusPayments.PENDING:
    case EPosStatusPayments.PENDING:
    case EWebStatusPayments.ANALYZING:
    case EWebStatusPayments.PROCESSING:
    case EWebStatusPayments.WAITING_PAYMENT:
    case EWebStatusPayments.PENDING_REVIEW:
      return {
        name: 'Pendente',
        enum: EAppStatusPayments.PENDING,
        color: Colors.attention,
      };

    case EAppStatusPayments.DECLINED:
    case EPosStatusPayments.REJECTED:
    case EPosStatusPayments.CANCELLED:
    case EPosStatusPayments.DECLINED:
    case EPosStatusPayments.DECLINED_BY_CARD:
    case EPosStatusPayments.WITH_ERROR:
    case EPosStatusPayments.TECHNICAL_ERROR:
    case EWebStatusPayments.REFUSED:
      return {
        name: 'Recusado',
        enum: EAppStatusPayments.DECLINED,
        color: Colors.danger,
      };

    case EAppStatusPayments.PARTIAL_APPROVED:
    case EPosStatusPayments.PARTIAL_APPROVED:
      return {
        name: 'Aprovado parcialmente',
        enum: EAppStatusPayments.PARTIAL_APPROVED,
        color: Colors.action,
      };

    case EAppStatusPayments.REQUEST_REFUNDED:
      return {
        name: 'Reembolso solicitado',
        enum: EAppStatusPayments.REQUEST_REFUNDED,
        color: Colors.attention,
      };

    case EAppStatusPayments.PENDING_REFUND:
      return {
        name: 'Reembolso pendente',
        enum: EAppStatusPayments.PENDING_REFUND,
        color: Colors.attention,
      };

    case EAppStatusPayments.REFUNDED:
    case EWebStatusPayments.REFUNDED:
      return {
        name: 'Reembolsado',
        enum: EAppStatusPayments.REFUNDED,
        color: Colors.sketch,
      };

    case EAppStatusPayments.CHARGEDBACK:
    case EWebStatusPayments.CHARGEDBACK:
      return {
        name: 'Chargeback',
        enum: EAppStatusPayments.CHARGEDBACK,
        color: Colors.sketch,
      };

    default:
      return {
        name: normalizeRawText(valueReceived) || valueReceived,
        enum: stringRawDataText(valueReceived) || valueReceived,
        color: Colors.gray,
      };
  }
}



export function installmentValuesFormat(installments: number | null | undefined): string {
  if (installments && installments === 1) {
    return 'À Vista';
  }

  if (installments && installments > 1) {
    return `${installments} Vezes`;
  }

  return 'Sem parcelamento';
}



export function platformTextFormat(value: string): string {
  const valueReceived = stringUpperCase(value) || '';

  switch (valueReceived) {
    case EPlatformPayments.WEB:
      return 'Web';

    case EPlatformPayments.MOBILE:
      return 'Mobile';

    case EPlatformPayments.POS:
      return 'Maquininha [POS]';

    default:
      return '';
  }
}



export function transferStatusTextFormat(value: string): IPaymentObject {
  const valueReceived = stringUpperCase(value) || '';

  switch (valueReceived) {
    case ETransferStatusPayment.UNKNOWN:
      return {
        name: 'Desconhecido',
        enum: stringRawDataText(valueReceived) || valueReceived,
        color: Colors.gray,
      };

    case ETransferStatusPayment.PENDING:
      return {
        name: 'Pendente',
        enum: stringRawDataText(valueReceived) || valueReceived,
        color: Colors.attention,
      };

    case ETransferStatusPayment.AVAILABLE:
      return {
        name: 'Disponível',
        enum: stringRawDataText(valueReceived) || valueReceived,
        color: Colors.accept,
      };

    case ETransferStatusPayment.RECEIPT_DONE:
      return {
        name: 'Recebimento feito',
        enum: stringRawDataText(valueReceived) || valueReceived,
        color: Colors.accept,
      };

    case ETransferStatusPayment.REFUSED:
      return {
        name: 'Recusado',
        enum: stringRawDataText(valueReceived) || valueReceived,
        color: Colors.danger,
      };

    case ETransferStatusPayment.REFUNDED:
      return {
        name: 'Reembolsado',
        enum: stringRawDataText(valueReceived) || valueReceived,
        color: Colors.sketch,
      };

    default:
      return {
        name: normalizeRawText(valueReceived) || valueReceived,
        enum: stringRawDataText(valueReceived) || valueReceived,
        color: Colors.gray,
      };
  }
}



export function renderPayManipulate(value: string): IPaymentObject {
  const valueReceived = stringUpperCase(value) || '';

  switch (valueReceived) {
    case EManipulatePayments.DISCOUNT:
      return {
        name: 'Desconto',
        enum: EManipulatePayments.DISCOUNT,
        flag: Images.modeDiscount,
        logo: '',
      };

    case EManipulatePayments.ADDITION:
      return {
        name: 'Acréscimo',
        enum: EManipulatePayments.ADDITION,
        flag: Images.modeDiscount,
        logo: '',
      };

    default:
      return {
        name: normalizeRawText(valueReceived) || valueReceived,
        enum: stringRawDataText(valueReceived) || valueReceived,
        flag: '',
        logo: '',
      };
  }
}



export function renderFlagTypeText(value: string): IPaymentObject {
  const valueReceived = stringUpperCase(value) || '';

  switch (valueReceived) {
    case ETypePayments.PAID_MARKED:
      return {
        name: 'Marcado como pago',
        enum: ETypePayments.PAID_MARKED,
        flag: Images.flagTypeWallet,
        logo: Images.logoTypeWallet,
        color: Colors.action,
      };

    case ETypePayments.WALLET:
      return {
        name: 'Carteira Noitada',
        enum: ETypePayments.WALLET,
        flag: Images.flagTypeWallet,
        logo: Images.logoTypeWallet,
        color: Colors.primary,
      };

    case ETypePayments.CASH:
      return {
        name: 'Dinheiro',
        enum: ETypePayments.CASH,
        flag: Images.flagTypeCash,
        logo: Images.flagTypeCash,
        color: Colors.accept,
      };

    case ETypePayments.CHEQUE:
      return {
        name: 'Cheque',
        enum: ETypePayments.CHEQUE,
        flag: Images.flagTypeCheque,
        logo: Images.flagTypeCheque,
        color: Colors.accept,
      };

    case ETypePayments.PIX:
      return {
        name: 'Pix',
        enum: ETypePayments.PIX,
        flag: Images.flagTypePix,
        logo: Images.flagTypePix,
        color: Colors.accept,
      };

    case ETypePayments.CREDIT:
      return {
        name: 'Cartão de crédito',
        enum: ETypePayments.CREDIT,
        flag: Images.flagTypeCredit,
        logo: Images.flagTypeCredit,
        color: Colors.sketch,
      };

    case ETypePayments.DEBIT:
      return {
        name: 'Cartão de débito',
        enum: ETypePayments.DEBIT,
        flag: Images.flagTypeDebit,
        logo: Images.flagTypeDebit,
        color: Colors.action,
      };

    case ETypePayments.TICKET:
      return {
        name: 'Cartão ticket',
        enum: ETypePayments.TICKET,
        flag: Images.flagTypeTicket,
        logo: Images.flagTypeTicket,
        color: Colors.sketch,
      };

    default:
      return {
        name: normalizeRawText(valueReceived) || valueReceived,
        enum: stringRawDataText(valueReceived) || valueReceived,
        flag: '',
        logo: '',
        color: Colors.gray,
      };
  }
}



export function banksInfoFormat(value: string): IPaymentObject {
  const valueReceived = stringUpperCase(value) || '';

  switch (valueReceived) {
    case EBanksKeyPayments.NUBANK:
      return {
        name: 'Nu Bank',
        enum: EBanksKeyPayments.NUBANK,
        flag: Images.flagBankNubank,
        logo: Images.logoBankNubank,
        color: '#5F249F',
      };

    case EBanksKeyPayments.PAGARME:
      return {
        name: 'PagarMe',
        enum: EBanksKeyPayments.PAGARME,
        flag: Images.flagBankPagarme,
        logo: Images.logoBankPagarme,
      };

    case EBanksKeyPayments.STONE:
      return {
        name: 'Stone',
        enum: EBanksKeyPayments.STONE,
        flag: Images.flagBankStone,
        logo: Images.logoBankStone,
      };

    default:
      return {
        name: normalizeRawText(valueReceived) || valueReceived,
        enum: stringRawDataText(valueReceived) || valueReceived,
        flag: '',
        logo: '',
      };
  }
}



export function payBrandsInfoFormat(value: string): IPaymentObject {
  const valueReceived = stringUpperCase(value) || '';

  switch (valueReceived) {
    // Modes    

    case EAppBrandPayments.WALLET:
      return {
        name: 'Carteira Noitada',
        enum: EAppBrandPayments.WALLET,
        flag: Images.flagTypeWallet,
        logo: '',
      };

    case EAppBrandPayments.CASH:
      return {
        name: 'Dinheiro',
        enum: EAppBrandPayments.CASH,
        flag: Images.flagTypeCash,
        logo: Images.logoTypeCash,
      };

    case EAppBrandPayments.CHEQUE:
      return {
        name: 'Cheque',
        enum: EAppBrandPayments.CHEQUE,
        flag: Images.flagTypeCheque,
        logo: Images.logoTypeCheque,
      };

    case EAppBrandPayments.PIX:
      return {
        name: 'Pix',
        enum: EAppBrandPayments.PIX,
        flag: Images.flagTypePix,
        logo: Images.logoTypePix,
      };


    // Cards Credits / Debits

    case EAppBrandPayments.AMEX:
    case EPosBrandPayments.AMERICAN:
    case EWebBrandPayments.AMEX:
      return {
        name: 'American Express',
        enum: EAppBrandPayments.AMEX,
        flag: Images.flagCardAmerican,
        logo: Images.logoCreditAmerican,
        color: '#2577b6',
      };

    case EAppBrandPayments.AURA:
    case EWebBrandPayments.AURA:
      return {
        name: 'Aura',
        enum: EAppBrandPayments.AURA,
        flag: Images.flagCardAura,
        logo: Images.logoCreditAura,
      };

    case EAppBrandPayments.CABAL:
      return {
        name: 'Cabal',
        enum: EAppBrandPayments.CABAL,
        flag: Images.flagCardCabal,
        logo: Images.logoCreditCabal,
      };

    case EAppBrandPayments.DINERS:
    case EPosBrandPayments.DINERSCLUB:
    case EWebBrandPayments.DINERS:
      return {
        name: 'Diners',
        enum: EAppBrandPayments.DINERS,
        flag: Images.flagCardDiners,
        logo: Images.logoCreditDiners,
        color: '#2E3192',
      };

    case EAppBrandPayments.DISCOVER:
    case EWebBrandPayments.DISCOVER:
      return {
        name: 'Discover',
        enum: EAppBrandPayments.DISCOVER,
        flag: Images.flagCardDiscover,
        logo: Images.logoCreditDiscover,
      };

    case EAppBrandPayments.ELO:
    case EWebBrandPayments.ELO:
      return {
        name: 'Elo',
        enum: EAppBrandPayments.ELO,
        flag: Images.flagCardElo,
        logo: Images.logoCreditElo,
        color: '#000000',
      };

    case EAppBrandPayments.HIPERCARD:
    case EWebBrandPayments.HIPERCARD:
      return {
        name: 'Hipercard',
        enum: EAppBrandPayments.HIPERCARD,
        flag: Images.flagCardHipercard,
        logo: Images.logoCreditHipercard,
        color: '#7e1016',
      };

    case EAppBrandPayments.JCB:
    case EWebBrandPayments.JCB:
      return {
        name: 'JCB',
        enum: EAppBrandPayments.JCB,
        flag: Images.flagCardJcb,
        logo: Images.logoCreditJcb,
      };

    case EAppBrandPayments.MASTERCARD:
    case EWebBrandPayments.MASTERCARD:
      return {
        name: 'Mastercard',
        enum: EAppBrandPayments.MASTERCARD,
        flag: Images.flagCardMastercard,
        logo: Images.logoCreditMastercard,
        color: '#a23c01',
      };

    case EAppBrandPayments.UNIONPAY:
      return {
        name: 'UnionPay',
        enum: EAppBrandPayments.UNIONPAY,
        flag: Images.flagCardUnionpay,
        logo: Images.logoCreditUnionpay,
      };

    case EAppBrandPayments.VISA:
    case EWebBrandPayments.VISA:
      return {
        name: 'Visa',
        enum: EAppBrandPayments.VISA,
        flag: Images.flagCardVisa,
        logo: Images.logoCreditVisa,
        color: '#1a1f71',
      };


    // Cards Tickets

    case EAppBrandPayments.ALELO:
    case EWebBrandPayments.ALELO:
      return {
        name: 'Alelo',
        enum: EAppBrandPayments.ALELO,
        flag: Images.flagTicketAlelo,
        logo: '',
      };

    case EAppBrandPayments.BEN:
      return {
        name: 'Ben',
        enum: EAppBrandPayments.BEN,
        flag: Images.flagTicketBen,
        logo: '',
      };

    case EAppBrandPayments.SODEXO:
    case EWebBrandPayments.SODEXO:
      return {
        name: 'Sodexo',
        enum: EAppBrandPayments.SODEXO,
        flag: Images.flagDefault,
        logo: Images.logoIconAppWhite,
      };

    case EAppBrandPayments.TICKET:
    case EWebBrandPayments.TICKET:
      return {
        name: 'Ticket',
        enum: EAppBrandPayments.TICKET,
        flag: Images.flagTicketTicket,
        logo: '',
      };

    case EAppBrandPayments.VR:
    case EWebBrandPayments.VR:
      return {
        name: 'Vr',
        enum: EAppBrandPayments.VR,
        flag: Images.flagTicketVr,
        logo: '',
      };


    // Default

    default:
      return {
        name: normalizeRawText(valueReceived) || valueReceived,
        enum: stringRawDataText(valueReceived) || valueReceived,
        flag: Images.flagDefault,
        logo: Images.logoIconAppWhite,
      };
  }
}
