import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  fontSize?: number;
  backgroundColor?: string;
  color?: string;
  fontWeight?: string;
}



export const CheckInUserLevel = styled.p`
  min-height: 22px;
  min-width: 40px;
  padding-top: 3px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 2px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.black};
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: ${(props: IProps) => props.fontSize || 11.5}px;
  font-weight: ${(props: IProps) => props.fontWeight};
  text-align: center;
  border-radius: 50px;
`;
