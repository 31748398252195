import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ECompanyModality,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  IconStore,
  IconForks,
  IconDelivery,
} from '../components/Icons';



export function returnIconCompanyModalities(value: string | undefined, color?: string, size?: number) {
  const colorProp = color || Colors.white;
  const sizeProp = size;


  if (!value) {
    return;
  }

  switch (value) {
    case ECompanyModality.ORDERSHEET:
      return (
        <IconStore
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyModality.REMOVAL_ITEM:
      return (
        <IconForks
          color={colorProp}
          size={sizeProp}
        />
      );

    case ECompanyModality.DELIVERY:
      return (
        <IconDelivery
          color={colorProp}
          size={sizeProp}
        />
      );

    default:
      return null;
  }
};



export function renderTypeTextModalities(type: string) {
  switch (type) {
    case ECompanyModality.ORDERSHEET:
      return TranslateConfig.ORDERSHEET;

    case ECompanyModality.REMOVAL_ITEM:
      return TranslateConfig.REMOVAL_ITEM;

    case ECompanyModality.DELIVERY:
      return TranslateConfig.DELIVERY;

    default:
      return '-';
  }
}
