import React,
{
  useState,
} from 'react';

import {
  Helmet,
} from 'react-helmet-async';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '@noitada/redux-config';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Container,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';
import TabBarStyled from '../../../components/TabBar';

import LocationsScreen from '../../../modals/Locations';

import TabSearchEventScreen from './Tabs/TabEvents';
import TabSearchCompanyScreen from './Tabs/TabProfiles';



const SearchScreen: React.FC = () => {
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);


  const [stateIndex, setStateIndex] = useState(0);

  const [openLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [searchLocality, setSearchLocality] = useState(localities);

  const screenTabs = [
    {
      name: 'SearchCompany',
      title: translate(TranslateConfig.PARTNERS),
    },
    {
      name: 'SearchEvent',
      title: translate(TranslateConfig.EVENTS),
    },
  ];



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <Helmet>
        <title>
          {`${translate(TranslateConfig.SEARCH)} | ${translate(TranslateConfig.NAME_APP)}`}
        </title>
      </Helmet>



      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
            />
          }
          centerContent={
            <Title>
              {translate(TranslateConfig.SEARCH)}
            </Title>
          }
          noSubToolbarPadding>

          <TabBarStyled
            tabs={screenTabs}
            selected={stateIndex}
            onPress={(tab) => {
              setStateIndex(tab?.index);
            }}
          />

        </HeaderScreen>



        {stateIndex === 0 && (
          <TabSearchCompanyScreen
            location={searchLocality}
            openLocation={(status: boolean) => {
              setShowLocationModal(status);
            }}
          />
        )}



        {stateIndex === 1 && (
          <TabSearchEventScreen
            location={searchLocality}
            openLocation={(status: boolean) => {
              setShowLocationModal(status);
            }}
          />
        )}

      </Container>



      <LocationsScreen
        visible={openLocationModal}
        type={'APP'}
        action={'RETURN'}
        currentCity={searchLocality?.city}
        onClose={() => {
          setShowLocationModal(false);
        }}
        onPress={(location: any) => {
          setSearchLocality(location);
          setShowLocationModal(false);
        }}
      />

    </Screen>

  );
};



export default SearchScreen;
