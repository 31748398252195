import {
  IInputPlaceSelect,
} from '@noitada/axios-config/interfaces/App/input-select-place.interface';



const CitiesFullBraBa: Array<IInputPlaceSelect> = [
  {
    id: 2,
    label: 'Alagoinhas',
    value: 'ALAGOINHAS',
  },
  {
    id: 3,
    label: 'Barreiras',
    value: 'BARREIRAS',
  },
  {
    id: 4,
    label: 'Camaçari',
    value: 'CAMACARI',
  },
  {
    id: 5,
    label: 'Candeias',
    value: 'CANDEIAS',
  },
  {
    id: 6,
    label: 'Feira de Santana',
    value: 'FEIRA_DE_SANTANA',
  },
  {
    id: 7,
    label: 'Ilhéus',
    value: 'ILHEUS',
  },
  {
    id: 8,
    label: 'Itabuna',
    value: 'ITABUNA',
  },
  {
    id: 9,
    label: 'Jequié',
    value: 'JEQUIE',
  },
  {
    id: 10,
    label: 'Juazeiro',
    value: 'JUAZEIRO',
  },
  {
    id: 11,
    label: 'Lauro de Freitas',
    value: 'LAURO_DE_FREITAS',
  },
  {
    id: 1,
    label: 'Salvador',
    value: 'SALVADOR',
  },
  {
    id: 12,
    label: 'Santo Antônio de Jesus',
    value: 'SANTO_ANTONIO_DE_JESUS',
  },
  {
    id: 13,
    label: 'Simões Filho',
    value: 'SIMOES_FILHO',
  },
  {
    id: 14,
    label: 'Vitória da Conquista',
    value: 'VITORIA_DA_CONQUISTA',
  },
];



export default CitiesFullBraBa;
