import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import Vars from '@noitada/shared/environments/variables';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonUserCheckin from '../../../components/Buttons/ButtonUserCheckin';

import {
  Body,
  Screen,
  SubTitle,
  Title,
  ToolbarGroup,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconConfig,
  IconCreditCard,
  IconDollar,
  IconEyeOff,
  IconEyeOn,
  IconTransactions,
  IconTransfer,
} from '../../../components/Icons';

import {
  FormatMoney,
  FormatSymbolMoney,
} from '../../../config/mask.config';

import NameRoutes from '../../../navigation/names';

import {
  OrderSheetHeaderButton,
  PlaceSheetView,
  SheetPriceView,
  SheetTypePriceText,
  SheetHourText,
  WalletShowValueContainer,
  WalletShowValueButton,
  WalletActionHeaderApp,
  WalletActionConfigView,
  WalletActionConfigText,
  WalletOptionsContainer,
  WalletOptionsContent,
  WalletOptionsItems,
  WalletOptionsButton,
  WalletOptionsIcon,
  WalletOptionsText,
  WalletFooterOptionsText,
} from './styled';



const WalletScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const [priceWallet, setPriceWallet] = useState<number>(0);

  const [showBalance, setShowBalance] = useState<boolean>(false);



  function renderItemWallet(icon: any, title: string, onPress: any) {
    return (

      <WalletOptionsItems>

        <WalletOptionsButton
          onClick={() => {
            if (onPress) {
              onPress();
            }
          }}>

          <WalletOptionsIcon>
            {icon}
          </WalletOptionsIcon>



          <WalletOptionsText>
            {title}
          </WalletOptionsText>

        </WalletOptionsButton>

      </WalletOptionsItems>

    );
  }


  function openWebScreen(title: string, link: string, action?: string | null) {
    history.push({
      pathname: NameRoutes.WebViewScreen,
      state: {
        title,
        link,
        action,
      },
    });
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.WALLET });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <OrderSheetHeaderButton
        // start={{ x: 0, y: 0 }}
        // end={{ x: 1, y: 1 }}
        locations={[0.35, 0.99]}
        colors={[Colors.primary, Colors.primaryDark]}>

        <HeaderScreen
          transparent
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.white}>
                {translate(TranslateConfig.WALLET)}
              </Title>

              <SubTitle
                color={Colors.white}>
                {user?.publicName.split(' ')[0]}
              </SubTitle>
            </Body>
          }
          rightIcon={
            <ToolbarGroup>
              <ButtonMore
                color={Colors.white}
              />
            </ToolbarGroup>
          }
        />



        <PlaceSheetView>

          <SheetPriceView>

            <SheetTypePriceText>
              {FormatSymbolMoney()}
            </SheetTypePriceText>


            <SheetHourText>
              {showBalance
                ? FormatMoney(priceWallet, false)
                : '• • •'
              }
            </SheetHourText>

          </SheetPriceView>



          <WalletShowValueContainer>

            <WalletShowValueButton
              onClick={() => {
                setShowBalance(!showBalance);
              }}>
              {showBalance
                ? (
                  <IconEyeOff
                    color={Colors.white}
                    size={26}
                  />
                )
                : (
                  <IconEyeOn
                    color={Colors.white}
                    size={26}
                  />
                )
              }
            </WalletShowValueButton>



            <WalletActionHeaderApp>
              <ButtonUserCheckin
                size={65}
              />
            </WalletActionHeaderApp>

          </WalletShowValueContainer>

        </PlaceSheetView>



        <WalletActionConfigView
          onClick={() => {
            history.push(NameRoutes.SettingWalletNoitagScreen);
          }}>

          <IconConfig
            color={Colors.white}
            size={25}
          />


          <WalletActionConfigText>
            Configurar carteira / Noitag
          </WalletActionConfigText>

        </WalletActionConfigView>

      </OrderSheetHeaderButton>



      <WalletOptionsContainer>

        <WalletOptionsContent>

          {renderItemWallet(
            <IconDollar
              size={Sizes.iconAndroidSize}
              color={Colors.white}
            />,
            'Recarregar carteira',
            () => {
              //
            },
          )}


          {renderItemWallet(
            <IconTransfer
              size={Sizes.iconAndroidSize}
              color={Colors.white}
            />,
            'Transferir valor da carteira',
            () => {
              //
            },
          )}


          {renderItemWallet(
            <IconCreditCard
              size={Sizes.iconAndroidSize}
              color={Colors.white}
            />,
            translate(TranslateConfig.PAYMENTS_FORMS),
            () => {
              openWebScreen(
                translate(TranslateConfig.PAYMENTS_FORMS),
                Vars().globalAccountCreditCard,
                translate(TranslateConfig.ACTION_PAY_FORMS_SEE),
              );
            },
          )}


          {renderItemWallet(
            <IconTransactions
              size={Sizes.iconAndroidSize}
              color={Colors.white}
            />,
            'Todas as transações',
            () => {
              history.push(NameRoutes.TransactionsScreen);
            },
          )}

        </WalletOptionsContent>



        <WalletFooterOptionsText>
          Todas as informações são criptografadas!
        </WalletFooterOptionsText>

      </WalletOptionsContainer>

    </Screen>

  );
};



export default WalletScreen;
