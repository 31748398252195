// Backgrounds
import _appTermPoliciesPrivacity from '../assets/backgrounds/app-term-policies-privacity.png';
import _backgroundFooterAbout from '../assets/backgrounds/background_fallback.png';
import _LoginImageBackground from '../assets/backgrounds/background_login.png';
import _backgroundSplashBubble from '../assets/backgrounds/background_splash.png';


// Placeholders
import _placeholderAvatarCompany from '../assets/backgrounds/banner_avatar_company.png';
import _placeholderAvatarUser from '../assets/backgrounds/banner_avatar_user.png';
import _placeholderAvatarWaiter from '../assets/backgrounds/banner_avatar_waiter.png';
import _placeholderEvent from '../assets/backgrounds/banner_event.png';

import _patternSquare from '../assets/backgrounds/pattern_squares.png';


// Drawables
import _pinBecoDasCores from '../assets/drawables/color_carnaval_beco_das_cores.png';
import _pinFantasyParades from '../assets/drawables/color_carnaval_fantasy_parade.png';
import _pinParadeGate from '../assets/drawables/color_carnaval_parade_gate.png';
import _pinTransitAgent from '../assets/drawables/color_carnaval_transit_agent.png';
import _pinBathroom from '../assets/drawables/color_marker_bathroom.png';
import _pinBus from '../assets/drawables/color_marker_bus.png';
import _pinChild from '../assets/drawables/color_marker_child.png';
import _pinSelectiveOther from '../assets/drawables/color_marker_collect_other.png';
import _pinSelectiveCollect from '../assets/drawables/color_marker_collect_selective.png';
import _pinFire from '../assets/drawables/color_marker_fire.png';
import _pinLifeguard from '../assets/drawables/color_marker_lifeguard.png';
import _pinMedical from '../assets/drawables/color_marker_medical.png';
import _pinPoliceCivil from '../assets/drawables/color_marker_police_civil.png';
import _pinPoliceMilitar from '../assets/drawables/color_marker_police_militar.png';
import _pinTaxi from '../assets/drawables/color_marker_taxi.png';
import _pinHealthSurveillance from '../assets/drawables/color_marker_vigilancia_sanitaria.png';
import _markerNCompany from '../assets/drawables/marker_n_company.png';
import _markerNEvent from '../assets/drawables/marker_n_event.png';
import _markerNStreet from '../assets/drawables/marker_n_street.png';
import _pinCleanHealth from '../assets/drawables/marker-carnival-clean-health.png';
import _pinPublicAgency from '../assets/drawables/marker-carnival-public-agency.png';
import _pinReservedArea from '../assets/drawables/marker-carnival-reserved-area.png';


// Flags
import _countryFlagAfghanistan from '../assets/flags/flag-afghanistan.png';
import _countryFlagAlandIslands from '../assets/flags/flag-aland-islands.png';
import _countryFlagAlbania from '../assets/flags/flag-albania.png';
import _countryFlagAlgeria from '../assets/flags/flag-algeria.png';
import _countryFlagAmericanSamoa from '../assets/flags/flag-american-samoa.png';
import _countryFlagAndorra from '../assets/flags/flag-andorra.png';
import _countryFlagAngola from '../assets/flags/flag-angola.png';
import _countryFlagAnguilla from '../assets/flags/flag-anguilla.png';
import _countryFlagAntarctica from '../assets/flags/flag-antarctica.png';
import _countryFlagAntiguaAndBarbuda from '../assets/flags/flag-antigua-and-barbuda.png';
import _countryFlagArgentina from '../assets/flags/flag-argentina.png';
import _countryFlagArmenia from '../assets/flags/flag-armenia.png';
import _countryFlagAruba from '../assets/flags/flag-aruba.png';
import _countryFlagflagAscensionIsland from '../assets/flags/flag-ascension-island.png';
import _countryFlagAustralia from '../assets/flags/flag-australia.png';
import _countryFlagAustria from '../assets/flags/flag-austria.png';
import _countryFlagAzerbaijan from '../assets/flags/flag-azerbaijan.png';
import _countryFlagBahamas from '../assets/flags/flag-bahamas.png';
import _countryFlagBahrain from '../assets/flags/flag-bahrain.png';
import _countryFlagBangladesh from '../assets/flags/flag-bangladesh.png';
import _countryFlagBarbados from '../assets/flags/flag-barbados.png';
import _countryFlagBelarus from '../assets/flags/flag-belarus.png';
import _countryFlagBelgium from '../assets/flags/flag-belgium.png';
import _countryFlagBelize from '../assets/flags/flag-belize.png';
import _countryFlagBenin from '../assets/flags/flag-benin.png';
import _countryFlagBermuda from '../assets/flags/flag-bermuda.png';
import _countryFlagBhutan from '../assets/flags/flag-bhutan.png';
import _countryFlagBiot from '../assets/flags/flag-biot.png';
import _countryFlagBolivia from '../assets/flags/flag-bolivia.png';
import _countryFlagBonaire from '../assets/flags/flag-bonaire.png';
import _countryFlagBosnian from '../assets/flags/flag-bosnian.png';
import _countryFlagBotswana from '../assets/flags/flag-botswana.png';
import _countryFlagBouvetIsland from '../assets/flags/flag-bouvet-island.png';
import _countryFlagBrazil from '../assets/flags/flag-brazil.png';
import _countryFlagBritishAntarcticTerritory from '../assets/flags/flag-british-antarctic-territory.png';
import _countryFlagBritishVirginIslands from '../assets/flags/flag-british-virgin-islands.png';
import _countryFlagBrunei from '../assets/flags/flag-brunei.png';
import _countryFlagBulgaria from '../assets/flags/flag-bulgaria.png';
import _countryFlagBurkinaFaso from '../assets/flags/flag-burkina-faso.png';
import _countryFlagBurma from '../assets/flags/flag-burma.png';
import _countryFlagBurundi from '../assets/flags/flag-burundi.png';
import _countryFlagCambodia from '../assets/flags/flag-cambodia.png';
import _countryFlagCameroon from '../assets/flags/flag-cameroon.png';
import _countryFlagCanada from '../assets/flags/flag-canada.png';
import _countryFlagCapeVerde from '../assets/flags/flag-cape-verde.png';
import _countryFlagCascadia from '../assets/flags/flag-cascadia.png';
import _countryFlagCaymanIslands from '../assets/flags/flag-cayman-islands.png';
import _countryFlagCentraAfricanRepublic from '../assets/flags/flag-centra-african-republic.png';
import _countryFlagChad from '../assets/flags/flag-chad.png';
import _countryFlagChile from '../assets/flags/flag-chile.png';
import _countryFlagChina from '../assets/flags/flag-china.png';
import _countryFlagChristmasIsland from '../assets/flags/flag-christmas-island.png';
import _countryFlagCocosIslands from '../assets/flags/flag-cocos-islands.png';
import _countryFlagColombia from '../assets/flags/flag-colombia.png';
import _countryFlagComoros from '../assets/flags/flag-comoros.png';
import _countryFlagCongoKinshasa from '../assets/flags/flag-congo-kinshasa.png';
import _countryFlagCongo from '../assets/flags/flag-congo.png';
import _countryFlagCookIslands from '../assets/flags/flag-cook-islands.png';
import _countryFlagCostaRica from '../assets/flags/flag-costa-rica.png';
import _countryFlagCroatian from '../assets/flags/flag-croatian.png';
import _countryFlagCuba from '../assets/flags/flag-cuba.png';
import _countryFlagCuracao from '../assets/flags/flag-curacao.png';
import _countryFlagCyprus from '../assets/flags/flag-cyprus.png';
import _countryFlagCzechRepublic from '../assets/flags/flag-czech-republic.png';
import _countryFlagDenmark from '../assets/flags/flag-denmark.png';
import _countryFlagDjibouti from '../assets/flags/flag-djibouti.png';
import _countryFlagDominica from '../assets/flags/flag-dominica.png';
import _countryFlagDominicanRepublic from '../assets/flags/flag-dominican-republic.png';
import _countryFlagEastTimor from '../assets/flags/flag-east-timor.png';
import _countryFlagEcuador from '../assets/flags/flag-ecuador.png';
import _countryFlagEgypt from '../assets/flags/flag-egypt.png';
import _countryFlagElSalvador from '../assets/flags/flag-el-salvador.png';
import _countryFlagEngland from '../assets/flags/flag-england.png';
import _countryFlagEquatorialGuinea from '../assets/flags/flag-equatorial-guinea.png';
import _countryFlagEritrea from '../assets/flags/flag-eritrea.png';
import _countryFlagEstonia from '../assets/flags/flag-estonia.png';
import _countryFlagEthiopia from '../assets/flags/flag-ethiopia.png';
import _countryFlagEuropeanUnion from '../assets/flags/flag-european-union.png';
import _countryFlagExYugoslavia from '../assets/flags/flag-ex-yugoslavia.png';
import _countryFlagFalklandIslands from '../assets/flags/flag-falkland-islands.png';
import _countryFlagFaroeIslands from '../assets/flags/flag-faroe-islands.png';
import _countryFlagFiji from '../assets/flags/flag-fiji.png';
import _countryFlagFinland from '../assets/flags/flag-finland.png';
import _countryFlagFrance from '../assets/flags/flag-france.png';
import _countryFlagFrenchGuiana from '../assets/flags/flag-french-guiana.png';
import _countryFlagFrenchPolynesia from '../assets/flags/flag-french-polynesia.png';
import _countryFlagFrenchSouthernTerritories from '../assets/flags/flag-french-southern-territories.png';
import _countryFlagGabon from '../assets/flags/flag-gabon.png';
import _countryFlagGambia from '../assets/flags/flag-gambia.png';
import _countryFlagGeorgia from '../assets/flags/flag-georgia.png';
import _countryFlagGermany from '../assets/flags/flag-germany.png';
import _countryFlagGhana from '../assets/flags/flag-ghana.png';
import _countryFlagGibraltar from '../assets/flags/flag-gibraltar.png';
import _countryFlagGreece from '../assets/flags/flag-greece.png';
import _countryFlagGreenland from '../assets/flags/flag-greenland.png';
import _countryFlagGrenada from '../assets/flags/flag-grenada.png';
import _countryFlagGuadeloupe from '../assets/flags/flag-guadeloupe.png';
import _countryFlagGuam from '../assets/flags/flag-guam.png';
import _countryFlagGuatemala from '../assets/flags/flag-guatemala.png';
import _countryFlagGuernsey from '../assets/flags/flag-guernsey.png';
import _countryFlagGuineaBissau from '../assets/flags/flag-guinea-bissau.png';
import _countryFlagGuinea from '../assets/flags/flag-guinea.png';
import _countryFlagGuyana from '../assets/flags/flag-guyana.png';
import _countryFlagHaiti from '../assets/flags/flag-haiti.png';
import _countryFlagFlagHeardIslandAndMcdonaldIslands from '../assets/flags/flag-heard-island-and-mcdonald-islands.png';
import _countryFlagHolySee from '../assets/flags/flag-holy-see.png';
import _countryFlagHonduras from '../assets/flags/flag-honduras.png';
import _countryFlagHongKong from '../assets/flags/flag-hong-kong.png';
import _countryFlagHungary from '../assets/flags/flag-hungary.png';
import _countryFlagIceland from '../assets/flags/flag-iceland.png';
import _countryFlagIndia from '../assets/flags/flag-india.png';
import _countryFlagIndonesia from '../assets/flags/flag-indonesia.png';
import _countryFlagIran from '../assets/flags/flag-iran.png';
import _countryFlagIraq from '../assets/flags/flag-iraq.png';
import _countryFlagIreland from '../assets/flags/flag-ireland.png';
import _countryFlagIsleOfMan from '../assets/flags/flag-isle-of-man.png';
import _countryFlagIsrael from '../assets/flags/flag-israel.png';
import _countryFlagItaly from '../assets/flags/flag-italy.png';
import _countryFlagIvoryCoast from '../assets/flags/flag-ivory-coast.png';
import _countryFlagJamaica from '../assets/flags/flag-jamaica.png';
import _countryFlagJanMayen from '../assets/flags/flag-jan-mayen.png';
import _countryFlagJapan from '../assets/flags/flag-japan.png';
import _countryFlagJarvisIsland from '../assets/flags/flag-jarvis-island.png';
import _countryFlagJersey from '../assets/flags/flag-jersey.png';
import _countryFlagJordan from '../assets/flags/flag-jordan.png';
import _countryFlagKazakhstan from '../assets/flags/flag-kazakhstan.png';
import _countryFlagKenya from '../assets/flags/flag-kenya.png';
import _countryFlagKiribati from '../assets/flags/flag-kiribati.png';
import _countryFlagKorea from '../assets/flags/flag-korea.png';
import _countryFlagKosovo from '../assets/flags/flag-kosovo.png';
import _countryFlagKuwait from '../assets/flags/flag-kuwait.png';
import _countryFlagKyrgyzstan from '../assets/flags/flag-kyrgyzstan.png';
import _countryFlagLaos from '../assets/flags/flag-laos.png';
import _countryFlagLatvia from '../assets/flags/flag-latvia.png';
import _countryFlagLebanon from '../assets/flags/flag-lebanon.png';
import _countryFlagLesotho from '../assets/flags/flag-lesotho.png';
import _countryFlagLiberia from '../assets/flags/flag-liberia.png';
import _countryFlagLibya from '../assets/flags/flag-libya.png';
import _countryFlagLiechtenstein from '../assets/flags/flag-liechtenstein.png';
import _countryFlagLithuania from '../assets/flags/flag-lithuania.png';
import _countryFlagLuxembourg from '../assets/flags/flag-luxembourg.png';
import _countryFlagMacau from '../assets/flags/flag-macau.png';
import _countryFlagMacedonia from '../assets/flags/flag-macedonia.png';
import _countryFlagMadagascar from '../assets/flags/flag-madagascar.png';
import _countryFlagMalawi from '../assets/flags/flag-malawi.png';
import _countryFlagMalaysia from '../assets/flags/flag-malaysia.png';
import _countryFlagMaldives from '../assets/flags/flag-maldives.png';
import _countryFlagMali from '../assets/flags/flag-mali.png';
import _countryFlagMalta from '../assets/flags/flag-malta.png';
import _countryFlagMarshallIslands from '../assets/flags/flag-marshall-islands.png';
import _countryFlagMartinique from '../assets/flags/flag-martinique.png';
import _countryFlagMauritania from '../assets/flags/flag-mauritania.png';
import _countryFlagMauritius from '../assets/flags/flag-mauritius.png';
import _countryFlagMayotte from '../assets/flags/flag-mayotte.png';
import _countryFlagMexico from '../assets/flags/flag-mexico.png';
import _countryFlagMicronesia from '../assets/flags/flag-micronesia.png';
import _countryFlagMoldova from '../assets/flags/flag-moldova.png';
import _countryFlagMonaco from '../assets/flags/flag-monaco.png';
import _countryFlagMongolia from '../assets/flags/flag-mongolia.png';
import _countryFlagMontenegro from '../assets/flags/flag-montenegro.png';
import _countryFlagMontserrat from '../assets/flags/flag-montserrat.png';
import _countryFlagMorocco from '../assets/flags/flag-morocco.png';
import _countryFlagMozambique from '../assets/flags/flag-mozambique.png';
import _countryFlagMyanmar from '../assets/flags/flag-myanmar.png';
import _countryFlagNamibia from '../assets/flags/flag-namibia.png';
import _countryFlagNauru from '../assets/flags/flag-nauru.png';
import _countryFlagNepal from '../assets/flags/flag-nepal.png';
import _countryFlagNetherlandsAntilles from '../assets/flags/flag-netherlands-antilles.png';
import _countryFlagNetherlands from '../assets/flags/flag-netherlands.png';
import _countryFlagNewCaledonia from '../assets/flags/flag-new-caledonia.png';
import _countryFlagNewZealand from '../assets/flags/flag-new-zealand.png';
import _countryFlagNicaragua from '../assets/flags/flag-nicaragua.png';
import _countryFlagNiger from '../assets/flags/flag-niger.png';
import _countryFlagNigeria from '../assets/flags/flag-nigeria.png';
import _countryFlagNiue from '../assets/flags/flag-niue.png';
import _countryFlagNorfolkIsland from '../assets/flags/flag-norfolk-island.png';
import _countryFlagNorthKorea from '../assets/flags/flag-north-korea.png';
import _countryFlagNorthernIreland from '../assets/flags/flag-northern-ireland.png';
import _countryFlagNorthernMarianaIslands from '../assets/flags/flag-northern-mariana-islands.png';
import _countryFlagNorway from '../assets/flags/flag-norway.png';
import _countryFlagOman from '../assets/flags/flag-oman.png';
import _countryFlagPakistan from '../assets/flags/flag-pakistan.png';
import _countryFlagPalau from '../assets/flags/flag-palau.png';
import _countryFlagPalestinianTerritory from '../assets/flags/flag-palestinian-territory.png';
import _countryFlagPanama from '../assets/flags/flag-panama.png';
import _countryFlagPapuaNewGuinea from '../assets/flags/flag-papua-new-guinea.png';
import _countryFlagParaguay from '../assets/flags/flag-paraguay.png';
import _countryFlagPeru from '../assets/flags/flag-peru.png';
import _countryFlagPhilippines from '../assets/flags/flag-philippines.png';
import _countryFlagPitcairn from '../assets/flags/flag-pitcairn.png';
import _countryFlagPoland from '../assets/flags/flag-poland.png';
import _countryFlagPortugal from '../assets/flags/flag-portugal.png';
import _countryFlagPuertoRico from '../assets/flags/flag-puerto-rico.png';
import _countryFlagQatar from '../assets/flags/flag-qatar.png';
import _countryFlagRepublicOfChina from '../assets/flags/flag-republic-of-china.png';
import _countryFlagReunion from '../assets/flags/flag-reunion.png';
import _countryFlagRomania from '../assets/flags/flag-romania.png';
import _countryFlagRussia from '../assets/flags/flag-russia.png';
import _countryFlagRwanda from '../assets/flags/flag-rwanda.png';
import _countryFlagSaintBarthelemy from '../assets/flags/flag-saint-barthelemy.png';
import _countryFlagSaintHelenaAndDependencies from '../assets/flags/flag-saint-helena-and-dependencies.png';
import _countryFlagSaintHelena from '../assets/flags/flag-saint-helena.png';
import _countryFlagSaintKittsAndNevis from '../assets/flags/flag-saint-kitts-and-nevis.png';
import _countryFlagSaintLucia from '../assets/flags/flag-saint-lucia.png';
import _countryFlagSaintMartin from '../assets/flags/flag-saint-martin.png';
import _countryFlagSaintPierreAndMiquelon from '../assets/flags/flag-saint-pierre-and-miquelon.png';
import _countryFlagSaintVincentAndTheGrenadines from '../assets/flags/flag-saint-vincent-and-the-grenadines.png';
import _countryFlagSamoa from '../assets/flags/flag-samoa.png';
import _countryFlagSanMarino from '../assets/flags/flag-san-marino.png';
import _countryFlagSaoTomeAndPrincipe from '../assets/flags/flag-sao-tome-and-principe.png';
import _countryFlagSaudiArabia from '../assets/flags/flag-saudi-arabia.png';
import _countryFlagScotland from '../assets/flags/flag-scotland.png';
import _countryFlagSenegal from '../assets/flags/flag-senegal.png';
import _countryFlagSerbia from '../assets/flags/flag-serbia.png';
import _countryFlagSeychelles from '../assets/flags/flag-seychelles.png';
import _countryFlagSierraLeone from '../assets/flags/flag-sierra-leone.png';
import _countryFlagSingapore from '../assets/flags/flag-singapore.png';
import _countryFlagSintEustatius from '../assets/flags/flag-sint-eustatius.png';
import _countryFlagSintMaarten from '../assets/flags/flag-sint-maarten.png';
import _countryFlagSlovakia from '../assets/flags/flag-slovakia.png';
import _countryFlagSlovenia from '../assets/flags/flag-slovenia.png';
import _countryFlagSMOM from '../assets/flags/flag-SMOM.png';
import _countryFlagSolomonIslands from '../assets/flags/flag-solomon-islands.png';
import _countryFlagSomalia from '../assets/flags/flag-somalia.png';
import _countryFlagSouthAfrica from '../assets/flags/flag-south-africa.png';
import _countryFlagSouthGeorgia from '../assets/flags/flag-south-georgia.png';
import _countryFlagSouthSudan from '../assets/flags/flag-south-sudan.png';
import _countryFlagSpain from '../assets/flags/flag-spain.png';
import _countryFlagSpm from '../assets/flags/flag-spm.png';
import _countryFlagSriLanka from '../assets/flags/flag-sri-lanka.png';
import _countryFlagSudan from '../assets/flags/flag-sudan.png';
import _countryFlagSuriname from '../assets/flags/flag-suriname.png';
import _countryFlagSvalbard from '../assets/flags/flag-svalbard.png';
import _countryFlagSwaziland from '../assets/flags/flag-swaziland.png';
import _countryFlagSweden from '../assets/flags/flag-sweden.png';
import _countryFlagSwitzerland from '../assets/flags/flag-switzerland.png';
import _countryFlagSyria from '../assets/flags/flag-syria.png';
import _countryFlagTaiwan from '../assets/flags/flag-taiwan.png';
import _countryFlagTajikistan from '../assets/flags/flag-tajikistan.png';
import _countryFlagTanzania from '../assets/flags/flag-tanzania.png';
import _countryFlagThailand from '../assets/flags/flag-thailand.png';
import _countryFlagTimorLeste from '../assets/flags/flag-timor-leste.png';
import _countryFlagTogo from '../assets/flags/flag-togo.png';
import _countryFlagTokelau from '../assets/flags/flag-tokelau.png';
import _countryFlagTonga from '../assets/flags/flag-tonga.png';
import _countryFlagTrinidadAndTobago from '../assets/flags/flag-trinidad-and-tobago.png';
import _countryFlagTunisia from '../assets/flags/flag-tunisia.png';
import _countryFlagTurkey from '../assets/flags/flag-turkey.png';
import _countryFlagTurkmenistan from '../assets/flags/flag-turkmenistan.png';
import _countryFlagTurksAndCaicosIslands from '../assets/flags/flag-turks-and-caicos-islands.png';
import _countryFlagTuvalu from '../assets/flags/flag-tuvalu.png';
import _countryFlagUganda from '../assets/flags/flag-uganda.png';
import _countryFlagUkraine from '../assets/flags/flag-ukraine.png';
import _countryFlagUnitedArabEmirates from '../assets/flags/flag-united-arab-emirates.png';
import _countryFlagUnitedKingdom from '../assets/flags/flag-united-kingdom.png';
import _countryFlagUnitedStates from '../assets/flags/flag-united-states.png';
import _countryFlagUruguay from '../assets/flags/flag-uruguay.png';
import _countryFlagUzbekistan from '../assets/flags/flag-uzbekistan.png';
import _countryFlagVanuatu from '../assets/flags/flag-vanuatu.png';
import _countryFlagVaticanCity from '../assets/flags/flag-vatican-city.png';
import _countryFlagVenezuela from '../assets/flags/flag-venezuela.png';
import _countryFlagVietnam from '../assets/flags/flag-vietnam.png';
import _countryFlagVirginIslands from '../assets/flags/flag-virgin-islands.png';
import _countryFlagWales from '../assets/flags/flag-wales.png';
import _countryFlagWallisAndFutuna from '../assets/flags/flag-wallis-and-futuna.png';
import _countryFlagWesternSahara from '../assets/flags/flag-western-sahara.png';
import _countryFlagYemen from '../assets/flags/flag-yemen.png';
import _countryFlagZambia from '../assets/flags/flag-zambia.png';
import _countryFlagZimbabwe from '../assets/flags/flag-zimbabwe.png';
import _world from '../assets/flags/world.png';


// Images
import _flagPrideColor from '../assets/images/flag-lgbt-color.png';
import _flagPrideGray from '../assets/images/flag-lgbt-gray.png';

import _carnivalImageButton from '../assets/images/grid-button-carnival.png';
import _festivalImageButton from '../assets/images/grid-button-festival.png';
import _noitadaHomeImageButton from '../assets/images/grid-button-home.png';

import _infos from '../assets/images/infos.png';

import _colorLevelBasic from '../assets/images/level-basic.png';
import _colorLevelKing from '../assets/images/level-king.png';
import _colorLevelNone from '../assets/images/level-none.png';
import _colorLevelPersas from '../assets/images/level-persas.png';
import _colorLevelSuper from '../assets/images/level-prime.png';
import _colorLevelStar from '../assets/images/level-star.png';


// Markets
import _listEventDone from '../assets/images/list-event-done.png';
import _listEventWait from '../assets/images/list-event-wait.png';
import _locationGlobal from '../assets/images/location-global.png';

import _marketAppStore from '../assets/images/market_app_store.png';
import _marketGooglePlay from '../assets/images/market_google_play.png';
import _marketWindowsStore from '../assets/images/market_windows_store.png';


import _menuMarkerCarnival from '../assets/images/menu_marker_carnival.png';

import _noBar from '../assets/images/no_bar.png';
import _noCarnivalAttractions from '../assets/images/no_carnival_attraction.png';
import _noCarnivalCabin from '../assets/images/no_carnival_cabin.png';
import _noCarnivalDistrict from '../assets/images/no_carnival_district.png';
import _noCarnivalGuides from '../assets/images/no_carnival_guide.png';
import _noCarnivalPhones from '../assets/images/no_carnival_phone.png';
import _noCarnivalStreet from '../assets/images/no_carnival_street.png';
import _noClub from '../assets/images/no_club.png';
import _noFestival from '../assets/images/no_festival.png';
import _noHighlight from '../assets/images/no_highlight.png';
import _noOrderItems from '../assets/images/no_order_items.png';
import _noParty from '../assets/images/no_party.png';
import _noPayCards from '../assets/images/no_pay_cards.png';
import _noReserveUpcoming from '../assets/images/no_reserves_upcoming.png';
import _noFavoritesCompanies from '../assets/images/no-favorites-companies.png';
import _noFavoritesEvents from '../assets/images/no-favorites-events.png';
import _noHelp from '../assets/images/no-help.png';
import _noNoitag from '../assets/images/noitag-all-no.png';
import _noitagCardOff from '../assets/images/noitag-card-off.png';
import _noitagCardOn from '../assets/images/noitag-card-on.png';
import _noitagWatchOff from '../assets/images/noitag-watch-off.png';
import _noitagWatchOn from '../assets/images/noitag-watch-on.png';
import _noNotifications from '../assets/images/notifications.png';

import _order_done from '../assets/images/order_done.png';
import _ordersheet_done from '../assets/images/ordersheet_done.png';
import _ordersheet_finishing from '../assets/images/ordersheet_finishing.png';
import _ordersheet_pay_app from '../assets/images/ordersheet_pay_app.png';
import _ordersheet_pay_place from '../assets/images/ordersheet_pay_place.png';
import _ordersheet_wait from '../assets/images/ordersheet_wait.png';
import _ordersheetClosed from '../assets/images/ordersheet-closed.png';
import _ordersheetOpened from '../assets/images/ordersheet-opened.png';
import _ordersheetService from '../assets/images/ordersheet-service.png';
import _permissions from '../assets/images/permissions.png';
import _searchEmpty from '../assets/images/search-empty.png';

import _separatorHorizontal from '../assets/images/separator_horizontal.png';
import _separatorVertical from '../assets/images/separator_vertical.png';
import _shadowFloor from '../assets/images/shadow_floor.png';


// Stickers
import _smile from '../assets/images/smile.png';
import _stickerCake from '../assets/images/sticker_cake.png';

import _ticketsEmpty from '../assets/images/tickets-empty.png';
import _ticketsWait from '../assets/images/tickets-wait.png';


// Tutorial e Configs
import _tutorial1 from '../assets/images/tutorial-1.png';
import _tutorial2 from '../assets/images/tutorial-2.png';
import _tutorial3 from '../assets/images/tutorial-3.png';

import _wifiInfos from '../assets/images/wifi-infos.png';
import _wifi from '../assets/images/wifi.png';


// Logos
import _logoMobileAndroidApp from '../assets/logos/icon_mobile_android_app.png';
import _logoIconAppBlack from '../assets/logos/logo_icon_app_black.png';
import _logoIconAppWhite from '../assets/logos/logo_icon_app_white.png';
import _logoUberIcon from '../assets/logos/logo_uber_icon.png';
import _logoWazeIcon from '../assets/logos/logo_waze_icon.png';
import _logoAppNameWhite from '../assets/logos/logo-name-app-white.png';
import _logoNoitadaCarnivalIcon from '../assets/logos/logo-noitada-carnival-icon.png';


// Payments
import _creditCardFrontDefault from '../assets/payments/flag_credit_card_acnull_front.png';
import _creditCardBackDefault from '../assets/payments/flag_credit_card_acnull_upback.png';
import _creditCardFrontAmerican from '../assets/payments/flag_credit_card_american_front.png';
import _creditCardBackAmerican from '../assets/payments/flag_credit_card_american_upback.png';
import _creditCardFrontDiners from '../assets/payments/flag_credit_card_diners_front.png';
import _creditCardBackDiners from '../assets/payments/flag_credit_card_diners_upback.png';
import _creditCardFrontElo from '../assets/payments/flag_credit_card_elo_front.png';
import _creditCardBackElo from '../assets/payments/flag_credit_card_elo_upback.png';
import _creditCardFrontHipercard from '../assets/payments/flag_credit_card_hipercard_front.png';
import _creditCardBackHipercard from '../assets/payments/flag_credit_card_hipercard_upback.png';
import _creditCardFrontMastercard from '../assets/payments/flag_credit_card_mastercard_front.png';
import _creditCardBackMastercard from '../assets/payments/flag_credit_card_mastercard_upback.png';
import _creditCardFrontNubank from '../assets/payments/flag_credit_card_nubank_front.png';
import _creditCardBackNubank from '../assets/payments/flag_credit_card_nubank_upback.png';
import _creditCardFrontVisa from '../assets/payments/flag_credit_card_visa_front.png';
import _creditCardBackVisa from '../assets/payments/flag_credit_card_visa_upback.png';

import _flagBankNubank from '../assets/payments/flag-bank-nubank.png';
import _flagBankPagarme from '../assets/payments/flag-bank-pagarme.png';
import _flagBankStone from '../assets/payments/flag-bank-stone.png';

import _flagDefault from '../assets/payments/flag-credit-acnull-front.png';
import _flagCardAmerican from '../assets/payments/flag-credit-american.png';
import _flagCardAura from '../assets/payments/flag-credit-aura.png';
import _flagCardCabal from '../assets/payments/flag-credit-cabal.png';
import _flagCardDiners from '../assets/payments/flag-credit-diners.png';
import _flagCardDiscover from '../assets/payments/flag-credit-discover.png';
import _flagCardElo from '../assets/payments/flag-credit-elo.png';
import _flagCardHipercard from '../assets/payments/flag-credit-hipercard.png';
import _flagCardJcb from '../assets/payments/flag-credit-jcb.png';
import _flagCardMastercard from '../assets/payments/flag-credit-mastercard.png';
import _flagCardUnionpay from '../assets/payments/flag-credit-unionpay.png';
import _flagCardVisa from '../assets/payments/flag-credit-visa.png';
import _flagTicketAlelo from '../assets/payments/flag-ticket-alelo.png';
import _flagTicketBen from '../assets/payments/flag-ticket-ben.png';
import _flagTicketSodexo from '../assets/payments/flag-ticket-sodexo.png';
import _flagTicketTicket from '../assets/payments/flag-ticket-ticket.png';
import _flagTicketVr from '../assets/payments/flag-ticket-vr.png';
import _flagTypeCash from '../assets/payments/flag-type-cash.png';
import _flagTypeCheque from '../assets/payments/flag-type-cheque.png';
import _flagTypeCredit from '../assets/payments/flag-type-credit.png';
import _flagTypeDebit from '../assets/payments/flag-type-debit.png';
import _flagTypePix from '../assets/payments/flag-type-pix.png';
import _flagTypeTicket from '../assets/payments/flag-type-ticket.png';
import _flagTypeWallet from '../assets/payments/flag-type-wallet.png';

import _logoBankNubank from '../assets/payments/logo-bank-nubank.png';
import _logoBankPagarme from '../assets/payments/logo-bank-pagarme.png';
import _logoBankStone from '../assets/payments/logo-bank-stone.png';

import _logoCreditAmerican from '../assets/payments/logo-credit-american.png';
import _logoCreditAura from '../assets/payments/logo-credit-aura.png';
import _logoCreditCabal from '../assets/payments/logo-credit-cabal.png';
import _logoCreditDiners from '../assets/payments/logo-credit-diners.png';
import _logoCreditDiscover from '../assets/payments/logo-credit-discover.png';
import _logoCreditElo from '../assets/payments/logo-credit-elo.png';
import _logoCreditHipercard from '../assets/payments/logo-credit-hipercard.png';
import _logoCreditJcb from '../assets/payments/logo-credit-jcb.png';
import _logoCreditMastercard from '../assets/payments/logo-credit-mastercard.png';
import _logoCreditUnionpay from '../assets/payments/logo-credit-unionpay.png';
import _logoCreditVisa from '../assets/payments/logo-credit-visa.png';

import _logoTypeCash from '../assets/payments/logo-type-cash.png';
import _logoTypeCheque from '../assets/payments/logo-type-cheque.png';
import _logoTypeCredit from '../assets/payments/logo-type-credit.png';
import _logoTypeDebit from '../assets/payments/logo-type-debit.png';
import _logoTypePix from '../assets/payments/logo-type-pix.png';
import _logoTypeTicket from '../assets/payments/logo-type-ticket.png';
import _logoTypeWallet from '../assets/payments/logo-type-wallet.png';

import _modeAddition from '../assets/payments/mode-type-addition.png';
import _modeDiscount from '../assets/payments/mode-type-discount.png';



export const BACKGROUNDS = {
  loginImageBackground:             _LoginImageBackground,
  
  backgroundFooterAbout:            _backgroundFooterAbout,
  backgroundSplashBubble:           _backgroundSplashBubble,
  
  patternSquare:                    _patternSquare,

  appTermPoliciesPrivacity:         _appTermPoliciesPrivacity,
};



export const CARNIVAL = {
  pinTransitAgent:                  _pinTransitAgent,
  pinBecoDasCores:                  _pinBecoDasCores,

  pinBathroom:                      _pinBathroom,
  pinBus:                           _pinBus,
  pinChild:                         _pinChild,
  pinFire:                          _pinFire,
  pinLifeguard:                     _pinLifeguard,
  pinMedical:                       _pinMedical,
  pinTaxi:                          _pinTaxi,
  pinHealthSurveillance:            _pinHealthSurveillance,


  // Ordem
  pinPoliceCivil:                   _pinPoliceCivil,
  pinPoliceMilitar:                 _pinPoliceMilitar,
  pinParadeGate:                    _pinParadeGate,
  pinFantasyParades:                _pinFantasyParades,
  pinSelectiveCollect:              _pinSelectiveCollect,
  pinSelectiveOther:                _pinSelectiveOther,

  menuMarkerCarnival:               _menuMarkerCarnival,
  pinCleanHealth:                   _pinCleanHealth,
  pinPublicAgency:                  _pinPublicAgency,
  pinReservedArea:                  _pinReservedArea,
};



export const CONFIGS = {
  tutorial1:                        _tutorial1,
  tutorial2:                        _tutorial2,
  tutorial3:                        _tutorial3,
  permissions:                      _permissions,
};



export const FLAGS_COUNTRY = {
  world:                            _world,

  countryFlagAfghanistan:           _countryFlagAfghanistan,
  countryFlagAlandIslands:          _countryFlagAlandIslands,
  countryFlagAlbania:               _countryFlagAlbania,
  countryFlagAlgeria:               _countryFlagAlgeria,
  countryFlagAmericanSamoa:         _countryFlagAmericanSamoa,
  countryFlagAndorra:               _countryFlagAndorra,
  countryFlagAngola:                _countryFlagAngola,
  countryFlagAnguilla:              _countryFlagAnguilla,
  countryFlagAntarctica:            _countryFlagAntarctica,
  countryFlagAntiguaAndBarbuda:     _countryFlagAntiguaAndBarbuda,
  countryFlagArgentina:             _countryFlagArgentina,
  countryFlagArmenia:               _countryFlagArmenia,
  countryFlagAruba:                 _countryFlagAruba,
  countryFlagflagAscensionIsland:   _countryFlagflagAscensionIsland,
  countryFlagAustralia:             _countryFlagAustralia,
  countryFlagAustria:               _countryFlagAustria,
  countryFlagAzerbaijan:            _countryFlagAzerbaijan,
  countryFlagBahamas:               _countryFlagBahamas,
  countryFlagBahrain:               _countryFlagBahrain,
  countryFlagBangladesh:            _countryFlagBangladesh,
  countryFlagBarbados:              _countryFlagBarbados,
  countryFlagBelarus:               _countryFlagBelarus,
  countryFlagBelgium:               _countryFlagBelgium,
  countryFlagBelize:                _countryFlagBelize,
  countryFlagBenin:                 _countryFlagBenin,
  countryFlagBermuda:               _countryFlagBermuda,
  countryFlagBhutan:                _countryFlagBhutan,
  countryFlagBiot:                  _countryFlagBiot,
  countryFlagBolivia:               _countryFlagBolivia,
  countryFlagBonaire:               _countryFlagBonaire,
  countryFlagBosnian:               _countryFlagBosnian,
  countryFlagBotswana:              _countryFlagBotswana,
  countryFlagBouvetIsland:          _countryFlagBouvetIsland,
  countryFlagBrazil:                _countryFlagBrazil,
  countryFlagBritishAntarcticTerritory:           _countryFlagBritishAntarcticTerritory,
  countryFlagBritishVirginIslands:          _countryFlagBritishVirginIslands,
  countryFlagBrunei:                _countryFlagBrunei,
  countryFlagBulgaria:              _countryFlagBulgaria,
  countryFlagBurkinaFaso:           _countryFlagBurkinaFaso,
  countryFlagBurma:                 _countryFlagBurma,
  countryFlagBurundi:               _countryFlagBurundi,
  countryFlagCambodia:              _countryFlagCambodia,
  countryFlagCameroon:              _countryFlagCameroon,
  countryFlagCanada:                _countryFlagCanada,
  countryFlagCapeVerde:             _countryFlagCapeVerde,
  countryFlagCascadia:              _countryFlagCascadia,
  countryFlagCaymanIslands:         _countryFlagCaymanIslands,
  countryFlagCentraAfricanRepublic: _countryFlagCentraAfricanRepublic,
  countryFlagChad:                  _countryFlagChad,
  countryFlagChile:                 _countryFlagChile,
  countryFlagChina:                 _countryFlagChina,
  countryFlagChristmasIsland:       _countryFlagChristmasIsland,
  countryFlagCocosIslands:          _countryFlagCocosIslands,
  countryFlagColombia:              _countryFlagColombia,
  countryFlagComoros:               _countryFlagComoros,
  countryFlagCongoKinshasa:         _countryFlagCongoKinshasa,
  countryFlagCongo:                 _countryFlagCongo,
  countryFlagCookIslands:           _countryFlagCookIslands,
  countryFlagCostaRica:             _countryFlagCostaRica,
  countryFlagCroatian:              _countryFlagCroatian,
  countryFlagCuba:                  _countryFlagCuba,
  countryFlagCuracao:               _countryFlagCuracao,
  countryFlagCyprus:                _countryFlagCyprus,
  countryFlagCzechRepublic:         _countryFlagCzechRepublic,
  countryFlagDenmark:               _countryFlagDenmark,
  countryFlagDjibouti:              _countryFlagDjibouti,
  countryFlagDominica:              _countryFlagDominica,
  countryFlagDominicanRepublic:     _countryFlagDominicanRepublic,
  countryFlagEastTimor:             _countryFlagEastTimor,
  countryFlagEcuador:               _countryFlagEcuador,
  countryFlagEgypt:                 _countryFlagEgypt,
  countryFlagElSalvador:            _countryFlagElSalvador,
  countryFlagEngland:               _countryFlagEngland,
  countryFlagEquatorialGuinea:      _countryFlagEquatorialGuinea,
  countryFlagEritrea:               _countryFlagEritrea,
  countryFlagEstonia:               _countryFlagEstonia,
  countryFlagEthiopia:              _countryFlagEthiopia,
  countryFlagEuropeanUnion:         _countryFlagEuropeanUnion,
  countryFlagExYugoslavia:          _countryFlagExYugoslavia,
  countryFlagFalklandIslands:       _countryFlagFalklandIslands,
  countryFlagFaroeIslands:          _countryFlagFaroeIslands,
  countryFlagFiji:                  _countryFlagFiji,
  countryFlagFinland:               _countryFlagFinland,
  countryFlagFrance:                _countryFlagFrance,
  countryFlagFrenchGuiana:          _countryFlagFrenchGuiana,
  countryFlagFrenchPolynesia:       _countryFlagFrenchPolynesia,
  countryFlagFrenchSouthernTerritories:           _countryFlagFrenchSouthernTerritories,
  countryFlagGabon:                 _countryFlagGabon,
  countryFlagGambia:                _countryFlagGambia,
  countryFlagGeorgia:               _countryFlagGeorgia,
  countryFlagGermany:               _countryFlagGermany,
  countryFlagGhana:                 _countryFlagGhana,
  countryFlagGibraltar:             _countryFlagGibraltar,
  countryFlagGreece:                _countryFlagGreece,
  countryFlagGreenland:             _countryFlagGreenland,
  countryFlagGrenada:               _countryFlagGrenada,
  countryFlagGuadeloupe:            _countryFlagGuadeloupe,
  countryFlagGuam:                  _countryFlagGuam,
  countryFlagGuatemala:             _countryFlagGuatemala,
  countryFlagGuernsey:              _countryFlagGuernsey,
  countryFlagGuineaBissau:          _countryFlagGuineaBissau,
  countryFlagGuinea:          _countryFlagGuinea,
  countryFlagGuyana:          _countryFlagGuyana,
  countryFlagHaiti:           _countryFlagHaiti,
  countryFlagFlagHeardIslandAndMcdonaldIslands:           _countryFlagFlagHeardIslandAndMcdonaldIslands,
  countryFlagHolySee:           _countryFlagHolySee,
  countryFlagHonduras:          _countryFlagHonduras,
  countryFlagHongKong:          _countryFlagHongKong,
  countryFlagHungary:           _countryFlagHungary,
  countryFlagIceland:           _countryFlagIceland,
  countryFlagIndia:           _countryFlagIndia,
  countryFlagIndonesia:           _countryFlagIndonesia,
  countryFlagIran:          _countryFlagIran,
  countryFlagIraq:          _countryFlagIraq,
  countryFlagIreland:           _countryFlagIreland,
  countryFlagIsleOfMan:           _countryFlagIsleOfMan,
  countryFlagIsrael:          _countryFlagIsrael,
  countryFlagItaly:           _countryFlagItaly,
  countryFlagIvoryCoast:          _countryFlagIvoryCoast,
  countryFlagJamaica:           _countryFlagJamaica,
  countryFlagJanMayen:          _countryFlagJanMayen,
  countryFlagJapan:           _countryFlagJapan,
  countryFlagJarvisIsland:          _countryFlagJarvisIsland,
  countryFlagJersey:          _countryFlagJersey,
  countryFlagJordan:          _countryFlagJordan,
  countryFlagKazakhstan:          _countryFlagKazakhstan,
  countryFlagKenya:           _countryFlagKenya,
  countryFlagKiribati:          _countryFlagKiribati,
  countryFlagKorea:           _countryFlagKorea,
  countryFlagKosovo:          _countryFlagKosovo,
  countryFlagKuwait:          _countryFlagKuwait,
  countryFlagKyrgyzstan:          _countryFlagKyrgyzstan,
  countryFlagLaos:          _countryFlagLaos,
  countryFlagLatvia:          _countryFlagLatvia,
  countryFlagLebanon:           _countryFlagLebanon,
  countryFlagLesotho:           _countryFlagLesotho,
  countryFlagLiberia:           _countryFlagLiberia,
  countryFlagLibya:           _countryFlagLibya,
  countryFlagLiechtenstein:           _countryFlagLiechtenstein,
  countryFlagLithuania:           _countryFlagLithuania,
  countryFlagLuxembourg:          _countryFlagLuxembourg,
  countryFlagMacau:           _countryFlagMacau,
  countryFlagMacedonia:           _countryFlagMacedonia,
  countryFlagMadagascar:          _countryFlagMadagascar,
  countryFlagMalawi:          _countryFlagMalawi,
  countryFlagMalaysia:          _countryFlagMalaysia,
  countryFlagMaldives:          _countryFlagMaldives,
  countryFlagMali:          _countryFlagMali,
  countryFlagMalta:           _countryFlagMalta,
  countryFlagMarshallIslands:           _countryFlagMarshallIslands,
  countryFlagMartinique:          _countryFlagMartinique,
  countryFlagMauritania:          _countryFlagMauritania,
  countryFlagMauritius:           _countryFlagMauritius,
  countryFlagMayotte:           _countryFlagMayotte,
  countryFlagMexico:          _countryFlagMexico,
  countryFlagMicronesia:          _countryFlagMicronesia,
  countryFlagMoldova:           _countryFlagMoldova,
  countryFlagMonaco:          _countryFlagMonaco,
  countryFlagMongolia:          _countryFlagMongolia,
  countryFlagMontenegro:          _countryFlagMontenegro,
  countryFlagMontserrat:          _countryFlagMontserrat,
  countryFlagMorocco:           _countryFlagMorocco,
  countryFlagMozambique:          _countryFlagMozambique,
  countryFlagMyanmar:           _countryFlagMyanmar,
  countryFlagNamibia:           _countryFlagNamibia,
  countryFlagNauru:           _countryFlagNauru,
  countryFlagNepal:           _countryFlagNepal,
  countryFlagNetherlandsAntilles:           _countryFlagNetherlandsAntilles,
  countryFlagNetherlands:           _countryFlagNetherlands,
  countryFlagNewCaledonia:          _countryFlagNewCaledonia,
  countryFlagNewZealand:          _countryFlagNewZealand,
  countryFlagNicaragua:           _countryFlagNicaragua,
  countryFlagNiger:           _countryFlagNiger,
  countryFlagNigeria:           _countryFlagNigeria,
  countryFlagNiue:          _countryFlagNiue,
  countryFlagNorfolkIsland:           _countryFlagNorfolkIsland,
  countryFlagNorthKorea:          _countryFlagNorthKorea,
  countryFlagNorthernIreland:           _countryFlagNorthernIreland,
  countryFlagNorthernMarianaIslands:          _countryFlagNorthernMarianaIslands,
  countryFlagNorway:          _countryFlagNorway,
  countryFlagOman:          _countryFlagOman,
  countryFlagPakistan:          _countryFlagPakistan,
  countryFlagPalau:           _countryFlagPalau,
  countryFlagPalestinianTerritory:          _countryFlagPalestinianTerritory,
  countryFlagPanama:          _countryFlagPanama,
  countryFlagPapuaNewGuinea:          _countryFlagPapuaNewGuinea,
  countryFlagParaguay:          _countryFlagParaguay,
  countryFlagPeru:          _countryFlagPeru,
  countryFlagPhilippines:           _countryFlagPhilippines,
  countryFlagPitcairn:          _countryFlagPitcairn,
  countryFlagPoland:          _countryFlagPoland,
  countryFlagPortugal:          _countryFlagPortugal,
  countryFlagPuertoRico:          _countryFlagPuertoRico,
  countryFlagQatar:           _countryFlagQatar,
  countryFlagRepublicOfChina:           _countryFlagRepublicOfChina,
  countryFlagReunion:           _countryFlagReunion,
  countryFlagRomania:           _countryFlagRomania,
  countryFlagRussia:          _countryFlagRussia,
  countryFlagRwanda:          _countryFlagRwanda,
  countryFlagSaintBarthelemy:           _countryFlagSaintBarthelemy,
  countryFlagSaintHelenaAndDependencies:          _countryFlagSaintHelenaAndDependencies,
  countryFlagSaintHelena:           _countryFlagSaintHelena,
  countryFlagSaintKittsAndNevis:          _countryFlagSaintKittsAndNevis,
  countryFlagSaintLucia:          _countryFlagSaintLucia,
  countryFlagSaintMartin:           _countryFlagSaintMartin,
  countryFlagSaintPierreAndMiquelon:          _countryFlagSaintPierreAndMiquelon,
  countryFlagSaintVincentAndTheGrenadines:          _countryFlagSaintVincentAndTheGrenadines,
  countryFlagSamoa:           _countryFlagSamoa,
  countryFlagSanMarino:           _countryFlagSanMarino,
  countryFlagSaoTomeAndPrincipe:          _countryFlagSaoTomeAndPrincipe,
  countryFlagSaudiArabia:           _countryFlagSaudiArabia,
  countryFlagScotland:          _countryFlagScotland,
  countryFlagSenegal:           _countryFlagSenegal,
  countryFlagSerbia:          _countryFlagSerbia,
  countryFlagSeychelles:          _countryFlagSeychelles,
  countryFlagSierraLeone:           _countryFlagSierraLeone,
  countryFlagSingapore:           _countryFlagSingapore,
  countryFlagSintEustatius:           _countryFlagSintEustatius,
  countryFlagSintMaarten:           _countryFlagSintMaarten,
  countryFlagSlovakia:          _countryFlagSlovakia,
  countryFlagSlovenia:          _countryFlagSlovenia,
  countryFlagSMOM:          _countryFlagSMOM,
  countryFlagSolomonIslands:          _countryFlagSolomonIslands,
  countryFlagSomalia:           _countryFlagSomalia,
  countryFlagSouthAfrica:           _countryFlagSouthAfrica,
  countryFlagSouthGeorgia:          _countryFlagSouthGeorgia,
  countryFlagSouthSudan:          _countryFlagSouthSudan,
  countryFlagSpain:           _countryFlagSpain,
  countryFlagSpm:           _countryFlagSpm,
  countryFlagSriLanka:          _countryFlagSriLanka,
  countryFlagSudan:           _countryFlagSudan,
  countryFlagSuriname:          _countryFlagSuriname,
  countryFlagSvalbard:          _countryFlagSvalbard,
  countryFlagSwaziland:           _countryFlagSwaziland,
  countryFlagSweden:          _countryFlagSweden,
  countryFlagSwitzerland:           _countryFlagSwitzerland,
  countryFlagSyria:           _countryFlagSyria,
  countryFlagTaiwan:          _countryFlagTaiwan,
  countryFlagTajikistan:          _countryFlagTajikistan,
  countryFlagTanzania:          _countryFlagTanzania,
  countryFlagThailand:          _countryFlagThailand,
  countryFlagTimorLeste:          _countryFlagTimorLeste,
  countryFlagTogo:          _countryFlagTogo,
  countryFlagTokelau:           _countryFlagTokelau,
  countryFlagTonga:           _countryFlagTonga,
  countryFlagTrinidadAndTobago:           _countryFlagTrinidadAndTobago,
  countryFlagTunisia:           _countryFlagTunisia,
  countryFlagTurkey:          _countryFlagTurkey,
  countryFlagTurkmenistan:          _countryFlagTurkmenistan,
  countryFlagTurksAndCaicosIslands:           _countryFlagTurksAndCaicosIslands,
  countryFlagTuvalu:          _countryFlagTuvalu,
  countryFlagUganda:          _countryFlagUganda,
  countryFlagUkraine:           _countryFlagUkraine,
  countryFlagUnitedArabEmirates:          _countryFlagUnitedArabEmirates,
  countryFlagUnitedKingdom:           _countryFlagUnitedKingdom,
  countryFlagUnitedStates:          _countryFlagUnitedStates,
  countryFlagUruguay:           _countryFlagUruguay,
  countryFlagUzbekistan:          _countryFlagUzbekistan,
  countryFlagVanuatu:           _countryFlagVanuatu,
  countryFlagVaticanCity:           _countryFlagVaticanCity,
  countryFlagVenezuela:           _countryFlagVenezuela,
  countryFlagVietnam:           _countryFlagVietnam,
  countryFlagVirginIslands:           _countryFlagVirginIslands,
  countryFlagWales:           _countryFlagWales,
  countryFlagWallisAndFutuna:           _countryFlagWallisAndFutuna,
  countryFlagWesternSahara:           _countryFlagWesternSahara,
  countryFlagYemen:           _countryFlagYemen,
  countryFlagZambia:          _countryFlagZambia,
  countryFlagZimbabwe:          _countryFlagZimbabwe,
};



export const IMAGES_APP = {
  carnivalImageButton:              _carnivalImageButton,
  festivalImageButton:              _festivalImageButton,
  noitadaHomeImageButton:           _noitadaHomeImageButton,

  infos:                            _infos,

  wifi:                             _wifi,
  wifiInfos:                        _wifiInfos,
};



export const ICONS = {
  markerNCompany:                   _markerNCompany,
  markerNEvent:                     _markerNEvent,
  markerNStreet:                    _markerNStreet,

  flagPrideGray:                    _flagPrideGray,
  flagPrideColor:                   _flagPrideColor,
};



export const ITEMS = {
  orderDone:                        _order_done,
  orderSheetDone:                   _ordersheet_done,
  orderSheetFinishing:              _ordersheet_finishing,
  ordersheetPayApp:                 _ordersheet_pay_app,
  ordersheetPayPlace:               _ordersheet_pay_place,
  ordersheetWait:                   _ordersheet_wait,
  ordersheetClosed:                 _ordersheetClosed,
  ordersheetOpened:                 _ordersheetOpened,
  ordersheetService:                _ordersheetService,
};



export const ITEMS_EMPTY = {
  noBar:                            _noBar,
  noClub:                           _noClub,
  noHighlight:                      _noHighlight,
  noParty:                          _noParty,

  noAttractions:                    _noCarnivalAttractions,
  noCabin:                          _noCarnivalCabin,
  noDistrict:                       _noCarnivalDistrict,
  noStreet:                         _noCarnivalStreet,
  noGuides:                         _noCarnivalGuides,
  noPhones:                         _noCarnivalPhones,

  locationGlobal:                   _locationGlobal,

  noFavoritesCompanies:             _noFavoritesCompanies,
  noFavoritesEvents:                _noFavoritesEvents,
  
  noFestival:                       _noFestival,
  
  listEventDone:                    _listEventDone,
  listEventWait:                    _listEventWait,
  
  searchEmpty:                      _searchEmpty,

  ticketsEmpty:                     _ticketsEmpty,
  ticketsWait:                      _ticketsWait,

  noHelp:                           _noHelp,

  noNotifications:                  _noNotifications,
  noOrderItems:                     _noOrderItems,
  
  noReserveUpcoming:                _noReserveUpcoming,

  noNoitag:                         _noNoitag,
  noitagCardOff:                    _noitagCardOff,
  noitagCardOn:                     _noitagCardOn,
  noitagWatchOff:                   _noitagWatchOff,
  noitagWatchOn:                    _noitagWatchOn,

  noPayCards:                       _noPayCards,
};



export const LEVELS = {
  colorLevelNone:                   _colorLevelNone,
  colorLevelBasic:                  _colorLevelBasic,
  colorLevelPersas:                 _colorLevelPersas,
  colorLevelSuper:                  _colorLevelSuper,
  colorLevelStar:                   _colorLevelStar,
  colorLevelKing:                   _colorLevelKing,
};



export const LOGOS = {
  logoIconAppBlack:                 _logoIconAppBlack,
  logoIconAppWhite:                 _logoIconAppWhite,
  logoMobileAndroidApp:             _logoMobileAndroidApp,
  logoAppNameWhite:                 _logoAppNameWhite,
  
  logoUberIcon:                     _logoUberIcon,
  logoWazeIcon:                     _logoWazeIcon,

  logoNoitadaCarnivalIcon:          _logoNoitadaCarnivalIcon,
};



export const MARKETS = {
  marketGooglePlay:                 _marketGooglePlay,
  marketAppStore:                   _marketAppStore,
  marketWindowsStore:               _marketWindowsStore,
};



export const PAYMENTS = {
  flagDefault:                      _flagDefault,

  modeDiscount:                     _modeDiscount,
  modeAddition:                     _modeAddition,

  flagTypeWallet:                   _flagTypeWallet,
  flagTypeCash:                     _flagTypeCash,
  flagTypeCheque:                   _flagTypeCheque,
  flagTypePix:                      _flagTypePix,
  flagTypeCredit:                   _flagTypeCredit,
  flagTypeDebit:                    _flagTypeDebit,
  flagTypeTicket:                   _flagTypeTicket,

  flagBankNubank:                   _flagBankNubank,
  flagBankPagarme:                  _flagBankPagarme,
  flagBankStone:                    _flagBankStone,

  flagCardAmerican:                 _flagCardAmerican,
  flagCardAura:                     _flagCardAura,
  flagCardDiners:                   _flagCardDiners,
  flagCardDiscover:                 _flagCardDiscover,
  flagCardElo:                      _flagCardElo,
  flagCardHipercard:                _flagCardHipercard,
  flagCardMastercard:               _flagCardMastercard,
  flagCardVisa:                     _flagCardVisa,
  flagCardCabal:                    _flagCardCabal,
  flagCardJcb:                      _flagCardJcb,
  flagCardUnionpay:                 _flagCardUnionpay,

  flagTicketAlelo:                  _flagTicketAlelo,
  flagTicketBen:                    _flagTicketBen,
  flagTicketSodexo:                 _flagTicketSodexo,
  flagTicketTicket:                 _flagTicketTicket,
  flagTicketVr:                     _flagTicketVr,

  logoTypeWallet:                   _logoTypeWallet,
  logoTypeCash:                     _logoTypeCash,
  logoTypePix:                      _logoTypePix,
  logoTypeCheque:                   _logoTypeCheque,
  logoTypeCredit:                   _logoTypeCredit,
  logoTypeDebit:                    _logoTypeDebit,
  logoTypeTicket:                   _logoTypeTicket,

  logoBankNubank:                   _logoBankNubank,
  logoBankPagarme:                  _logoBankPagarme,
  logoBankStone:                    _logoBankStone,

  logoCreditAmerican:               _logoCreditAmerican,
  logoCreditAura:                   _logoCreditAura,
  logoCreditCabal:                  _logoCreditCabal,
  logoCreditDiners:                 _logoCreditDiners,
  logoCreditDiscover:               _logoCreditDiscover,
  logoCreditElo:                    _logoCreditElo,
  logoCreditHipercard:              _logoCreditHipercard,
  logoCreditJcb:                    _logoCreditJcb,
  logoCreditMastercard:             _logoCreditMastercard,
  logoCreditUnionpay:               _logoCreditUnionpay,
  logoCreditVisa:                   _logoCreditVisa,
  
  creditCardFrontDefault:           _creditCardFrontDefault,
  creditCardBackDefault:            _creditCardBackDefault,

  creditCardFrontAmerican:          _creditCardFrontAmerican,
  creditCardBackAmerican:           _creditCardBackAmerican,

  creditCardFrontDiners:            _creditCardFrontDiners,
  creditCardBackDiners:             _creditCardBackDiners,

  creditCardFrontElo:               _creditCardFrontElo,
  creditCardBackElo:                _creditCardBackElo,

  creditCardFrontHipercard:         _creditCardFrontHipercard,
  creditCardBackHipercard:          _creditCardBackHipercard,

  creditCardFrontMastercard:        _creditCardFrontMastercard,
  creditCardBackMastercard:         _creditCardBackMastercard,

  creditCardFrontNubank:            _creditCardFrontNubank,
  creditCardBackNubank:             _creditCardBackNubank,

  creditCardFrontVisa:              _creditCardFrontVisa,
  creditCardBackVisa:               _creditCardBackVisa,
};



export const PLACEHOLDERS = {
  placeholderAvatarCompany:         _placeholderAvatarCompany,
  placeholderAvatarUser:            _placeholderAvatarUser,
  placeholderAvatarWaiter:          _placeholderAvatarWaiter,
  placeholderEvent:                 _placeholderEvent,
};



export const SEPARATORS = {
  separatorHorizontal:              _separatorHorizontal,
  separatorVertical:                _separatorVertical,
  shadowFloor:                      _shadowFloor,
};



export const STICKERS = {
  smile:                            _smile,
  stickerCake:                      _stickerCake,
};

const Images = {
  ...BACKGROUNDS,
  ...CARNIVAL,
  ...CONFIGS,
  ...FLAGS_COUNTRY,
  ...IMAGES_APP,
  ...ICONS,
  ...ITEMS,
  ...ITEMS_EMPTY,
  ...LEVELS,
  ...LOGOS,
  ...MARKETS,
  ...PAYMENTS,
  ...PLACEHOLDERS,
  ...SEPARATORS,
  ...STICKERS,
};



export default Images;
